define("ui/components/workload-metrics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RLCyJXzV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[1,[27,\"metrics-action\",null,[[\"queryAction\",\"state\"],[[27,\"action\",[[22,0,[]],\"query\"],null],[23,[\"state\"]]]]],false],[0,\"\\n  \"],[1,[27,\"metrics-graph\",null,[[\"graphs\",\"loading\",\"noGraphs\"],[[23,[\"graphs\"]],[23,[\"state\",\"loading\"]],[23,[\"state\",\"noGraphs\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/workload-metrics/template.hbs"
    }
  });

  _exports.default = _default;
});