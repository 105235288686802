define("ui/models/catalog", ["exports", "@rancher/ember-api-store/models/resource", "shared/utils/util", "ui/utils/constants"], function (_exports, _resource, _util, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Catalog = _resource.default.extend({
    modalService: Ember.inject.service('modal'),
    level: 'global',
    displayKind: Ember.computed('kind', function () {
      return (0, _util.ucFirst)(Ember.get(this, 'kind'));
    }),
    combinedState: Ember.computed('id', function () {
      if (!Ember.get(this, 'id')) {
        return 'disabled';
      }
    }),
    canClone: Ember.computed('actions.clone', function () {
      var name = Ember.get(this, 'name');
      var catalogNames = Ember.get(_constants.default, 'CATALOG');
      var builtIn = [Ember.get(catalogNames, 'LIBRARY_KEY'), Ember.get(catalogNames, 'ALIBABA_APP_HUB_KEY'), Ember.get(catalogNames, 'HELM_STABLE_KEY'), Ember.get(catalogNames, 'HELM_INCUBATOR_KEY')];
      return !builtIn.includes(name);
    }),
    availableActions: Ember.computed('actionLinks.{refresh}', function () {
      var a = Ember.get(this, 'actionLinks') || {};
      return [{
        action: 'enable',
        icon: 'icon icon-plus-circle',
        enabled: !this.id,
        label: 'generic.enable'
      }, {
        enabled: !!a.refresh,
        label: 'catalogPage.index.refreshBtn',
        icon: 'icon icon-refresh',
        action: 'refresh'
      }];
    }),
    actions: {
      enable: function enable() {
        this.save();
      },
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: this,
          scope: Ember.get(this, 'level')
        });
      },
      clone: function clone() {
        var clone = this.cloneForNew();
        Ember.get(this, 'modalService').toggleModal('modal-edit-catalog', {
          model: clone,
          scope: Ember.get(this, 'level')
        });
      },
      refresh: function refresh() {
        this.doAction('refresh');
      }
    }
  });

  Catalog.reopenClass({
    stateMap: {
      'disabled': {
        icon: 'icon icon-alert',
        color: 'text-muted'
      }
    }
  });
  var _default = Catalog;
  _exports.default = _default;
});