define("ui/authenticated/project/dns/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    projectController: Ember.inject.controller('authenticated.project'),
    queryParams: ['sortBy'],
    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['stack.isDefault:desc', 'stack.displayName', 'sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['stack.isDefault:desc', 'stack.displayName', 'displayName', 'id'],
      searchField: 'displayName',
      translationKey: 'generic.name'
    }, {
      name: 'displayType',
      sort: ['displayType', 'displayName', 'id'],
      searchField: 'displayType',
      translationKey: 'generic.type',
      width: 150
    }, {
      name: 'target',
      sort: false,
      searchField: 'displayTargets',
      translationKey: 'dnsPage.table.target'
    }],
    groupTableBy: Ember.computed.alias('projectController.groupTableBy'),
    expandedInstances: Ember.computed.alias('projectController.expandedInstances'),
    preSorts: Ember.computed.alias('projectController.preSorts'),
    rows: Ember.computed.alias('model.records')
  });

  _exports.default = _default;
});