define("ui/models/workload", ["exports", "shared/mixins/grafana", "@rancher/ember-api-store/models/resource", "shared/utils/util", "shared/utils/parse-unit", "@rancher/ember-api-store/utils/denormalize", "ui/mixins/state-counts", "ui/mixins/endpoint-ports", "shared/mixins/display-image", "shared/utils/constants"], function (_exports, _grafana, _resource, _util, _parseUnit, _denormalize, _stateCounts, _endpointPorts, _displayImage, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activeIcon = activeIcon;
  _exports.default = void 0;
  var WORKLOAD_CONFIG_FIELDS = ['cronJobConfig', 'daemonSetConfig', 'deploymentConfig', 'jobConfig', 'replicaSetConfig', 'replicationControllerConfig', 'statefulSetConfig'];

  var Workload = _resource.default.extend(_grafana.default, _displayImage.default, _stateCounts.default, _endpointPorts.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    pods: (0, _denormalize.hasMany)('id', 'pod', 'workloadId'),
    scaleTimer: null,
    // @TODO-2.0 cleanup all these...
    hasPorts: true,
    canUpgrade: true,
    canHaveLabels: true,
    realButNotLb: true,
    canHaveLinks: true,
    canChangeNetworking: true,
    canChangeSecurity: true,
    canHaveSecrets: true,
    canHaveEnvironment: true,
    canHaveHealthCheck: true,
    isBalancer: false,
    canBalanceTo: true,
    grafanaResourceId: Ember.computed.alias('name'),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore'),
    canClone: Ember.computed.not('hasSidekicks'),
    hasSidekicks: Ember.computed.gt('containers.length', 1),
    launchConfig: Ember.computed.alias('containers.firstObject'),
    canScaleUp: Ember.computed.alias('canScale'),
    init: function init() {
      this._super.apply(this, arguments);

      this.defineStateCounts('pods', 'podStates', 'podCountSort');
    },
    restarts: Ember.computed('pods.@each.restarts', function () {
      var out = 0;
      (Ember.get(this, 'pods') || []).forEach(function (pod) {
        out += Ember.get(pod, 'restarts');
      });
      return out;
    }),
    lcType: Ember.computed('type', function () {
      return (Ember.get(this, 'type') || '').toLowerCase();
    }),
    canEdit: Ember.computed('links.update', 'lcType', function () {
      var lcType = Ember.get(this, 'lcType');
      return !!Ember.get(this, 'links.update') && lcType !== 'job';
    }),
    availableActions: function () {
      var a = Ember.get(this, 'actionLinks') || {};
      var podForShell = Ember.get(this, 'podForShell');
      var isPaused = Ember.get(this, 'isPaused');
      var canEdit = Ember.get(this, 'canEdit');
      var choices = [{
        label: 'action.redeploy',
        icon: 'icon icon-refresh',
        action: 'redeploy',
        enabled: canEdit,
        bulkable: true
      }, {
        label: 'action.addSidekick',
        icon: 'icon icon-plus-circle',
        action: 'addSidekick'
      }, {
        label: 'action.rollback',
        icon: 'icon icon-history',
        action: 'rollback',
        enabled: !!a.rollback
      }, {
        divider: true
      }, {
        label: 'action.execute',
        icon: 'icon icon-terminal',
        action: 'shell',
        enabled: !!podForShell,
        altAction: 'popoutShell'
      }, //      { label: 'action.logs',           icon: 'icon icon-file',             action: 'logs',           enabled: !!a.logs, altAction: 'popoutLogs' },
      {
        divider: true
      }, {
        label: 'action.pause',
        icon: 'icon icon-pause',
        action: 'pause',
        enabled: !!a.pause && !isPaused,
        bulkable: true
      }, {
        label: 'action.resume',
        icon: 'icon icon-play',
        action: 'resume',
        enabled: !!a.pause && isPaused,
        bulkable: true
      }];
      return choices;
    }.property('actionLinks.{activate,deactivate,pause,restart,rollback,garbagecollect}', 'links.{update,remove}', 'podForShell', 'isPaused', 'canEdit'),
    displayType: function () {
      var type = this.get('type');
      return Ember.get(this, 'intl').t("servicePage.serviceType.".concat(type));
    }.property('type'),
    sortName: function () {
      return (0, _util.sortableNumericSuffix)(Ember.get(this, 'displayName'));
    }.property('displayName'),
    combinedState: function () {
      var service = Ember.get(this, 'state');
      var health = Ember.get(this, 'healthState');

      if (service === 'active' && health) {
        // Return the health state for active services
        return health;
      } // Return the service for anything else


      return service;
    }.property('state', 'healthState'),
    isGlobalScale: function () {
      var lcType = Ember.get(this, 'lcType');
      return lcType === 'daemonset';
    }.property('lcType'),
    canScaleDown: function () {
      return Ember.get(this, 'canScale') && Ember.get(this, 'scale') > 0;
    }.property('canScale', 'scale'),
    displayScale: function () {
      if (Ember.get(this, 'isGlobalScale')) {
        return Ember.get(this, 'intl').t('servicePage.multistat.daemonSetScale');
      } else {
        return Ember.get(this, 'scale');
      }
    }.property('scale', 'isGlobalScale', 'lcType'),
    canScale: Ember.computed('lcType', function () {
      var lcType = Ember.get(this, 'lcType');
      return lcType !== 'cronjob' && lcType !== 'daemonset' && lcType !== 'job';
    }),
    activeIcon: function () {
      return activeIcon(this);
    }.property('lcType'),
    memoryReservationBlurb: Ember.computed('launchConfig.memoryReservation', function () {
      if (Ember.get(this, 'launchConfig.memoryReservation')) {
        return (0, _parseUnit.formatSi)(Ember.get(this, 'launchConfig.memoryReservation'), 1024, 'iB', 'B');
      }
    }),
    podForShell: Ember.computed('pods.@each.canShell', function () {
      return Ember.get(this, 'pods').findBy('canShell', true);
    }),
    secondaryLaunchConfigs: Ember.computed('containers.[]', function () {
      return (Ember.get(this, 'containers') || []).slice(1);
    }),
    isCreatedByRancher: Ember.computed('workloadAnnotations', function () {
      var workloadAnnotations = Ember.get(this, 'workloadAnnotations') || {};
      return !!workloadAnnotations[_constants.default.LABEL.CREATOR_ID];
    }),
    currentScale: Ember.computed('pods.@each.state', 'scale', function () {
      var _this$pods = this.pods,
          pods = _this$pods === void 0 ? [] : _this$pods;
      return pods.filter(function (p) {
        return p.state === 'running';
      }).length;
    }),
    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },
      pause: function pause() {
        return this.doAction('pause');
      },
      resume: function resume() {
        return this.doAction('resume');
      },
      restart: function restart() {
        return this.doAction('restart', {
          rollingRestartStrategy: {}
        });
      },
      rollback: function rollback() {
        Ember.get(this, 'modalService').toggleModal('modal-rollback-service', {
          originalModel: this
        });
      },
      garbageCollect: function garbageCollect() {
        return this.doAction('garbagecollect');
      },
      // Start and stop are only here to mimic the same actions that exist on a container
      // the reason being bulkActions, to forgo writing distinct logic for containers vs
      // services lets just mimic the actions here.
      start: function start() {
        return this.doAction('activate');
      },
      stop: function stop() {
        return this.doAction('deactivate');
      },
      promptStop: function promptStop() {
        Ember.get(this, 'modalService').toggleModal('modal-container-stop', {
          model: [this]
        });
      },
      scaleUp: function scaleUp() {
        Ember.set(this, 'scale', Ember.get(this, 'scale') + 1);
        this.saveScale();
      },
      scaleDown: function scaleDown() {
        var scale = Ember.get(this, 'scale');
        scale -= 1;
        scale = Math.max(scale, 0);
        Ember.set(this, 'scale', scale);
        this.saveScale();
      },
      edit: function edit() {
        var upgradeImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'false';
        var route = 'containers.run';

        if (Ember.get(this, 'lcType') === 'loadbalancerservice') {
          route = 'balancers.run';
        }

        Ember.get(this, 'router').transitionTo(route, {
          queryParams: {
            workloadId: Ember.get(this, 'id'),
            upgrade: true,
            upgradeImage: upgradeImage,
            namespaceId: Ember.get(this, 'namespaceId')
          }
        });
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('containers.run', {
          queryParams: {
            workloadId: Ember.get(this, 'id')
          }
        });
      },
      redeploy: function redeploy() {
        this.updateTimestamp();
        this.save();
      },
      addSidekick: function addSidekick() {
        Ember.get(this, 'router').transitionTo('containers.run', {
          queryParams: {
            workloadId: Ember.get(this, 'id'),
            addSidekick: true
          }
        });
      },
      shell: function shell() {
        Ember.get(this, 'modalService').toggleModal('modal-shell', {
          model: Ember.get(this, 'podForShell')
        });
      },
      popoutShell: function popoutShell() {
        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'podForShell.id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.console', projectId);
        var system = Ember.get(this, 'podForShell.node.info.os.operatingSystem') || '';
        var windows = false;

        if (system.startsWith('Windows')) {
          windows = true;
        }

        Ember.run.later(function () {
          var opt = 'toolbars=0,width=900,height=700,left=200,top=200';
          window.open("//".concat(window.location.host).concat(route, "?podId=").concat(podId, "&windows=").concat(windows, "&isPopup=true"), '_blank', opt);
        });
      }
    },
    updateTimestamp: function updateTimestamp() {
      var obj = Ember.get(this, 'annotations');

      if (!obj) {
        obj = {};
        Ember.set(this, 'annotations', obj);
      }

      obj[_constants.default.LABEL.TIMESTAMP] = new Date().toISOString().replace(/\.\d+Z$/, 'Z');
    },
    saveScale: function saveScale() {
      if (Ember.get(this, 'scaleTimer')) {
        Ember.run.cancel(Ember.get(this, 'scaleTimer'));
      }

      var timer = Ember.run.later(this, function () {
        var _this = this;

        this.save({
          data: {
            scale: Ember.get(this, 'scale')
          }
        }).catch(function (err) {
          Ember.get(_this, 'growl').fromError('Error updating scale', err);
        });
      }, 500);
      Ember.set(this, 'scaleTimer', timer);
    },
    clearConfigsExcept: function clearConfigsExcept(keep) {
      var keys = this.allKeys().filter(function (x) {
        return WORKLOAD_CONFIG_FIELDS.indexOf(x) > -1;
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    }
  });

  function activeIcon(workload) {
    var out = 'icon icon-services';

    switch (workload.get('lcType')) {
      case 'pod':
        out = 'icon icon-container';
        break;

      case 'cronjob':
        out = 'icon icon-backup';
        break;

      case 'job':
        out = 'icon icon-file';
        break;

      case 'daemonset':
        out = 'icon icon-globe';
        break;

      case 'statefulset':
        out = 'icon icon-database';
        break;
    }

    return out;
  }

  Workload.reopenClass({
    stateMap: {
      'active': {
        icon: activeIcon,
        color: 'text-success'
      }
    },
    mangleIn: function mangleIn(data) {
      if (data) {
        var publicEndpoints = Ember.get(data, 'publicEndpoints') || [];
        var containers = Ember.get(data, 'containers') || [];
        publicEndpoints.forEach(function (endpoint) {
          endpoint.type = 'publicEndpoint';
        });
        containers.forEach(function (container) {
          container.type = 'container';
        });
      }

      return data;
    }
  });
  var _default = Workload;
  _exports.default = _default;
});