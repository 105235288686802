define("ui/helpers/format-si", ["exports", "shared/helpers/format-si"], function (_exports, _formatSi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _formatSi.default;
    }
  });
});