define("ui/authenticated/apikeys/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        tokens: this.get('globalStore').findAll('token')
      });
    }
  });

  _exports.default = _default;
});