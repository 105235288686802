define("ui/models/secondarylaunchconfig", ["exports", "ui/models/launchconfig"], function (_exports, _launchconfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var secondaryLaunchConfigs = _launchconfig.default.extend({
    displayEnvironmentVars: Ember.computed('launchConfig.environment', function () {
      var envs = [];
      var environment = this.get('launchConfig.environment') || {};
      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });
      return envs;
    })
  });

  var _default = secondaryLaunchConfigs;
  _exports.default = _default;
});