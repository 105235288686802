define("ui/models/projectcatalog", ["exports", "ui/models/catalog", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _catalog, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _catalog.default.extend({
    level: 'project',
    project: (0, _denormalize.reference)('projectId')
  });

  _exports.default = _default;
});