define("ui/mixins/notifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    getNotifierById: function getNotifierById(id) {
      if (!id) {
        return null;
      }

      var notifiers = Ember.get(this, 'notifiers');
      return notifiers.filterBy('id', id).get('firstObject');
    },
    recipientsTip: Ember.computed('notifiers.@each.{id,displayName}', 'alertGroup.recipients.@each.{length,notifierType,recipient,notifierId}', function () {
      var _this = this;

      var recipients = Ember.get(this, 'alertGroup.recipients') || [];
      var out = recipients.map(function (recipient) {
        var notifierId = Ember.get(recipient, 'notifierId');

        var notifier = _this.getNotifierById(notifierId);

        if (notifier) {
          var name = notifier.get('displayNameAndType');
          return "<div class=\"p-5 pt-0\">".concat(name, "</div>");
        }

        return null;
      }).filter(function (str) {
        return !!str;
      }).join('');
      return Ember.String.htmlSafe(out);
    })
  });

  _exports.default = _default;
});