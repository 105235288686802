define("ui/authenticated/dummy-dev/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3S40oKET",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-6 box p-0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col mt-0 mb-0\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"http://placehold.it/120x120\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"here is some info\"],[10],[0,\"\\n        \"],[7,\"hr\"],[9],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"and some more down here\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"col span-6 box p-0\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col mt-0 mb-0\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"http://placehold.it/120x120\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"here is some info\"],[10],[0,\"\\n        \"],[7,\"hr\"],[9],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"and some more down here\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/dummy-dev/template.hbs"
    }
  });

  _exports.default = _default;
});