define("ui/models/job", ["exports", "ui/models/workload"], function (_exports, _workload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Job = _workload.default.extend({});

  var _default = Job;
  _exports.default = _default;
});