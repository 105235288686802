define("ui/apps-tab/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    catalog: Ember.inject.service(),
    store: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return Ember.get(this, 'catalog').fetchUnScopedCatalogs();
    },
    model: function model() {
      return this.get('store').findAll('app').then(function (apps) {
        return {
          apps: apps
        };
      });
    },
    afterModel: function afterModel(model
    /* , transition */
    ) {
      return Ember.get(this, 'catalog').fetchAppTemplates(Ember.get(model, 'apps'));
    },
    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, "session.".concat(_constants.default.SESSION.PROJECT_ROUTE), 'apps-tab');
    })
  });

  _exports.default = _default;
});