define("ui/components/modal-kubeconfig/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KQFgC4CT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],1],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"icon icon-spinner icon-spin\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"k8sPage.configFile.generating\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"step\"]],2],null]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"k8sPage.configFile.usage.helpText\"],[[\"htmlSafe\"],[true]]],false],[10],[0,\"\\n    \"],[1,[27,\"code-block\",null,[[\"language\",\"code\"],[\"yaml\",[23,[\"kubeconfig\"]]]]],false],[0,\"\\n    \"],[1,[27,\"copy-to-clipboard\",null,[[\"tooltipText\",\"buttonText\",\"clipboardText\",\"class\"],[\"\",\"copyToClipboard.tooltip\",[23,[\"kubeconfig\"]],\"with-clip\"]]],false],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"mt-10 mb-0\"],[9],[1,[27,\"t\",[\"k8sPage.configFile.usage.downloadText\"],[[\"downloadUrl\",\"htmlSafe\"],[[23,[\"downloadUrl\"]],true]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-primary close-kubeconfig\"],[9],[1,[27,\"t\",[\"generic.closeModal\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-kubeconfig/template.hbs"
    }
  });

  _exports.default = _default;
});