define("ui/components/node-group/component", ["exports", "ui/components/node-group/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    fullColspan: null,
    afterName: 0,
    showState: false,
    afterState: 0,
    alignState: 'text-center',
    showActions: true,
    nodes: null,
    nodeId: null,
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      var nodes = Ember.get(this, 'nodes');
      var nodeId = Ember.get(this, 'nodeId');

      if (nodes && nodeId) {
        var clusterId = Ember.get(this, 'scope.currentCluster.id');
        var targetNode = nodes.find(function (n) {
          return n.id === nodeId && n.clusterId === clusterId;
        });
        Ember.set(this, 'model', targetNode);
      }
    }
  });

  _exports.default = _default;
});