define("ui/components/container-metrics/component", ["exports", "shared/mixins/metrics", "ui/components/container-metrics/template"], function (_exports, _metrics, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_metrics.default, {
    layout: _template.default,
    filters: {
      resourceType: 'container'
    },
    projectScope: true,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'metricParams', {
        podName: Ember.get(this, 'podId'),
        containerName: Ember.get(this, 'resourceId')
      });
    }
  });

  _exports.default = _default;
});