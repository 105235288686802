define("ui/apps-tab/detail/controller", ["exports", "ui/components/pod-dots/component", "ui/authenticated/project/config-maps/index/controller"], function (_exports, _component, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var podsHeaders = [{
    name: 'expand',
    sort: false,
    searchField: null,
    width: 30
  }, {
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'image',
    sort: ['image', 'displayName'],
    searchField: 'image',
    translationKey: 'generic.image'
  }, {
    name: 'scale',
    sort: ['scale:desc', 'isGlobalScale:desc', 'displayName'],
    searchField: null,
    translationKey: 'stacksPage.table.scale',
    classNames: 'text-center',
    width: 100
  }];
  var ingressHeaders = [{
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'created',
    sort: ['created', 'id'],
    classNames: 'text-right pr-20',
    searchField: false,
    translationKey: 'generic.created'
  }];
  var servicesHeaders = [{
    name: 'state',
    sort: ['stack.isDefault:desc', 'stack.displayName', 'sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['stack.isDefault:desc', 'stack.displayName', 'displayName', 'id'],
    searchField: 'displayName',
    translationKey: 'generic.name'
  }, {
    name: 'displayType',
    sort: ['displayType', 'displayName', 'id'],
    searchField: 'displayType',
    translationKey: 'generic.type',
    width: 150
  }, {
    name: 'target',
    sort: false,
    searchField: 'displayTargets',
    translationKey: 'dnsPage.table.target'
  }];
  var volumesHeaders = [//    {
  //      name: 'expand',
  //      sort: false,
  //      searchField: null,
  //      width: 30
  //    },
  {
    name: 'state',
    sort: ['sortState', 'displayName'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 120
  }, {
    name: 'name',
    sort: ['displayName', 'id'],
    searchField: 'displayName',
    translationKey: 'volumesPage.claimName.label'
  }, {
    name: 'size',
    sort: ['sizeBytes'],
    search: ['sizeBytes', 'displaySize'],
    translationKey: 'generic.size',
    width: 120
  }, {
    name: 'volume',
    sort: ['volume.displayName', 'displayName', 'id'],
    translationKey: 'volumesPage.volume.label',
    searchField: null
  }, {
    name: 'storageClass',
    sort: ['storageClass.displayName', 'displayName', 'id'],
    translationKey: 'volumesPage.storageClass.label',
    searchField: null
  }];
  var secretsHeaders = [{
    name: 'state',
    sort: ['sortState', 'name', 'id'],
    type: 'string',
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 125
  }, {
    name: 'name',
    sort: ['name', 'id'],
    translationKey: 'generic.name'
  }, {
    name: 'namespace',
    translationKey: 'generic.namespace',
    searchField: 'namespace.displayName',
    sort: ['namespace.displayName', 'name', 'id']
  }, {
    name: 'keys',
    translationKey: 'secretsPage.table.keys',
    searchField: 'keys',
    sort: ['firstKey', 'name', 'id']
  }, {
    name: 'created',
    translationKey: 'generic.created',
    sort: ['created:desc', 'name', 'id'],
    searchField: false,
    type: 'string',
    width: 150
  }];

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    ingressHeaders: ingressHeaders,
    servicesHeaders: servicesHeaders,
    volumesHeaders: volumesHeaders,
    secretsHeaders: secretsHeaders,
    configMapsHeaders: _controller.headers,
    ingressSearchText: '',
    secretsSearchText: '',
    configMapsSearchText: '',
    podsHeaders: podsHeaders,
    podsSearchText: '',
    servicesSearchText: '',
    volumesSearchText: '',
    sortBy: 'name',
    extraSearchFields: ['id:prefix', 'displayIp:ip'],
    extraSearchSubFields: _component.searchFields,
    expandedInstances: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('expandedInstances', []);
    },
    actions: {
      toggleExpand: function toggleExpand(instId) {
        var list = this.get('expandedInstances');

        if (list.includes(instId)) {
          list.removeObject(instId);
        } else {
          list.addObject(instId);
        }
      }
    },
    workloadsAndPods: Ember.computed('model.app.workloads', 'model.app.pods', function () {
      var out = [];
      out = this.get('model.app.pods').filter(function (obj) {
        return !obj.get('workloadId');
      });
      out.pushObjects(this.get('model.app.workloads').slice());
      return out;
    })
  });

  _exports.default = _default;
});