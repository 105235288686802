define("ui/authenticated/project/dns/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params
    /* , transition*/
    ) {
      var store = Ember.get(this, 'store');
      var deps = {
        dnsRecords: store.findAll('service'),
        workloads: store.findAll('workload')
      };

      if (Ember.get(params, 'id')) {
        deps['existing'] = store.find('service', params.id);
      }

      return Ember.RSVP.hash(deps, 'Load dependencies').then(function (hash) {
        var record;
        var namespaceId = params.namespaceId;
        var namespace;

        if (namespaceId) {
          namespace = store.getById('namespace', namespaceId);
        } // If the namespace doesn't exist or isn't set, pick default


        if (!namespace) {
          namespace = store.all('namespace').findBy('isDefault', true);

          if (namespace) {
            namespaceId = namespace.get('id');
          }
        }

        if (hash.existing) {
          record = hash.existing.cloneForNew();
          delete hash.existing;
        } else {
          record = store.createRecord({
            type: 'service',
            namespaceId: namespaceId,
            ipAddresses: [''],
            sessionAffinity: 'None',
            kind: 'ClusterIP',
            clusterIp: 'None'
          });
        }

        hash.record = record;
        return hash;
      });
    },
    resetController: function resetController(controller, isExisting
    /* , transition*/
    ) {
      if (isExisting) {
        Ember.set(controller, 'namespaceId', null);
        Ember.set(controller, 'dnsRecordId', null);
      }
    }
  });

  _exports.default = _default;
});