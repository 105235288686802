define("ui/models/openldapconfig", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var OpenLdapConfig = _resource.default.extend({
    type: 'openLdapConfig'
  });

  var _default = OpenLdapConfig;
  _exports.default = _default;
});