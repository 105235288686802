define("ui/components/form-node-requirement/component", ["exports", "ui/components/form-node-requirement/template", "shared/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    term: null,
    ruleArray: null,
    editing: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.initRuleArray();
    },
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'ruleArray.length') === 0) {
        this.send('addRule');
      }
    },
    actions: {
      addRule: function addRule() {
        Ember.get(this, 'ruleArray').pushObject({
          operator: 'In'
        });
      },
      removeRule: function removeRule(rule) {
        Ember.get(this, 'ruleArray').removeObject(rule);
      }
    },
    ruleChanged: Ember.observer('ruleArray.@each.{key,operator,values}', function () {
      Ember.set(this, 'term.matchExpressions', (Ember.get(this, 'ruleArray') || []).filter(function (rule) {
        if (rule.operator === 'In' || rule.operator === 'NotIn') {
          return rule.values;
        }

        return rule.key;
      }).map(function (rule) {
        var out = {
          key: rule.key,
          operator: rule.operator
        };

        if (rule.operator === 'In' || rule.operator === 'NotIn') {
          out.values = rule.values.split(',');
        }

        return out;
      }));
    }),
    operatorChoices: _constants.default.VOLUME_NODE_SELECTOR_OPERATOR,
    initRuleArray: function initRuleArray() {
      var ruleArray = [];
      (Ember.get(this, 'term.matchExpressions') || []).forEach(function (requirement) {
        ruleArray.push({
          key: requirement.key,
          operator: requirement.operator,
          values: (requirement.values || []).join(',')
        });
      });
      Ember.set(this, 'ruleArray', ruleArray);
    }
  });

  _exports.default = _default;
});