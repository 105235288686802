define("ui/components/modal-wechat/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-wechat/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'about', 'span-6', 'offset-3', 'alert']
  });

  _exports.default = _default;
});