define("ui/components/notifier/notifier-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GbDemxbO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"bulkActions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\"],[11,\"valign\",\"middle\"],[11,\"class\",\"row-check\"],[11,\"style\",\"padding-top: 2px;\"],[9],[0,\"\\n    \"],[1,[27,\"check-box\",null,[[\"nodeId\"],[[23,[\"model\",\"id\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"state\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[1,[27,\"badge-state\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"name\"]]]]],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n   \"],[7,\"div\"],[11,\"class\",\"text-muted text-small\"],[9],[1,[23,[\"model\",\"description\"]],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"type\"]]]]],[11,\"class\",\"clip\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-capitalize\"],[9],[1,[23,[\"model\",\"notifierType\"]],false],[0,\" \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showNotifierValue\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-muted text-small\"],[9],[1,[23,[\"model\",\"notifierTableLabel\"]],false],[0,\": \"],[1,[23,[\"model\",\"notifierValue\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"created\"]]]]],[11,\"class\",\"text-right pr-20\"],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displayCreated\"]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[\"generic.actions\"],null]]]],[11,\"class\",\"actions\"],[9],[0,\"\\n  \"],[1,[27,\"action-menu\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/notifier/notifier-row/template.hbs"
    }
  });

  _exports.default = _default;
});