define("ui/authenticated/cluster/security/members/index/controller", ["exports", "ui/mixins/filter-state"], function (_exports, _filterState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var headers = [{
    translationKey: 'membersPage.index.table.userId',
    name: 'user.displayName',
    sort: ['user.displayName', 'name', 'id']
  }, {
    translationKey: 'membersPage.index.table.template',
    name: 'roleTemplateId',
    sort: ['roleTemplateId']
  }, {
    translationKey: 'generic.created',
    name: 'created',
    sort: ['created'],
    searchField: false,
    classNames: 'text-right pr-20',
    width: 200
  }];

  var _default = Ember.Controller.extend(_filterState.default, {
    settings: Ember.inject.service(),
    scope: Ember.inject.service(),
    sortBy: 'name',
    headers: headers,
    searchText: '',
    filterableContent: Ember.computed('model.clusterRoleTemplateBindings.[]', function () {
      return Ember.get(this, 'model.clusterRoleTemplateBindings');
    })
  });

  _exports.default = _default;
});