define("ui/components/volume-source/source-portworx/component", ["exports", "ui/components/volume-source/source-portworx/template", "shared/mixins/volume-source"], function (_exports, _template, _volumeSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'portworxVolume'
  });

  _exports.default = _default;
});