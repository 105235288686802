define("ui/components/settings/telemetry-opt/component", ["exports", "ui/utils/constants", "ui/components/settings/telemetry-opt/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IN = 'in';
  var OUT = 'out';

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    layout: _template.default,
    initialValue: null,
    optIn: null,
    init: function init() {
      this._super.apply(this, arguments);

      var val = false;

      if (this.get('initialValue') === IN) {
        val = true;
      }

      this.set('optIn', val);
    },
    actions: {
      save: function save(btnCb) {
        var _this = this;

        this.get('settings').set(_constants.default.SETTING.TELEMETRY, this.get('optIn') ? IN : OUT);
        this.get('settings').one('settingsPromisesResolved', function () {
          btnCb(true);

          if (_this.saved) {
            _this.saved();
          }
        });
      }
    }
  });

  _exports.default = _default;
});