define("ui/utils/parse-uri", ["exports", "shared/utils/parse-uri"], function (_exports, _parseUri) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _parseUri.default;
    }
  });
});