define("ui/components/pod-dots/component", ["exports", "ember-cli-pagination/computed/paged-array", "ui/utils/search-text", "ui/components/pod-dots/template"], function (_exports, _pagedArray, _searchText, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.searchFields = void 0;
  var searchFields = ['displayName', 'id:prefix', 'displayState', 'image', 'displayIp:ip'];
  _exports.searchFields = searchFields;

  var _default = Ember.Component.extend({
    layout: _template.default,
    pod: null,
    pods: null,
    searchText: null,
    groupByPod: false,
    paging: true,
    sort: ['sortStr'],
    searchFields: searchFields,
    tagName: '',
    page: 1,
    perPage: 120,
    pageCountChanged: Ember.observer('indexFrom', 'filtered.length', function () {
      // Go to the last page if we end up past the last page
      var from = this.get('indexFrom');
      var last = this.get('filtered.length');
      var perPage = this.get('perPage');

      if (this.get('page') > 1 && from > last) {
        var page = Math.ceil(last / perPage);
        this.set('page', page);
      }
    }),
    // Ignore the setting because these are tiny dots.
    filtered: Ember.computed('pods.[]', 'pod', 'searchText', function () {
      var out = [];
      var pod = this.get('pod');
      var pods = this.get('pods');

      if (pods) {
        out.pushObjects(pods.slice());
      }

      if (pod) {
        out.pushObject(pod);
      }

      var _filter = (0, _searchText.filter)(out, Ember.get(this, 'searchText'), Ember.get(this, 'searchFields')),
          matches = _filter.matches;

      return matches;
    }),
    pagedContent: (0, _pagedArray.default)('filtered', {
      page: Ember.computed.alias('parent.page'),
      perPage: Ember.computed.alias('parent.perPage')
    }),
    indexFrom: Ember.computed('page', 'perPage', function () {
      var current = this.get('page');
      var perPage = this.get('perPage');
      return Math.max(0, 1 + perPage * (current - 1));
    }),
    indexTo: Ember.computed('indexFrom', 'perPage', 'filtered.length', function () {
      return Math.min(this.get('filtered.length'), this.get('indexFrom') + this.get('perPage') - 1);
    })
  });

  _exports.default = _default;
});