define("ui/templates/tooltip-action-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "62eIK+eC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"tooltip-content-inner tooltip-dot\"],[9],[0,\"\\n  \"],[1,[27,\"action-menu\",null,[[\"model\",\"showPrimary\",\"inTooltip\",\"class\"],[[23,[\"model\"]],false,true,\"pull-right tooltip-more-actions\"]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"display-name\"],[9],[1,[21,\"displayName\"],false],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"display-ip\"],[9],[1,[27,\"format-ip\",[[23,[\"model\",\"displayIp\"]]],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-action-menu.hbs"
    }
  });

  _exports.default = _default;
});