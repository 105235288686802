define("ui/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "29ITGDPK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"underlay\"],[11,\"class\",\"underlay hide\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"loading-underlay\"],[11,\"class\",\"underlay\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"loading-overlay\"],[11,\"class\",\"loading-overlay\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"settings\",\"isRancher\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"square\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"lid\"],[9],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"whiteboxloader\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"loader-inner ball-clip-rotate-pulse\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[1,[27,\"outlet\",[\"overlay\"],null],false],[0,\"\\n\"],[1,[27,\"outlet\",[\"error\"],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"application\"],[11,\"class\",\"container no-inline-space\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"component\",[[23,[\"tooltip\"]]],[[\"tooltipTemplate\",\"class\",\"id\",\"role\",\"aria-hidden\"],[[23,[\"tooltipTemplate\"]],\"container-tooltip\",\"tooltip-base\",\"tooltip\",\"false\"]]],false],[0,\"\\n\"],[1,[21,\"modal-root\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/application/template.hbs"
    }
  });

  _exports.default = _default;
});