define("ui/templates/-ns-quota-progressbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7GqY3xuK",
    "block": "{\"symbols\":[\"tt\"],\"statements\":[[7,\"table\"],[11,\"class\",\"table fixed tooltip-table\"],[11,\"style\",\"max-width: 300px;\"],[9],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"label\"]],false],[0,\":\"],[10],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,1,[\"value\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/-ns-quota-progressbar.hbs"
    }
  });

  _exports.default = _default;
});