define("ui/components/container/form-upgrade-replica-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h92pO3lj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"box\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[\"formUpgrade.strategy.minReadySeconds.label\"],null],false],[10],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[23,[\"editing\"]],[23,[\"workloadConfig\",\"minReadySeconds\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"input-group\"],[9],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"classNames\",\"value\"],[\"form-control\",[23,[\"workloadConfig\",\"minReadySeconds\"]]]]],false],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"input-group-addon bg-default\"],[9],[1,[27,\"t\",[\"generic.seconds\"],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"help-block\"],[9],[1,[27,\"t\",[\"formUpgrade.strategy.minReadySeconds.detail\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade-replica-set/template.hbs"
    }
  });

  _exports.default = _default;
});