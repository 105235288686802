define("ui/components/project-quota-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "50bPly+B",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"pr-10\"],[9],[0,\"\\n  \"],[1,[27,\"resource-quota-select\",null,[[\"currentQuota\",\"editing\",\"quota\"],[[23,[\"currentQuota\"]],[23,[\"editing\"]],[23,[\"quota\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"pr-10\"],[9],[0,\"\\n  \"],[1,[27,\"input-resource-quota\",null,[[\"editing\",\"quota\",\"key\"],[[23,[\"editing\"]],[23,[\"quota\"]],\"projectLimit\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"pr-10\"],[9],[0,\"\\n  \"],[1,[27,\"input-resource-quota\",null,[[\"editing\",\"quota\",\"key\"],[[23,[\"editing\"]],[23,[\"quota\"]],\"namespaceLimit\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\" \"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-group-btn\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary btn-sm\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-minus\"],[9],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],[23,[\"remove\"]],[23,[\"quota\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/project-quota-row/template.hbs"
    }
  });

  _exports.default = _default;
});