define("ui/utils/additional-routes", ["exports", "shared/utils/additional-routes"], function (_exports, _additionalRoutes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "addRoutes", {
    enumerable: true,
    get: function get() {
      return _additionalRoutes.addRoutes;
    }
  });
  Object.defineProperty(_exports, "applyRoutes", {
    enumerable: true,
    get: function get() {
      return _additionalRoutes.applyRoutes;
    }
  });
  Object.defineProperty(_exports, "clearRoutes", {
    enumerable: true,
    get: function get() {
      return _additionalRoutes.clearRoutes;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _additionalRoutes.default;
    }
  });
});