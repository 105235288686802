define("ui/containers/run/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    queryParams: ['namespaceId', 'workloadId', 'podId', 'addSidekick', 'upgrade', 'launchConfigIndex'],
    namespaceId: null,
    serviceId: null,
    containerId: null,
    addSidekick: null,
    upgrade: false,
    deleting: false,
    dataMap: Ember.computed.alias('model.dataMap'),
    actions: {
      transitionOut: function transitionOut() {
        this.transitionToRoute('containers.index', Ember.get(this, 'scope.currentProject.id'));
      },
      done: function done() {
        this.send('transitionOut');
      },
      cancel: function cancel() {
        this.send('transitionOut');
      },
      promptRemove: function promptRemove(idx) {
        var _this = this;

        var slc = Ember.get(this, 'dataMap.workload.secondaryLaunchConfigs').objectAt(idx);
        var resources = [{
          cb: function cb() {
            _this.removeSidekick(idx);
          },
          displayName: Ember.get(slc, 'name')
        }];
        Ember.get(this, 'modalService').toggleModal('confirm-delete', {
          resources: resources,
          showProtip: false
        });
      }
    },
    removeSidekick: function removeSidekick(idx) {
      var _this2 = this;

      var workload = Ember.get(this, 'dataMap.workload').clone();
      Ember.set(workload, 'completeLaunchConfigs', true);
      Ember.set(workload, 'completeUpdate', true);
      var slcs = Ember.get(workload, 'secondaryLaunchConfigs');
      slcs.removeAt(idx);
      var containers = [Ember.get(workload, 'containers.firstObject')];
      containers.pushObjects(slcs);
      Ember.set(workload, 'containers', containers);
      Ember.set(this, 'deleting', true);
      workload.save().then(function () {
        _this2.send('done');

        Ember.set(_this2, 'deleting', false);
      }).catch(function (err) {
        Ember.get(_this2, 'growl').fromError(err);
        Ember.set(_this2, 'deleting', false);
      });
    }
  });

  _exports.default = _default;
});