define("ui/components/settings/user-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IJui3qZ1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[1,[27,\"t\",[\"userPreferencesPage.generalInfo.header\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"box\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[\"userPreferencesPage.generalInfo.name.label\"],null],false],[10],[0,\"\\n      \"],[1,[27,\"input-or-display\",null,[[\"editable\",\"value\"],[[23,[\"editing\"]],[23,[\"account\",\"name\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[\"userPreferencesPage.generalInfo.username.label\"],null],false],[10],[0,\"\\n      \"],[1,[27,\"input-or-display\",null,[[\"editable\",\"value\"],[[23,[\"editing\"]],[23,[\"account\",\"login\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row text-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12 text-left\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn bg-primary\"],[9],[1,[27,\"t\",[\"userPreferencesPage.generalInfo.password.text\"],null],false],[3,\"action\",[[22,0,[]],\"editPassword\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/user-info/template.hbs"
    }
  });

  _exports.default = _default;
});