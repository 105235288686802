define("ui/authenticated/project/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const NONE = 'none';
  // const WORKLOAD = 'workload';
  var NAMESPACE = 'namespace';
  var NODE = 'node';

  var _default = Ember.Controller.extend({
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    queryParams: ['group'],
    group: NAMESPACE,
    nodes: null,
    expandedInstances: null,
    namespaces: Ember.computed.alias('scope.currentProject.namespaces'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('nodes', this.get('store').all('node'));
      this.set('expandedInstances', []);
    },
    actions: {
      toggleExpand: function toggleExpand(instId) {
        var list = this.get('expandedInstances');

        if (list.includes(instId)) {
          list.removeObject(instId);
        } else {
          list.addObject(instId);
        }
      },
      hideWarning: function hideWarning() {
        this.set('prefs.projects-warning', 'hide');
      }
    },
    showClusterWelcome: function () {
      return this.get('scope.currentCluster.state') === 'inactive' && !this.get('nodes.length');
    }.property('scope.currentCluster.state', 'nodes.[]'),
    groupTableBy: function () {
      if (this.get('group') === NAMESPACE) {
        return 'namespaceId';
      } else if (this.get('group') === NODE) {
        return 'nodeId';
      } else {
        return null;
      }
    }.property('group'),
    preSorts: function () {
      if (this.get('groupTableBy')) {
        return ['namespace.isDefault:desc', 'namespace.displayName'];
      } else {
        return null;
      }
    }.property('groupTableBy'),
    groupChanged: function () {
      var key = "prefs.".concat(_constants.default.PREFS.CONTAINER_VIEW);
      var cur = this.get(key);
      var neu = this.get('group');

      if (cur !== neu) {
        this.set(key, neu);
      }
    }.observes('group')
  });

  _exports.default = _default;
});