define("ui/models/activedirectoryconfig", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ActiveDirectoryConfig = _resource.default.extend({
    type: 'activeDirectoryConfig'
  });

  var _default = ActiveDirectoryConfig;
  _exports.default = _default;
});