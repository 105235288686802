define("ui/components/modal-new-volume/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JSRG4HD/",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-volume\",null,[[\"model\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\"],[[23,[\"model\"]],\"new\",false,[27,\"action\",[[22,0,[]],\"doSave\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-new-volume/template.hbs"
    }
  });

  _exports.default = _default;
});