define("ui/initializers/extend-ember-input", ["exports", "ui/mixins/safe-style"], function (_exports, _safeStyle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    // Allow style to be bound on inputs
    Ember.TextField.reopen(_safeStyle.default);
    Ember.TextArea.reopen(_safeStyle.default);
    Ember.Checkbox.reopen(_safeStyle.default); // Disable iOS auto-capitalization
    //

    Ember.TextField.reopen({
      attributeBindings: ['autocapitalize', 'spellcheck', 'autocomplete'],
      autocomplete: 'off',
      autocapitalize: 'none'
    });
  }

  var _default = {
    name: 'extend-ember-input',
    initialize: initialize
  };
  _exports.default = _default;
});