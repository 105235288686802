define("ui/authenticated/cluster/cluster-catalogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qfINw+48",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary btn-sm\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"catalogSettings.more.addActionLabel\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"add\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"catalogSettings.header\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"custom-catalog\",null,[[\"catalogs\",\"mode\"],[[23,[\"filtered\"]],\"cluster\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/cluster-catalogs/template.hbs"
    }
  });

  _exports.default = _default;
});