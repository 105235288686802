define("ui/authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QWTf5R4V",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[23,[\"isPopup\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"page-header\",null,[[\"pageScope\",\"goToPrevious\"],[[23,[\"pageScope\"]],\"goToPrevious\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"main\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"isPopup\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"page-footer\",null,[[\"showAbout\"],[\"showAbout\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/template.hbs"
    }
  });

  _exports.default = _default;
});