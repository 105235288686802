define("ui/models/container", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "shared/mixins/display-image", "shared/mixins/grafana", "ui/utils/constants"], function (_exports, _resource, _denormalize, _displayImage, _grafana, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Container = _resource.default.extend(_grafana.default, _displayImage.default, {
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    links: {},
    type: 'container',
    grafanaDashboardName: 'Pods',
    pod: (0, _denormalize.reference)('podId'),
    grafanaResourceId: Ember.computed.alias('name'),
    podName: Ember.computed('pod.name', function () {
      return Ember.get(this, 'pod.name');
    }),
    namespaceId: Ember.computed('pod.namespaceId', function () {
      return Ember.get(this, 'pod.namespaceId');
    }),
    canShell: Ember.computed('state', function () {
      return _constants.default.CAN_SHELL_STATES.indexOf(Ember.get(this, 'state')) > -1;
    }),
    availableActions: Ember.computed('canShell', function () {
      var canShell = Ember.get(this, 'canShell');
      var choices = [{
        label: 'action.execute',
        icon: 'icon icon-terminal',
        action: 'shell',
        enabled: canShell,
        altAction: 'popoutShell'
      }, {
        label: 'action.logs',
        icon: 'icon icon-file',
        action: 'logs',
        enabled: true,
        altAction: 'popoutLogs'
      }];
      return choices;
    }),
    restarts: Ember.computed('pod.status.containerStatuses.@each.restartCount', function () {
      var state = (Ember.get(this, 'pod.status.containerStatuses') || []).findBy('name', Ember.get(this, 'name'));

      if (state) {
        return Ember.get(state, 'restartCount');
      }
    }),
    hasCpuReservation: Ember.computed('resources.requests.cpu', function () {
      return !!Ember.get(this, 'resources.requests.cpu');
    }),
    hasMemoryReservation: Ember.computed('resources.requests.memory', function () {
      return !!Ember.get(this, 'resources.requests.memory');
    }),
    validateQuota: function validateQuota(namespace) {
      var projectLimit = Ember.get(this, 'scope.currentProject.resourceQuota.limit');

      if (!projectLimit) {
        return [];
      }

      var intl = Ember.get(this, 'intl');
      var errors = [];
      var limitsCpu = projectLimit.limitsCpu,
          limitsMemory = projectLimit.limitsMemory,
          requestsCpu = projectLimit.requestsCpu,
          requestsMemory = projectLimit.requestsMemory;

      if (limitsCpu && !Ember.get(this, 'resources.limits.cpu') && !Ember.get(namespace, 'containerDefaultResourceLimit.limitsCpu')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', {
          key: intl.t('formResourceQuota.resources.limitsCpu')
        }));
      }

      if (limitsMemory && !Ember.get(this, 'resources.limits.memory') && !Ember.get(namespace, 'containerDefaultResourceLimit.limitsMemory')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', {
          key: intl.t('formResourceQuota.resources.limitsMemory')
        }));
      }

      if (requestsCpu && !Ember.get(this, 'resources.requests.cpu') && !Ember.get(namespace, 'containerDefaultResourceLimit.requestsCpu')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', {
          key: intl.t('formResourceQuota.resources.requestsCpu')
        }));
      }

      if (requestsMemory && !Ember.get(this, 'resources.requests.memory') && !Ember.get(namespace, 'containerDefaultResourceLimit.requestsMemory')) {
        errors.push(intl.t('newContainer.errors.quotaRequired', {
          key: intl.t('formResourceQuota.resources.requestsMemory')
        }));
      }

      return errors;
    },
    actions: {
      shell: function shell() {
        Ember.get(this, 'modalService').toggleModal('modal-shell', {
          model: Ember.get(this, 'pod'),
          containerName: Ember.get(this, 'name')
        });
      },
      popoutShell: function popoutShell() {
        var _this = this;

        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'pod.id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.console', projectId);
        var system = Ember.get(this, 'pod.node.info.os.operatingSystem') || '';
        var windows = false;

        if (system.startsWith('Windows')) {
          windows = true;
        }

        Ember.run.later(function () {
          window.open("//".concat(window.location.host).concat(route, "?podId=").concat(podId, "&windows=").concat(windows, "&containerName=").concat(Ember.get(_this, 'name'), "&isPopup=true"), '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      },
      logs: function logs() {
        Ember.get(this, 'modalService').toggleModal('modal-container-logs', {
          model: Ember.get(this, 'pod'),
          containerName: Ember.get(this, 'name')
        });
      },
      popoutLogs: function popoutLogs() {
        var _this2 = this;

        var projectId = Ember.get(this, 'scope.currentProject.id');
        var podId = Ember.get(this, 'pod.id');
        var route = Ember.get(this, 'router').urlFor('authenticated.project.container-log', projectId);
        Ember.run.later(function () {
          window.open("//".concat(window.location.host).concat(route, "?podId=").concat(podId, "&containerName=").concat(Ember.get(_this2, 'name'), "&isPopup=true"), '_blank', 'toolbars=0,width=900,height=700,left=200,top=200');
        });
      }
    }
  });

  var _default = Container;
  _exports.default = _default;
});