define("ui/components/settings/theme-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ipBRtL6q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[1,[27,\"t\",[\"userPreferencesPage.theme.header\"],null],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"box\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container-theme-toggle row\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"col span-4 bg-default \",[27,\"if\",[[27,\"eq\",[[23,[\"theme\"]],\"ui-light\"],null],\"active\",\"\"],null]]]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-sun icon-3x vertical-middle\"],[9],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"vertical-middle\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"userPreferencesPage.theme.light\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"changeTheme\",\"ui-light\"]],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"col span-4 bg-default \",[27,\"if\",[[27,\"eq\",[[23,[\"theme\"]],\"ui-auto\"],null],\"active\",\"\"],null]]]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-auto icon-3x vertical-middle\"],[9],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"vertical-middle\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"userPreferencesPage.theme.auto\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"changeTheme\",\"ui-auto\"]],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"col span-4 bg-default \",[27,\"if\",[[27,\"eq\",[[23,[\"theme\"]],\"ui-dark\"],null],\"active\",\"\"],null]]]],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-moon icon-3x vertical-middle\"],[9],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"vertical-middle\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"userPreferencesPage.theme.dark\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"changeTheme\",\"ui-dark\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"help-block text-center\"],[9],[1,[27,\"t\",[\"userPreferencesPage.theme.autoDetail\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/theme-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});