define("ui/models/clusteralertgroup", ["exports", "@rancher/ember-api-store/models/resource", "ui/mixins/model-alert"], function (_exports, _resource, _modelAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend(_modelAlert.default, {
    type: 'clusteralertgroup'
  });

  _exports.default = _default;
});