define("ui/helpers/has-override", ["exports", "shared/helpers/has-override"], function (_exports, _hasOverride) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _hasOverride.default;
    }
  });
  Object.defineProperty(_exports, "hasOverride", {
    enumerable: true,
    get: function get() {
      return _hasOverride.hasOverride;
    }
  });
});