define("ui/components/settings/telemetry-opt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ac1GMOR2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"telemetryOpt.header\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"p\"],[11,\"class\",\"text-muted\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"telemetryOpt.subtext\"],[[\"appName\",\"htmlSafe\"],[[23,[\"settings\",\"appName\"]],true]]],false],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[28,[[23,[\"settings\",\"docsBase\"]],\"/faq/telemetry\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener nofollow\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"telemetryOpt.learnMore.label\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"hr\"],[9],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"col-inline checkbox\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"optIn\"]]]]],false],[0,\"\\n    \"],[1,[27,\"t\",[\"telemetryOpt.label\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"save-cancel\",null,[[\"cancelDisabled\",\"editing\",\"save\"],[true,true,[27,\"action\",[[22,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/telemetry-opt/template.hbs"
    }
  });

  _exports.default = _default;
});