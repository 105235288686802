define("ui/components/container-dot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C/0rNe+X",
    "block": "{\"symbols\":[\"container\"],\"statements\":[[4,\"if\",[[23,[\"model\",\"hasSidekicks\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"dot-group bg-default hand\"],[9],[0,\"\\n\"],[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"placement\",\"tagName\",\"tooltipFor\"],[[23,[\"type\"]],[23,[\"model\"]],[23,[\"template\"]],\"top\",\"span\",\"containerDot\"]],{\"statements\":[[4,\"each\",[[23,[\"model\",\"containers\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[12,\"class\",[28,[[22,1,[\"stateIcon\"]],\" \",[22,1,[\"stateColor\"]],\" dot\"]]],[12,\"alt\",[21,\"alt\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"placement\",\"tagName\",\"tooltipFor\"],[[23,[\"type\"]],[23,[\"model\"]],[23,[\"template\"]],\"top\",\"span\",\"containerDot\"]],{\"statements\":[[0,\"    \"],[7,\"i\"],[12,\"class\",[28,[[23,[\"model\",\"stateIcon\"]],\" \",[23,[\"model\",\"stateColor\"]],\" dot hand\"]]],[12,\"alt\",[21,\"alt\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container-dot/template.hbs"
    }
  });

  _exports.default = _default;
});