define("ui/utils/search-text", ["exports", "shared/utils/search-text"], function (_exports, _searchText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "filter", {
    enumerable: true,
    get: function get() {
      return _searchText.filter;
    }
  });
});