define("ui/authenticated/project/config-maps/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var store = Ember.get(this, 'store');
      return Ember.RSVP.hash({
        configMaps: store.findAll('configMap')
      });
    },
    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, "session.".concat(_constants.default.SESSION.PROJECT_ROUTE), 'authenticated.project.config-maps');
    })
  });

  _exports.default = _default;
});