define("ui/models/deployment", ["exports", "ui/models/workload"], function (_exports, _workload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Deployment = _workload.default.extend({
    combinedState: Ember.computed('state', 'isPaused', function () {
      var service = Ember.get(this, 'state');

      if (service === 'active' && Ember.get(this, 'isPaused')) {
        return 'paused';
      }

      return service;
    }),
    isPaused: Ember.computed('paused', function () {
      return !!Ember.get(this, 'paused');
    })
  });

  var _default = Deployment;
  _exports.default = _default;
});