define("ui/models/httpingresspath", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var HttpIngressPath = _resource.default.extend({
    service: (0, _denormalize.reference)('serviceId')
  });

  var _default = HttpIngressPath;
  _exports.default = _default;
});