define("ui/components/cru-volume/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-volume/template", "ui/models/volume", "ui/utils/constants"], function (_exports, _viewNewEdit, _template, _volume, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, {
    intl: Ember.inject.service(),
    features: Ember.inject.service(),
    layout: _template.default,
    model: null,
    sourceName: null,
    titleKey: 'cruVolume.title',
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var selectedSource = (Ember.get(this, 'sourceChoices') || []).find(function (source) {
        return !!Ember.get(_this, "primaryResource.".concat(Ember.get(source, 'value')));
      });

      if (selectedSource) {
        Ember.set(this, 'sourceName', Ember.get(selectedSource, 'name'));
      }
    },
    actions: {
      updateParams: function updateParams(key, map) {
        var _this2 = this;

        (0, _volume.getSources)('ephemeral').forEach(function (source) {
          if (source.value === key) {
            Ember.set(_this2, "primaryResource.".concat(key), map);
          } else {
            Ember.set(_this2, "primaryResource.".concat(source.value), null);
          }
        });
      }
    },
    headerToken: Ember.computed('scope', function () {
      var k = 'cruPersistentVolumeClaim.define.';
      k += Ember.get(this, 'mode');
      return k;
    }),
    sourceChoices: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var skip = ['host-path', 'secret'];
      var out = (0, _volume.getSources)('ephemeral').map(function (p) {
        var entry = Object.assign({}, p);
        var key = "volumeSource.".concat(entry.name, ".title");

        if (skip.includes(entry.name)) {
          entry.priority = 0;
        } else if (intl.exists(key)) {
          entry.label = intl.t(key);

          if (p.persistent) {
            entry.priority = 2;
          } else {
            entry.priority = 1;
          }
        } else {
          entry.label = entry.name;
          entry.priority = 3;
        }

        return entry;
      });
      return out.filter(function (x) {
        return x.priority > 0;
      }).sortBy('priority', 'label');
    }),
    supportedSourceChoices: Ember.computed('sourceChoices', function () {
      var showUnsupported = Ember.get(this, 'features').isFeatureEnabled(_constants.default.FEATURES.UNSUPPORTED_STORAGE_DRIVERS);
      return Ember.get(this, 'sourceChoices').filter(function (choice) {
        return showUnsupported || choice.supported;
      });
    }),
    sourceComponent: Ember.computed('sourceName', function () {
      var name = Ember.get(this, 'sourceName');
      var sources = (0, _volume.getSources)('ephemeral');
      var entry = sources.findBy('name', name);

      if (entry) {
        return {
          component: "volume-source/source-".concat(name),
          field: entry.value
        };
      }
    }),
    willSave: function willSave() {
      var vol = Ember.get(this, 'primaryResource');
      var entry = (0, _volume.getSources)('ephemeral').findBy('name', Ember.get(this, 'sourceName'));

      if (!entry) {
        var errors = [];
        var intl = Ember.get(this, 'intl');
        errors.push(intl.t('validation.required', {
          key: intl.t('cruVolume.source.label')
        }));
        Ember.set(this, 'errors', errors);
        return false;
      }

      var ok = this._super.apply(this, arguments);

      if (ok) {
        var out = Ember.Object.create({
          name: Ember.get(vol, 'name')
        });
        Ember.set(out, entry.value, Ember.get(vol, entry.value));

        if (this.doSave) {
          this.doSave({
            volume: out
          });
        }

        if (this.done) {
          this.done();
        }
      }

      return false;
    }
  });

  _exports.default = _default;
});