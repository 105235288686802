define("ui/components/modal-new-pvc/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-new-pvc/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    callback: Ember.computed.alias('modalService.modalOpts.callback'),
    namespace: Ember.computed.alias('modalService.modalOpts.namespace'),
    model: Ember.computed.alias('modalService.modalOpts.model'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('model')) {
        this.set('model', {});
      }
    },
    actions: {
      doSave: function doSave() {
        var callback = this.get('callback');

        if (callback) {
          callback(this.get('model'));
        }

        this.send('cancel');
      }
    }
  });

  _exports.default = _default;
});