define("ui/components/security-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DtVEPngz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"section\"],[11,\"class\",\"header has-tabs clearfix p-0\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"tab-nav\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.project.secrets\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"secretsPage.index.header\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.project.certificates\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"certificatesPage.index.header\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.project.registries\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"registriesPage.index.header\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/security-header/template.hbs"
    }
  });

  _exports.default = _default;
});