define("ui/security/authentication/ping/controller", ["exports", "global-admin/security/authentication/ping/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _controller.default;
    }
  });
});