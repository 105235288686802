define("ui/models/app", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize", "ui/utils/parse-externalid", "ui/mixins/state-counts", "ui/mixins/endpoint-ports"], function (_exports, _resource, _denormalize, _parseExternalid, _stateCounts, _endpointPorts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var App = _resource.default.extend(_stateCounts.default, _endpointPorts.default, {
    catalog: Ember.inject.service(),
    router: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    canEdit: false,
    namespace: (0, _denormalize.reference)('targetNamespace', 'namespace', 'clusterStore'),
    catalogTemplate: (0, _denormalize.reference)('externalIdInfo.templateId', 'template', 'globalStore'),
    init: function init() {
      this._super.apply(this, arguments);

      this.defineStateCounts('pods', 'podStates', 'podCountSort');
    },
    isIstio: Ember.computed('catalogTemplate.isIstio', function () {
      var catalogTemplate = this.catalogTemplate;

      if (catalogTemplate) {
        return Ember.get(this, 'catalogTemplate.isIstio');
      } else {
        return false;
      }
    }),
    pods: Ember.computed('namespace.pods.@each.workloadId', 'workloads.@each.workloadLabels', function () {
      var _this = this;

      return (Ember.get(this, 'namespace.pods') || []).filter(function (item) {
        if (item['labels']) {
          var inApp = item['labels']['io.cattle.field/appId'] === Ember.get(_this, 'name');

          if (inApp) {
            return true;
          }
        }

        var workload = Ember.get(item, 'workload');

        if (workload) {
          var found = Ember.get(_this, 'workloads').filterBy('id', Ember.get(workload, 'id'));
          return found.length > 0;
        }
      });
    }),
    services: Ember.computed('namespace.services.@each.labels', function () {
      var _this2 = this;

      return (Ember.get(this, 'namespace.services') || []).filter(function (item) {
        if (item['labels']) {
          return item['labels']['io.cattle.field/appId'] === Ember.get(_this2, 'name');
        }
      });
    }),
    dnsRecords: Ember.computed('namespace.services.@each.labels', function () {
      var _this3 = this;

      return (Ember.get(this, 'namespace.services') || []).filter(function (item) {
        if (item['labels']) {
          return item['labels']['io.cattle.field/appId'] === Ember.get(_this3, 'name');
        }
      });
    }),
    workloads: Ember.computed('namespace.workloads.@each.workloadLabels', function () {
      var _this4 = this;

      return (Ember.get(this, 'namespace.workloads') || []).filter(function (item) {
        if (item['workloadLabels']) {
          return item['workloadLabels']['io.cattle.field/appId'] === Ember.get(_this4, 'name');
        }
      });
    }),
    secrets: Ember.computed('namespace.secrets', function () {
      var _this5 = this;

      return (Ember.get(this, 'namespace.secrets') || []).filter(function (item) {
        if (item['labels']) {
          return item['labels']['io.cattle.field/appId'] === Ember.get(_this5, 'name');
        }
      });
    }),
    configMaps: Ember.computed('namespace.configMaps', function () {
      var _this6 = this;

      return (Ember.get(this, 'namespace.configMaps') || []).filter(function (item) {
        if (item['labels']) {
          return item['labels']['io.cattle.field/appId'] === Ember.get(_this6, 'name');
        }
      });
    }),
    ingress: Ember.computed('namespace.ingress', function () {
      var _this7 = this;

      return (Ember.get(this, 'namespace.ingress') || []).filter(function (item) {
        if (item['labels']) {
          return item['labels']['io.cattle.field/appId'] === Ember.get(_this7, 'name');
        }
      });
    }),
    volumes: Ember.computed('namespace.volumes', function () {
      var _this8 = this;

      return (Ember.get(this, 'namespace.volumes') || []).filter(function (item) {
        if (item['labels']) {
          return item['labels']['io.cattle.field/appId'] === Ember.get(_this8, 'name');
        }
      });
    }),
    publicEndpoints: Ember.computed('workloads.@each.publicEndpoints', 'services.@each.proxyEndpoints', function () {
      var out = [];
      Ember.get(this, 'workloads').forEach(function (workload) {
        (Ember.get(workload, 'publicEndpoints') || []).forEach(function (endpoint) {
          out.push(endpoint);
        });
      });
      Ember.get(this, 'services').forEach(function (service) {
        (Ember.get(service, 'proxyEndpoints') || []).forEach(function (endpoint) {
          out.push(endpoint);
        });
      });
      return out;
    }),
    displayAnswerStrings: Ember.computed('answers', function () {
      var out = [];
      var answers = Ember.get(this, 'answers') || {};
      Object.keys(answers).forEach(function (key) {
        out.push(key + (answers[key] ? "=".concat(answers[key]) : ''));
      });
      return out;
    }),
    externalIdInfo: Ember.computed('externalId', function () {
      return (0, _parseExternalid.parseHelmExternalId)(Ember.get(this, 'externalId'));
    }),
    canUpgrade: Ember.computed('actionLinks.{upgrade}', 'catalogTemplate', function () {
      var a = Ember.get(this, 'actionLinks') || {};
      return !!a.upgrade && !Ember.isEmpty(this.catalogTemplate);
    }),
    canClone: Ember.computed('catalogTemplate', function () {
      return !Ember.isEmpty(this.catalogTemplate);
    }),
    canRollback: Ember.computed('catalogTemplate', function () {
      return !Ember.isEmpty(this.catalogTemplate) && !!(this.actionLinks || {}).rollback;
    }),
    availableActions: Ember.computed('actionLinks.{rollback,upgrade}', 'catalogTemplate', function () {
      return [{
        label: 'action.upgrade',
        icon: 'icon icon-edit',
        action: 'upgrade',
        enabled: Ember.get(this, 'canUpgrade')
      }, {
        label: 'action.rollback',
        icon: 'icon icon-history',
        action: 'rollback',
        enabled: Ember.get(this, 'canRollback')
      }, {
        label: 'action.viewYaml',
        icon: 'icon icon-file',
        action: 'viewYaml',
        enabled: !!Ember.get(this, 'isIstio')
      }];
    }),
    actions: {
      viewYaml: function viewYaml() {
        Ember.get(this, 'modalService').toggleModal('modal-istio-yaml', {
          escToClose: true,
          name: Ember.get(this, 'displayName'),
          namespace: Ember.get(this, 'namespace.id'),
          appId: Ember.get(this, 'name')
        });
      },
      upgrade: function upgrade() {
        var templateId = Ember.get(this, 'externalIdInfo.templateId');
        var catalogId = Ember.get(this, 'externalIdInfo.catalog');
        var vKeys = Object.keys(Ember.get(this, 'catalogTemplate.versionLinks'));
        var latestVersion = vKeys[vKeys.length - 1];
        Ember.get(this, 'router').transitionTo('catalog-tab.launch', templateId, {
          queryParams: {
            appId: Ember.get(this, 'id'),
            catalog: catalogId,
            namespaceId: Ember.get(this, 'targetNamespace'),
            upgrade: latestVersion,
            istio: Ember.get(this, 'isIstio')
          }
        });
      },
      rollback: function rollback() {
        Ember.get(this, 'modalService').toggleModal('modal-rollback-app', {
          originalModel: this
        });
      },
      clone: function clone() {
        var templateId = Ember.get(this, 'externalIdInfo.templateId');
        var catalogId = Ember.get(this, 'externalIdInfo.catalog');
        Ember.get(this, 'router').transitionTo('catalog-tab.launch', templateId, {
          queryParams: {
            appId: Ember.get(this, 'id'),
            catalog: catalogId,
            namespaceId: Ember.get(this, 'targetNamespace'),
            clone: true
          }
        });
      }
    }
  });

  var _default = App;
  _exports.default = _default;
});