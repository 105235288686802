define("ui/utils/parse-target", ["exports", "shared/utils/parse-target"], function (_exports, _parseTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _parseTarget.default;
    }
  });
  Object.defineProperty(_exports, "parseTarget", {
    enumerable: true,
    get: function get() {
      return _parseTarget.parseTarget;
    }
  });
  Object.defineProperty(_exports, "stringifyTarget", {
    enumerable: true,
    get: function get() {
      return _parseTarget.stringifyTarget;
    }
  });
});