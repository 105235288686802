define("ui/ingresses/run/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LLpGoQg+",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"new-edit-ingress\",null,[[\"cancel\",\"certificates\",\"done\",\"editing\",\"existing\",\"ingress\",\"namespacedCertificates\"],[[27,\"action\",[[22,0,[]],\"cancel\"],null],[23,[\"model\",\"certificates\"]],[27,\"action\",[[22,0,[]],\"done\"],null],true,[23,[\"model\",\"existing\"]],[23,[\"model\",\"ingress\"]],[23,[\"model\",\"namespacedcertificates\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/ingresses/run/template.hbs"
    }
  });

  _exports.default = _default;
});