define("ui/components/project-resource-quota/component", ["exports", "shared/utils/util", "shared/utils/parse-unit", "ui/components/project-resource-quota/template"], function (_exports, _util, _parseUnit, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    limit: null,
    nsDefaultLimit: null,
    editing: null,
    quotaArray: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initQuotaArray();
    },
    actions: {
      addQuota: function addQuota() {
        Ember.get(this, 'quotaArray').pushObject({
          key: '',
          projectLimit: '',
          namespaceLimit: ''
        });
      },
      removeQuota: function removeQuota(quota) {
        Ember.get(this, 'quotaArray').removeObject(quota);
      }
    },
    quotaDidChange: Ember.observer('quotaArray.@each.{key,projectLimit,namespaceLimit}', function () {
      var _this = this;

      var limit = {};
      var nsDefaultLimit = {};
      (Ember.get(this, 'quotaArray') || []).forEach(function (quota) {
        if (quota.key && (quota.projectLimit || quota.namespaceLimit)) {
          limit[quota.key] = _this.convertToString(quota.key, quota.projectLimit);
          nsDefaultLimit[quota.key] = _this.convertToString(quota.key, quota.namespaceLimit);
        }
      });
      var out = null;

      if (Object.keys(limit).length) {
        out = {
          resourceQuota: {
            limit: limit
          },
          namespaceDefaultResourceQuota: {
            limit: nsDefaultLimit
          }
        };
      }

      if (this.changed) {
        this.changed(out);
      }
    }),
    convertToString: function convertToString(key, value) {
      if (!value) {
        return '';
      }

      switch (key) {
        case 'limitsCpu':
        case 'requestsCpu':
          return "".concat(value, "m");

        case 'limitsMemory':
        case 'requestsMemory':
          return "".concat(value, "Mi");

        case 'requestsStorage':
          return "".concat(value, "Gi");

        default:
          return value;
      }
    },
    convertToLimit: function convertToLimit(key, value) {
      if (!value) {
        return '';
      }

      switch (key) {
        case 'limitsCpu':
        case 'requestsCpu':
          return (0, _util.convertToMillis)(value);

        case 'limitsMemory':
        case 'requestsMemory':
          return (0, _parseUnit.parseSi)(value, 1024) / 1048576;

        case 'requestsStorage':
          return (0, _parseUnit.parseSi)(value) / Math.pow(1024, 3);

        default:
          return value;
      }
    },
    initQuotaArray: function initQuotaArray() {
      var _this2 = this;

      var limit = Ember.get(this, 'limit') || {};
      var nsDefaultLimit = Ember.get(this, 'nsDefaultLimit') || {};
      var array = [];
      Object.keys(limit).forEach(function (key) {
        if (key !== 'type' && typeof limit[key] === 'string') {
          var projectLimit = _this2.convertToLimit(key, limit[key]);

          var namespaceLimit = _this2.convertToLimit(key, nsDefaultLimit[key]);

          array.push({
            key: key,
            projectLimit: projectLimit,
            namespaceLimit: namespaceLimit
          });
        }
      });
      Ember.set(this, 'quotaArray', array);
    }
  });

  _exports.default = _default;
});