define("ui/authenticated/project/help/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      this.get('store').findAll('host').then(function (hosts) {
        _this.controllerFor('authenticated.project.help').set('hasHosts', hosts.get('length') > 0);
      });
    },
    resetController: function resetController(controller, isExisting
    /* , transition*/
    ) {
      if (isExisting) {
        controller.set('modelResolved', false);
        controller.set('modelError', false);
      }
    },
    actions: {
      didTransition: function didTransition() {
        var _this2 = this;

        Ember.$.getJSON("".concat(_constants.default.EXT_REFERENCES.FORUM, "/categories.json")).then(function (response) {
          var modelOut = {
            resolved: true
          };
          var promises = {};
          response.category_list.categories.forEach(function (item) {
            switch (item.name) {
              case 'Announcements':
                modelOut.announcements = item;
                promises['announcements'] = Ember.$.getJSON("".concat(_constants.default.EXT_REFERENCES.FORUM, "/c/").concat(item.id, "/l/latest.json"));
                break;

              case 'General':
                modelOut.general = item;
                promises['general'] = Ember.$.getJSON("".concat(_constants.default.EXT_REFERENCES.FORUM, "/c/").concat(item.id, "/l/latest.json"));
                break;

              case 'Rancher':
                modelOut.rancher = item;
                promises['rancher'] = Ember.$.getJSON("".concat(_constants.default.EXT_REFERENCES.FORUM, "/c/").concat(item.id, "/l/latest.json"));
                break;

              case 'RancherOS':
                modelOut.rancherOS = item;
                promises['rancherOS'] = Ember.$.getJSON("".concat(_constants.default.EXT_REFERENCES.FORUM, "/c/").concat(item.id, "/l/latest.json"));
                break;

              default:
                break;
            }
          });
          Ember.RSVP.hash(promises).then(function (hash) {
            Object.keys(hash).forEach(function (key) {
              var topics = hash[key].topic_list.topics.filterBy('pinned', false);
              topics.length = 5;
              modelOut[key].topics = topics;
            });

            _this2.controller.set('model', modelOut);
          }).catch(fail);
        }, fail);
        return true; // bubble the transition event

        function fail()
        /* error*/
        {
          var modelOut = {
            resolved: true,
            error: true
          };
          this.controller.set('model', modelOut);
        }
      }
    }
  });

  _exports.default = _default;
});