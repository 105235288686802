define("ui/components/container-logs/component", ["exports", "ui/utils/util", "ui/utils/platform", "ui/components/container-logs/template", "ui/utils/constants", "shared/utils/download-files"], function (_exports, _util, _platform, _template, _constants, _downloadFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LINES = 500;
  var AnsiUp = null;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    prefs: Ember.inject.service(),
    layout: _template.default,
    instance: null,
    alternateLabel: _platform.alternateLabel,
    showProtip: true,
    classNames: 'container-log',
    status: 'connecting',
    containerName: null,
    socket: null,
    wrapLines: null,
    isFollow: true,
    followTimer: null,
    isPrevious: false,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (AnsiUp) {
        this._bootstrap();
      } else {
        emberAutoImportDynamic("ansi_up").then(function (module) {
          AnsiUp = module.default;

          _this._bootstrap();
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();

      Ember.run.next(this, function () {
        var body = _this2.$('.log-body');

        var lastScrollTop = 0;
        body.scroll(function () {
          var scrollTop = body[0].scrollTop;

          if (lastScrollTop > scrollTop) {
            Ember.set(_this2, 'isFollow', false);
          }

          lastScrollTop = scrollTop;
        });

        _this2.exec();

        var btn = $('.scroll-bottom')[0]; // eslint-disable-line

        if (btn) {
          btn.focus();
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(Ember.get(this, 'followTimer'));
      this.disconnect();

      this._super();
    },
    actions: {
      download: function download() {
        var _this3 = this;

        var ignore = function ignore(el, sel) {
          return el.clone().find(sel || '>*').remove().end();
        };

        var log = this.$('.log-body').children('.log-msg');
        var stripped = '';
        log.each(function (i, e) {
          stripped += "".concat(ignore(_this3.$(e), 'span').text(), " \n");
        });
        (0, _downloadFiles.downloadFile)('container.log', stripped);
      },
      cancel: function cancel() {
        this.disconnect();

        if (this.dismiss) {
          this.dismiss();
        }
      },
      clear: function clear() {
        var body = this.$('.log-body')[0];
        body.innerHTML = '';
        body.scrollTop = 0;
      },
      scrollToTop: function scrollToTop() {
        this.$('.log-body').animate({
          scrollTop: '0px'
        });
      },
      followLog: function followLog() {
        Ember.set(this, 'isFollow', true);
        this.send('scrollToBottom');
      },
      scrollToBottom: function scrollToBottom() {
        var body = this.$('.log-body');
        body.stop().animate({
          scrollTop: "".concat(body[0].scrollHeight + 1000, "px")
        });
      }
    },
    watchReconnect: Ember.observer('containerName', 'isPrevious', function () {
      this.disconnect();
      this.send('clear');
      this.exec();
    }),
    wrapLinesDidChange: Ember.observer('wrapLines', function () {
      Ember.set(this, "prefs.".concat(_constants.default.PREFS.WRAP_LINES), Ember.get(this, 'wrapLines'));
    }),
    _bootstrap: function _bootstrap() {
      Ember.setProperties(this, {
        wrapLines: !!Ember.get(this, "prefs.".concat(_constants.default.PREFS.WRAP_LINES)),
        containerName: Ember.get(this, 'containerName') || Ember.get(this, 'instance.containers.firstObject.name')
      });

      this._initTimer();
    },
    _initTimer: function _initTimer() {
      var _this4 = this;

      var followTimer = setInterval(function () {
        if (Ember.get(_this4, 'isFollow')) {
          _this4.send('scrollToBottom');
        }
      }, 1000);
      Ember.set(this, 'followTimer', followTimer);
    },
    exec: function exec() {
      var instance = Ember.get(this, 'instance');
      var clusterId = Ember.get(this, 'scope.currentCluster.id');
      var namespaceId = Ember.get(instance, 'namespaceId');
      var podName = Ember.get(instance, 'name');
      var containerName = Ember.get(this, 'containerName');
      var scheme = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
      var url = "".concat(scheme).concat(window.location.host, "/k8s/clusters/").concat(clusterId, "/api/v1/namespaces/").concat(namespaceId, "/pods/").concat(podName, "/log");
      url += "?container=".concat(encodeURIComponent(containerName), "&tailLines=").concat(LINES, "&follow=true&timestamps=true&previous=").concat(Ember.get(this, 'isPrevious'));
      this.connect(url);
    },
    connect: function connect(url) {
      var _this5 = this;

      var socket = new WebSocket(url, 'base64.binary.k8s.io');
      Ember.set(this, 'socket', socket);
      var body = this.$('.log-body')[0];
      var $body = $(body); // eslint-disable-line

      Ember.set(this, 'status', 'initializing');

      socket.onopen = function () {
        Ember.set(_this5, 'status', 'connected');
      };

      socket.onmessage = function (message) {
        var ansiup = new AnsiUp();
        Ember.set(_this5, 'status', 'connected');
        var data = AWS.util.base64.decode(message.data).toString();
        var html = '';
        data.trim().split(/\n/).filter(function (line) {
          return line;
        }).forEach(function (line) {
          var match = line.match(/^\[?([^ \]]+)\]?\s?/);
          var dateStr = '';
          var msg = '';

          if (match && _this5.isDate(new Date(match[1]))) {
            var date = new Date(match[1]);
            msg = line.substr(match[0].length);
            dateStr = "<span class=\"log-date\">".concat(_util.default.escapeHtml(date.toLocaleDateString()), " ").concat(_util.default.escapeHtml(date.toLocaleTimeString()), " </span>");
          } else {
            msg = line;
          } // @@TODO@@ - 10-13-17 - needed to remove the escaping here because it was being double escaped but double verify that its acutally being escaped


          html += "<div class=\"log-msg log-combined\">".concat(dateStr).concat(ansiup.ansi_to_html(msg), "</div>");
        });
        body.insertAdjacentHTML('beforeend', html);
      };

      socket.onclose = function () {
        if (_this5.isDestroyed || _this5.isDestroying) {
          return;
        }

        Ember.set(_this5, 'status', 'disconnected');
      };
    },
    disconnect: function disconnect() {
      Ember.set(this, 'status', 'closed');
      var socket = Ember.get(this, 'socket');

      if (socket) {
        socket.close();
        Ember.set(this, 'socket', null);
      }
    },
    isDate: function isDate(date) {
      return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
    }
  });

  _exports.default = _default;
});