define("ui/templates/-add-cluster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ta3S/7u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[1,[27,\"t\",[\"clustersPage.addPage.host.clusterName\"],null],false],[1,[21,\"field-required\"],false],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\"],[\"text\",[23,[\"newHost\",\"user\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[1,[27,\"t\",[\"clustersPage.addPage.host.hostName\"],null],false],[1,[21,\"field-required\"],false],[10],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"value\"],[\"text\",[23,[\"newHost\",\"advertisedHostname\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pt-10 pb-15\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[1,[27,\"t\",[\"clustersPage.addPage.host.ssh\"],null],false],[1,[21,\"field-required\"],false],[10],[0,\"\\n    \"],[1,[27,\"textarea\",null,[[\"value\",\"classNames\",\"rows\"],[[23,[\"newHost\",\"ssh\"]],\"form-control no-resize\",\"6\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/-add-cluster.hbs"
    }
  });

  _exports.default = _default;
});