define("ui/models/secret", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    allWorkloads: Ember.inject.service(),
    state: 'active',
    canClone: true,
    firstKey: Ember.computed.alias('keys.firstObject'),
    keys: Ember.computed('data', function () {
      return Object.keys(Ember.get(this, 'data') || {}).sort();
    }),
    workloads: Ember.computed('allWorkloads.list.@each.volumes', 'allWorkloads.list.@each.containers', function () {
      var _this = this;

      return (Ember.get(this, 'allWorkloads.list') || []).map(function (item) {
        return item.obj;
      }).filter(function (workload) {
        if (Ember.get(_this, 'namespaceId') && Ember.get(workload, 'namespaceId') !== Ember.get(_this, 'namespaceId')) {
          return false;
        }

        var volume = (Ember.get(workload, 'volumes') || []).find(function (volume) {
          return Ember.get(volume, 'secret.secretName') === Ember.get(_this, 'name');
        });
        var env = (Ember.get(workload, 'containers') || []).find(function (container) {
          return (Ember.get(container, 'environmentFrom') || []).find(function (env) {
            return Ember.get(env, 'source') === 'secret' && Ember.get(env, 'sourceName') === Ember.get(_this, 'name');
          });
        });
        return volume || env;
      });
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.secrets.detail.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.secrets.new', {
          queryParams: {
            id: Ember.get(this, 'id'),
            type: Ember.get(this, 'type')
          }
        });
      }
    }
  });

  _exports.default = _default;
});