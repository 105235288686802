define("ui/components/modal-edit-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kce/O7ne",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"t\",[\"modalEditPassword.edit.title\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showHelp\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"help-text\"],[9],[1,[27,\"t\",[\"modalEditPassword.edit.help\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[1,[27,\"input-edit-password\",null,[[\"complete\",\"showCurrent\",\"showDeleteTokens\",\"cancel\",\"editButton\",\"user\"],[[27,\"action\",[[22,0,[]],\"complete\"],null],true,true,[27,\"action\",[[22,0,[]],\"cancel\"],null],\"modalEditPassword.actionButton\",[23,[\"user\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-edit-password/template.hbs"
    }
  });

  _exports.default = _default;
});