define("ui/models/principal", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants", "identicon.js"], function (_exports, _resource, _constants, _identicon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Principal = _resource.default.extend({
    intl: Ember.inject.service(),
    isUser: Ember.computed.equal('parsedExternalType', _constants.default.PROJECT.TYPE_USER),
    isTeam: Ember.computed.equal('parsedExternalType', _constants.default.PROJECT.TYPE_TEAM),
    isOrg: Ember.computed.equal('parsedExternalType', _constants.default.PROJECT.TYPE_ORG),
    parsedExternalType: Ember.computed('id', function () {
      return Ember.get(this, 'id').split(':').get('firstObject');
    }),
    avatarSrc: Ember.computed('isGithub', 'isGoogleOauth', 'id', 'profilePicture', function () {
      if (Ember.get(this, 'isGithub') && Ember.get(this, 'profilePicture') || Ember.get(this, 'isGoogleOauth') && Ember.get(this, 'profilePicture')) {
        return Ember.get(this, 'profilePicture');
      } else {
        var id = Ember.get(this, 'id') || 'Unknown';
        id = id.replace('local://', '');
        return "data:image/png;base64,".concat(new _identicon.default(AWS.util.crypto.md5(id, 'hex'), 80, 0.01).toString());
      }
    }),
    isGithub: Ember.computed('parsedExternalType', function () {
      // console.log('is github?', get(this, 'provider'));
      return (Ember.get(this, 'provider') || '').toLowerCase() === 'github';
    }),
    isGoogleOauth: Ember.computed('parsedExternalType', function () {
      return (Ember.get(this, 'provider') || '').toLowerCase() === 'googleoauth';
    }),
    logicalType: Ember.computed('parsedExternalType', function () {
      switch (Ember.get(this, 'parsedExternalType')) {
        case _constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_USER:
        case _constants.default.PROJECT.TYPE_ADFS_USER:
        case _constants.default.PROJECT.TYPE_AZURE_USER:
        case _constants.default.PROJECT.TYPE_FREEIPA_USER:
        case _constants.default.PROJECT.TYPE_GITHUB_USER:
        case _constants.default.PROJECT.TYPE_GOOGLE_USER:
        case _constants.default.PROJECT.TYPE_KEYCLOAK_USER:
        case _constants.default.PROJECT.TYPE_LDAP_USER:
        case _constants.default.PROJECT.TYPE_OPENLDAP_USER:
        case _constants.default.PROJECT.TYPE_PING_USER:
        case _constants.default.PROJECT.TYPE_RANCHER:
        case _constants.default.PROJECT.TYPE_SHIBBOLETH_USER:
          return _constants.default.PROJECT.PERSON;

        case _constants.default.PROJECT.TYPE_GITHUB_TEAM:
          return _constants.default.PROJECT.TEAM;

        case _constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_GROUP:
        case _constants.default.PROJECT.TYPE_ADFS_GROUP:
        case _constants.default.PROJECT.TYPE_AZURE_GROUP:
        case _constants.default.PROJECT.TYPE_FREEIPA_GROUP:
        case _constants.default.PROJECT.TYPE_GITHUB_ORG:
        case _constants.default.PROJECT.TYPE_KEYCLOAK_GROUP:
        case _constants.default.PROJECT.TYPE_LDAP_GROUP:
        case _constants.default.PROJECT.TYPE_OPENLDAP_GROUP:
        case _constants.default.PROJECT.TYPE_PING_GROUP:
        case _constants.default.PROJECT.TYPE_SHIBBOLETH_GROUP:
        case _constants.default.PROJECT.TYPE_GOOGLE_GROUP:
          return _constants.default.PROJECT.ORG;
      }
    }),
    logicalTypeSort: Ember.computed('logicalType', function () {
      switch (Ember.get(this, 'logicalType')) {
        case _constants.default.PROJECT.ORG:
          return 1;

        case _constants.default.PROJECT.TEAM:
          return 2;

        case _constants.default.PROJECT.PERSON:
          return 3;

        default:
          return 4;
      }
    }),
    displayType: Ember.computed('parsedExternalType', 'intl.locale', function () {
      var key = 'model.identity.displayType.unknown';
      var type = Ember.get(this, 'parsedExternalType');

      switch (type) {
        case _constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_USER:
        case _constants.default.PROJECT.TYPE_ADFS_USER:
        case _constants.default.PROJECT.TYPE_OKTA_USER:
        case _constants.default.PROJECT.TYPE_AZURE_USER:
        case _constants.default.PROJECT.TYPE_FREEIPA_USER:
        case _constants.default.PROJECT.TYPE_GITHUB_USER:
        case _constants.default.PROJECT.TYPE_GOOGLE_USER:
        case _constants.default.PROJECT.TYPE_KEYCLOAK_USER:
        case _constants.default.PROJECT.TYPE_LDAP_USER:
        case _constants.default.PROJECT.TYPE_OPENLDAP_USER:
        case _constants.default.PROJECT.TYPE_PING_USER:
        case _constants.default.PROJECT.TYPE_SHIBBOLETH_USER:
          key = 'model.identity.displayType.user';
          break;

        case _constants.default.PROJECT.TYPE_ACTIVE_DIRECTORY_GROUP:
        case _constants.default.PROJECT.TYPE_ADFS_GROUP:
        case _constants.default.PROJECT.TYPE_OKTA_GROUP:
        case _constants.default.PROJECT.TYPE_AZURE_GROUP:
        case _constants.default.PROJECT.TYPE_FREEIPA_GROUP:
        case _constants.default.PROJECT.TYPE_KEYCLOAK_GROUP:
        case _constants.default.PROJECT.TYPE_LDAP_GROUP:
        case _constants.default.PROJECT.TYPE_OPENLDAP_GROUP:
        case _constants.default.PROJECT.TYPE_PING_GROUP:
        case _constants.default.PROJECT.TYPE_SHIBBOLETH_GROUP:
        case _constants.default.PROJECT.TYPE_GOOGLE_GROUP:
          key = 'model.identity.displayType.group';
          break;

        case _constants.default.PROJECT.TYPE_GITHUB_TEAM:
          key = 'model.identity.displayType.team';
          break;

        case _constants.default.PROJECT.TYPE_GITHUB_ORG:
          key = 'model.identity.displayType.org';
          break;

        case _constants.default.PROJECT.TYPE_RANCHER:
          key = 'model.identity.displayType.localUser';
          break;
      }

      return Ember.get(this, 'intl').t(key, {
        type: type
      });
    })
  });

  Principal.reopenClass({
    mangleIn: function mangleIn(data
    /* , store */
    ) {
      if (data.displayName) {
        // set to name then delete
        data.name = data.displayName;
        delete data.displayName;
      }

      return data;
    }
  });
  var _default = Principal;
  _exports.default = _default;
});