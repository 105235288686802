define("ui/components/form-env-var/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZCsU+B8x",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"label\"],\"statements\":[[4,\"sortable-table\",null,[[\"classNames\",\"bulkActions\",\"rowActions\",\"paging\",\"search\",\"sortBy\",\"stickyHeader\",\"descending\",\"headers\",\"body\"],[\"grid fixed mb-0 sortable-table\",false,false,false,true,[23,[\"sortBy\"]],false,[23,[\"descending\"]],[23,[\"headers\"]],[23,[\"model\"]]]],{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[\"formEnvVar.labels.key\"],null],\":\"]]],[11,\"class\",\"force-wrap\"],[9],[1,[22,3,[\"key\"]],false],[10],[0,\"\\n      \"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[\"formEnvVar.labels.value\"],null],\":\"]]],[11,\"class\",\"force-wrap\"],[9],[1,[22,3,[\"value\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[12,\"colspan\",[28,[[22,1,[\"fullColspan\"]]]]],[11,\"class\",\"text-center text-muted pt-20 pb-20\"],[9],[1,[27,\"t\",[\"formEnvVar.noData\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1,2,3]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-env-var/template.hbs"
    }
  });

  _exports.default = _default;
});