define("ui/authenticated/cluster/storage/persistent-volumes/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5E1H/SZD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.cluster.storage.persistent-volumes.new\"],[[\"class\",\"disabled\"],[\"btn btn-sm bg-primary\",[27,\"rbac-prevents\",null,[[\"resource\",\"scope\",\"permission\"],[\"persistentvolume\",\"cluster\",\"create\"]]]]],{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"persistentVolumePage.addActionLabel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"persistentVolumePage.header\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"persistent-volume-table\",null,[[\"rows\"],[[23,[\"model\",\"persistentVolumes\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/persistent-volumes/index/template.hbs"
    }
  });

  _exports.default = _default;
});