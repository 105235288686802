define("ui/helpers/array-includes", ["exports", "shared/helpers/array-includes"], function (_exports, _arrayIncludes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _arrayIncludes.default;
    }
  });
});