define("ui/authenticated/project/hpa/detail/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      back: function back() {
        return this.transitionToRoute('authenticated.project.hpa.index');
      },
      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });

  _exports.default = _default;
});