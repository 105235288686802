define("ui/components/form-node-selector/component", ["exports", "ui/components/form-node-selector/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    rule: null,
    editing: true,
    actions: {
      removeRule: function removeRule(rule) {
        this.removeRule(rule);
      }
    },
    removeRule: function removeRule() {
      throw new Error('removeRule action is required!');
    }
  });

  _exports.default = _default;
});