define("ui/initializers/touch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application*/
  {
    // Add 'touch' or 'no-touch' to the <body> so CSS can depend on the device type.
    var body = $('BODY'); // eslint-disable-line

    if ('ontouchstart' in document.documentElement) {
      // Has touch, like an iPad
      body.addClass('touch');
    } else {
      // Does not have touch, like a desktop
      body.addClass('no-touch');
    }
  }

  var _default = {
    name: 'touch',
    initialize: initialize
  };
  _exports.default = _default;
});