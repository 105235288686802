define("ui/components/volume-source/source-local/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wWwiT7/",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-6\"],[9],[0,\"\\n      \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[\"cruPersistentVolume.local.path.label\"],null],false],[10],[0,\"\\n\"],[4,\"input-or-display\",null,[[\"editable\",\"value\"],[[23,[\"editing\"]],[23,[\"config\",\"path\"]]]],{\"statements\":[[0,\"        \"],[1,[27,\"input\",null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[23,[\"config\",\"path\"]],\"form-control\",[27,\"t\",[\"cruPersistentVolume.local.path.placeholder\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/volume-source/source-local/template.hbs"
    }
  });

  _exports.default = _default;
});