define("ui/authenticated/cluster/storage/classes/detail/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var original = this.modelFor('authenticated.cluster.storage.classes.detail');
      Ember.set(this, 'originalModel', Ember.get(original, 'storageclass'));
      return Ember.get(original, 'storageclass').clone();
    },
    setupController: function setupController(controller
    /* , model*/
    ) {
      this._super.apply(this, arguments);

      Ember.set(controller, 'originalModel', this.modelFor('authenticated.cluster.storage.classes.detail'));
    }
  });

  _exports.default = _default;
});