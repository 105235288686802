define("ui/models/storageclass", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerProvisioner = registerProvisioner;
  _exports.getProvisioners = getProvisioners;
  _exports.default = void 0;
  var BETA_ANNOTATION = 'storageclass.beta.kubernetes.io/is-default-class';
  var DEFAULT_ANNOTATION = 'storageclass.kubernetes.io/is-default-class';
  var PROVISIONERS = [];
  registerProvisioner('aws-ebs', 'kubernetes.io/aws-ebs', true, true);
  registerProvisioner('gce-pd', 'kubernetes.io/gce-pd', true, true);
  registerProvisioner('glusterfs', 'kubernetes.io/glusterfs', true, false);
  registerProvisioner('cinder', 'kubernetes.io/cinder', true, false);
  registerProvisioner('vsphere-volume', 'kubernetes.io/vsphere-volume', true, true);
  registerProvisioner('rbd', 'kubernetes.io/rbd', true, false);
  registerProvisioner('quobyte', 'kubernetes.io/quobyte', true, false);
  registerProvisioner('azure-disk', 'kubernetes.io/azure-disk', true, true);
  registerProvisioner('azure-file', 'kubernetes.io/azure-file', true, true);
  registerProvisioner('portworx-volume', 'kubernetes.io/portworx-volume', true, false);
  registerProvisioner('scaleio', 'kubernetes.io/scaleio', true, false);
  registerProvisioner('storageos', 'kubernetes.io/storageos', true, false);
  registerProvisioner('longhorn', 'rancher.io/longhorn', true, true);

  function registerProvisioner(name, provisioner, component, supported) {
    if (component === true) {
      component = name;
    }

    var existing = PROVISIONERS.findBy('name', name);

    if (existing) {
      PROVISIONERS.removeObject(existing);
    }

    PROVISIONERS.push({
      name: name,
      value: provisioner,
      component: component,
      supported: supported
    });
  }

  function getProvisioners() {
    return JSON.parse(JSON.stringify(PROVISIONERS));
  }

  var _default = _resource.default.extend({
    clusterStore: Ember.inject.service(),
    router: Ember.inject.service(),
    type: 'storageClass',
    state: 'active',
    isDefault: Ember.computed('annotations', function () {
      var annotations = Ember.get(this, 'annotations') || {};
      return annotations[DEFAULT_ANNOTATION] === 'true' || annotations[BETA_ANNOTATION] === 'true';
    }),
    availableActions: Ember.computed('isDefault', function () {
      var isDefault = Ember.get(this, 'isDefault');
      var out = [{
        label: 'action.makeDefault',
        icon: 'icon icon-star-fill',
        action: 'makeDefault',
        enabled: !isDefault
      }, {
        label: 'action.resetDefault',
        icon: 'icon icon-star-line',
        action: 'resetDefault',
        enabled: isDefault
      }];
      return out;
    }),
    displayProvisioner: Ember.computed('provisioner', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var provisioner = Ember.get(this, 'provisioner');
      var entry = PROVISIONERS.findBy('value', provisioner);

      if (provisioner && entry) {
        var key = "storageClass.".concat(entry.name, ".title");

        if (intl.exists(key)) {
          return intl.t(key);
        }
      }

      return provisioner;
    }),
    actions: {
      makeDefault: function makeDefault() {
        var _this = this;

        var cur = Ember.get(this, 'clusterStore').all('storageClass').filterBy('isDefault', true);
        var promises = [];
        cur.forEach(function (sc) {
          promises.push(sc.setDefault(false));
        });
        Ember.RSVP.all(promises).then(function () {
          _this.setDefault(true);
        });
      },
      resetDefault: function resetDefault() {
        this.setDefault(false);
      },
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.storage.classes.detail.edit', Ember.get(this, 'id'));
      }
    },
    setDefault: function setDefault(on) {
      var annotations = Ember.get(this, 'annotations');

      if (!annotations) {
        annotations = {};
        Ember.set(this, 'annotations', annotations);
      }

      if (on) {
        annotations[DEFAULT_ANNOTATION] = 'true';
        annotations[BETA_ANNOTATION] = 'true';
      } else {
        annotations[DEFAULT_ANNOTATION] = 'false';
        annotations[BETA_ANNOTATION] = 'false';
      }

      this.save();
    }
  });

  _exports.default = _default;
});