define("ui/models/clusterlogging", ["exports", "@rancher/ember-api-store/models/resource", "ui/mixins/logging-model"], function (_exports, _resource, _loggingModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend(_loggingModel.default, {
    type: 'clusterlogging'
  });

  _exports.default = _default;
});