define("ui/instance-initializers/nav", ["exports", "ui/utils/navigation-tree"], function (_exports, _navigationTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var rootNav = [// Project
  {
    scope: 'project',
    id: 'infra',
    localizedLabel: 'nav.infra.tab',
    ctx: [_navigationTree.getProjectId],
    submenu: [{
      id: 'containers',
      localizedLabel: 'nav.containers.tab',
      route: 'authenticated.project.index',
      ctx: [_navigationTree.getProjectId],
      resource: ['workload', 'ingress', 'service'],
      resourceScope: 'project',
      currentWhen: ['containers', 'workload', 'ingresses', 'authenticated.project.dns', 'volumes']
    }, {
      id: 'hpa',
      localizedLabel: 'nav.infra.hpa',
      route: 'authenticated.project.hpa',
      ctx: [_navigationTree.getProjectId],
      resource: ['horizontalpodautoscaler'],
      resourceScope: 'project'
    }, {
      id: 'pipelines',
      localizedLabel: 'nav.infra.pipelines',
      route: 'authenticated.project.pipeline.pipelines',
      ctx: [_navigationTree.getProjectId],
      resource: [],
      resourceScope: 'project'
    }, {
      id: 'istio',
      localizedLabel: 'nav.tools.istio',
      route: 'authenticated.project.istio.index',
      ctx: [_navigationTree.getProjectId],
      resource: [],
      resourceScope: 'project',
      currentWhen: ['authenticated.project.istio.project-istio']
    }, {
      id: 'infra-secrets',
      localizedLabel: 'nav.infra.secrets',
      route: 'authenticated.project.secrets',
      ctx: [_navigationTree.getProjectId],
      resource: ['namespacedsecret', 'secret', 'dockercredential', 'certificate'],
      resourceScope: 'project',
      currentWhen: ['authenticated.project.certificates', 'authenticated.project.registries', 'authenticated.project.secrets']
    }, {
      id: 'infra-config-maps',
      localizedLabel: 'nav.infra.configMaps',
      route: 'authenticated.project.config-maps',
      ctx: [_navigationTree.getProjectId],
      resource: ['configmap'],
      resourceScope: 'project'
    }]
  }, {
    scope: 'project',
    id: 'project-apps',
    localizedLabel: 'nav.apps.tab',
    route: 'apps-tab',
    ctx: [_navigationTree.getProjectId],
    resource: ['app'],
    resourceScope: 'project'
  }, {
    scope: 'project',
    id: 'namespaces',
    localizedLabel: 'nav.project.namespaces',
    route: 'authenticated.project.ns.index',
    ctx: [_navigationTree.getProjectId],
    resource: ['namespace'],
    resourceScope: 'cluster'
  }, {
    scope: 'project',
    id: 'project-security-roles',
    localizedLabel: 'nav.infra.members',
    route: 'authenticated.project.security.members',
    resource: ['projectroletemplatebinding'],
    resourceScope: 'global',
    ctx: [_navigationTree.getProjectId]
  }, {
    scope: 'project',
    id: 'project-tools',
    localizedLabel: 'nav.tools.tab',
    ctx: [_navigationTree.getProjectId],
    resource: [],
    resourceScope: 'global',
    submenu: [{
      id: 'tools-alerts',
      localizedLabel: 'nav.tools.alerts',
      route: 'authenticated.project.alert',
      resource: [],
      ctx: [_navigationTree.getProjectId],
      resourceScope: 'global'
    }, {
      id: 'manage-catalogs',
      localizedLabel: 'nav.tools.catalogs',
      route: 'authenticated.project.project-catalogs',
      ctx: [_navigationTree.getProjectId],
      resource: ['catalog', 'project-catalog'],
      resourceScope: 'global'
    }, {
      id: 'tools-logging',
      localizedLabel: 'nav.tools.logging',
      route: 'authenticated.project.logging',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getProjectId]
    }, {
      id: 'tools-monitoring',
      localizedLabel: 'nav.tools.monitoring',
      route: 'authenticated.project.monitoring.project-setting',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getProjectId]
    }, {
      id: 'tools-pipeline',
      localizedLabel: 'nav.tools.pipeline',
      route: 'authenticated.project.pipeline.settings',
      resource: ['sourcecodeproviderconfig'],
      resourceScope: 'project',
      ctx: [_navigationTree.getProjectId]
    }]
  }, // Cluster
  {
    scope: 'cluster',
    id: 'cluster-k8s',
    localizedLabel: 'nav.cluster.dashboard',
    route: 'authenticated.cluster.monitoring.index',
    ctx: [_navigationTree.getClusterId],
    resource: ['node'],
    resourceScope: 'global'
  }, {
    scope: 'cluster',
    id: 'cluster-nodes',
    localizedLabel: 'nav.cluster.nodes',
    route: 'authenticated.cluster.nodes',
    ctx: [_navigationTree.getClusterId],
    resource: ['node'],
    resourceScope: 'global'
  }, {
    scope: 'cluster',
    id: 'cluster-storage',
    localizedLabel: 'nav.cluster.storage.tab',
    ctx: [_navigationTree.getClusterId],
    resource: ['clusterroletemplatebinding'],
    resourceScope: 'global',
    submenu: [{
      scope: 'cluster',
      id: 'cluster-storage-volumes',
      localizedLabel: 'nav.cluster.storage.volumes',
      route: 'authenticated.cluster.storage.persistent-volumes.index',
      ctx: [_navigationTree.getClusterId],
      resource: ['project'],
      resourceScope: 'global'
    }, {
      scope: 'cluster',
      id: 'cluster-storage-classes',
      localizedLabel: 'nav.cluster.storage.classes',
      route: 'authenticated.cluster.storage.classes.index',
      ctx: [_navigationTree.getClusterId],
      resource: ['project'],
      resourceScope: 'global'
    }]
  }, {
    scope: 'cluster',
    id: 'cluster-projects',
    localizedLabel: 'nav.cluster.projects',
    route: 'authenticated.cluster.projects.index',
    ctx: [_navigationTree.getClusterId],
    resource: ['project'],
    resourceScope: 'global'
  }, {
    scope: 'cluster',
    id: 'cluster-security-roles',
    localizedLabel: 'nav.cluster.members',
    route: 'authenticated.cluster.security.members.index',
    resource: ['clusterroletemplatebinding'],
    resourceScope: 'global',
    ctx: [_navigationTree.getClusterId]
  }, {
    scope: 'cluster',
    id: 'cluster-tools',
    localizedLabel: 'nav.tools.tab',
    ctx: [_navigationTree.getClusterId],
    resource: [],
    resourceScope: 'global',
    submenu: [{
      id: 'cluster-tools-alert',
      localizedLabel: 'nav.tools.alerts',
      route: 'authenticated.cluster.alert',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getClusterId]
    }, {
      id: 'cluster-tools-backups',
      localizedLabel: 'nav.tools.backups',
      route: 'authenticated.cluster.backups',
      resourceScope: 'global',
      resource: ['etcdbackup'],
      ctx: [_navigationTree.getClusterId],
      condition: function condition() {
        return Ember.get(this, 'cluster.rancherKubernetesEngineConfig');
      }
    }, {
      scope: 'cluster',
      id: 'cluster-catalogs',
      localizedLabel: 'nav.admin.catalogs',
      route: 'authenticated.cluster.cluster-catalogs',
      ctx: [_navigationTree.getClusterId],
      resource: ['catalog', 'cluster-catalog'],
      resourceScope: 'global'
    }, {
      id: 'cluster-tools-notifiers',
      localizedLabel: 'nav.tools.notifiers',
      route: 'authenticated.cluster.notifier',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getClusterId]
    }, {
      id: 'cluster-tools-logging',
      localizedLabel: 'nav.tools.logging',
      route: 'authenticated.cluster.logging',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getClusterId]
    }, {
      id: 'cluster-tools-monitoring',
      localizedLabel: 'nav.tools.monitoring',
      route: 'authenticated.cluster.monitoring.cluster-setting',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getClusterId]
    }, {
      id: 'cluster-tools-istio',
      localizedLabel: 'nav.tools.istio',
      route: 'authenticated.cluster.istio.cluster-setting',
      resourceScope: 'global',
      resource: [],
      ctx: [_navigationTree.getClusterId]
    }]
  }, // Global
  {
    scope: 'global',
    id: 'global-clusters',
    localizedLabel: 'nav.admin.clusters.tab',
    route: 'global-admin.clusters',
    resource: ['cluster'],
    resourceScope: 'global'
  }, {
    scope: 'global',
    id: 'multi-cluster-apps',
    localizedLabel: 'nav.admin.multiClusterApps',
    route: 'global-admin.multi-cluster-apps',
    resource: ['multiclusterapp'],
    resourceScope: 'global'
  }, {
    scope: 'global',
    id: 'global-accounts',
    localizedLabel: 'nav.admin.accounts',
    route: 'global-admin.accounts',
    resource: ['user'],
    resourceScope: 'global'
  }, {
    scope: 'global',
    id: 'global-settings',
    localizedLabel: 'nav.settings.tab',
    route: 'global-admin.settings.advanced',
    resourceScope: 'global'
  }, {
    scope: 'global',
    id: 'global-security',
    localizedLabel: 'nav.admin.security.tab',
    submenu: [{
      id: 'global-security-roles',
      localizedLabel: 'nav.admin.security.roles',
      route: 'global-admin.security.roles.index',
      resource: ['roletemplate'],
      resourceScope: 'global'
    }, {
      id: 'global-security-roles',
      localizedLabel: 'nav.admin.security.podSecurityPolicies',
      route: 'global-admin.security.policies',
      resource: ['podsecuritypolicytemplate'],
      resourceScope: 'global'
    }, {
      id: 'global-security-authentication',
      localizedLabel: 'nav.admin.security.authentication',
      route: 'global-admin.security.authentication',
      condition: function condition() {
        var authConfigs = this.get('globalStore').all('authConfig');
        return authConfigs.get('length') > 0;
      }
    }]
  }, {
    scope: 'global',
    id: 'global-tools',
    localizedLabel: 'nav.tools.tab',
    submenu: [{
      scope: 'global',
      id: 'global-catalogs',
      localizedLabel: 'nav.admin.catalogs',
      route: 'global-admin.catalog',
      resource: ['catalog'],
      resourceScope: 'global'
    }, {
      scope: 'global',
      id: 'nodes-node-drivers',
      localizedLabel: 'nav.admin.drivers',
      route: 'nodes.custom-drivers',
      resource: ['nodedriver', 'kontainerdriver'],
      resourceScope: 'global'
    }, {
      id: 'global-dns-entries',
      localizedLabel: 'nav.admin.globalDnsEntries',
      route: 'global-admin.global-dns.entries',
      resource: ['globaldns'],
      resourceScope: 'global'
    }, {
      id: 'global-dns-providers',
      localizedLabel: 'nav.admin.globalDnsProviders',
      route: 'global-admin.global-dns.providers',
      resource: ['globaldnsprovider'],
      resourceScope: 'global'
    }, // {
    //   id:             'global-registry',
    //   localizedLabel: 'nav.admin.globalRegistry',
    //   route:          'global-admin.global-registry',
    //   // There is no schema for global registry. But we can use global dns to check if it is a HA env.
    //   resource:       ['globaldns'],
    //   resourceScope:  'global',
    // },
    {
      id: 'rke-template',
      localizedLabel: 'nav.admin.clusters.rkeTemplate',
      route: 'global-admin.cluster-templates',
      resource: ['clustertemplate'],
      resourceScope: 'global'
    }]
  }];

  function initialize()
  /* appInstance*/
  {
    (0, _navigationTree.bulkAdd)(rootNav);
  }

  var _default = {
    name: 'nav',
    initialize: initialize,
    after: 'store'
  };
  _exports.default = _default;
});