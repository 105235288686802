define("ui/catalog-tab/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1j5jKO3g",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"new-catalog\",null,[[\"cancel\",\"catalogApp\",\"catalogTemplate\",\"namespaceResource\",\"namespaces\",\"parentRoute\",\"selectedTemplateUrl\",\"templateKind\",\"templateResource\",\"upgrade\",\"versionLinks\",\"versionsArray\",\"istio\"],[[27,\"action\",[[22,0,[]],\"cancel\"],null],[23,[\"model\",\"catalogApp\"]],[23,[\"model\",\"catalogTemplate\"]],[23,[\"model\",\"namespace\"]],[23,[\"model\",\"namespaces\"]],[23,[\"parentRoute\"]],[23,[\"model\",\"catalogTemplateUrl\"]],[23,[\"model\",\"tplKind\"]],[23,[\"model\",\"tpl\"]],[23,[\"model\",\"upgradeTemplate\"]],[23,[\"model\",\"versionLinks\"]],[23,[\"model\",\"versionsArray\"]],[23,[\"istio\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/catalog-tab/launch/template.hbs"
    }
  });

  _exports.default = _default;
});