define("ui/components/progress-bar-multi/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e2498YqG",
    "block": "{\"symbols\":[\"obj\"],\"statements\":[[4,\"tooltip-element\",null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"tooltipFor\",\"inlineBlock\",\"classNames\"],[\"tooltip-basic\",[23,[\"tooltipContent\"]],[23,[\"tooltipTemplate\"]],\"tooltip-base\",\"progress-bar\",true,\"full-width\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"progress\"],[9],[4,\"each\",[[23,[\"pieces\"]]],null,{\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"progress-bar \",[22,1,[\"color\"]]]]],[12,\"style\",[22,1,[\"css\"]]],[9],[10]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/progress-bar-multi/template.hbs"
    }
  });

  _exports.default = _default;
});