define("ui/components/namespace-resource-quota/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXicryI9",
    "block": "{\"symbols\":[\"quota\"],\"statements\":[[4,\"if\",[[23,[\"quotaArray\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\"],[11,\"class\",\"table fixed\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"formResourceQuota.table.type.label\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"formResourceQuota.table.resources.label\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"formResourceQuota.table.value.label\"],null],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"quotaArray\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"namespace-quota-row\",null,[[\"quota\",\"editing\"],[[22,1,[]],[23,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"editing\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"formResourceQuota.table.noData\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/namespace-resource-quota/template.hbs"
    }
  });

  _exports.default = _default;
});