define("ui/authenticated/project/config-maps/detail/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dh7DK/tH",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-config-map\",null,[[\"originalModel\",\"model\",\"mode\",\"done\",\"cancel\"],[[23,[\"originalModel\"]],[23,[\"model\"]],\"edit\",[27,\"action\",[[22,0,[]],\"back\"],null],[27,\"action\",[[22,0,[]],\"back\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/config-maps/detail/edit/template.hbs"
    }
  });

  _exports.default = _default;
});