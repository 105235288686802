define("ui/components/modal-new-pvc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K7DQFKVC",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-persistent-volume-claim\",null,[[\"model\",\"namespace\",\"selectNamespace\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\"],[[23,[\"model\"]],[23,[\"namespace\"]],false,[27,\"if\",[[23,[\"model\",\"id\"]],\"edit\",\"new\"],null],false,[27,\"action\",[[22,0,[]],\"doSave\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-new-pvc/template.hbs"
    }
  });

  _exports.default = _default;
});