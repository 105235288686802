define("ui/authenticated/apikeys/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    scope: Ember.inject.service(),
    growl: Ember.inject.service(),
    endpointService: Ember.inject.service('endpoint'),
    modalService: Ember.inject.service('modal'),
    bulkActionHandler: Ember.inject.service(),
    application: Ember.inject.controller(),
    expire: 'never',
    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['sortState', 'name', 'id'],
      translationKey: 'apiPage.table.state',
      width: 80
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'apiPage.table.name'
    }, {
      name: 'description',
      sort: ['description', 'name', 'id'],
      translationKey: 'apiPage.table.description'
    }, {
      name: 'scope',
      sort: ['scope', 'name', 'id'],
      translationKey: 'apiPage.table.scope'
    }, {
      name: 'expires',
      sort: ['expiresAt', 'name', 'id'],
      translationKey: 'apiPage.table.expires.label',
      width: 175
    }, {
      classNames: 'text-right pr-20',
      name: 'created',
      sort: ['created', 'name', 'id'],
      searchField: false,
      translationKey: 'apiPage.table.created',
      width: 175
    }],
    project: Ember.computed.alias('scope.currentProject'),
    actions: {
      newApikey: function newApikey() {
        var cred = this.get('globalStore').createRecord({
          type: 'token'
        });
        this.get('modalService').toggleModal('modal-edit-apikey', cred);
      }
    },
    rows: Ember.computed('model.tokens.[]', function () {
      return Ember.get(this, 'model.tokens').filter(function (token) {
        var labels = Ember.get(token, 'labels');
        var expired = Ember.get(token, 'expired');
        var current = Ember.get(token, 'current');
        return (!expired || !labels || !labels['ui-session']) && !current;
      });
    })
  });

  _exports.default = _default;
});