define("ui/components/pod-dots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SMzIi9ln",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"pagedContent\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"container-dot\",null,[[\"model\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-center text-muted pt-20 pb-20\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"searchText\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"containersPage.table.noMatch\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"t\",[\"containersPage.table.noData\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[27,\"gt\",[[23,[\"filtered\",\"length\"]],[23,[\"pagedContent\",\"length\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"page-numbers\",null,[[\"content\",\"class\",\"indexTo\",\"indexFrom\",\"totalCount\",\"textLabel\"],[[23,[\"pagedContent\"]],\"text-center mt-10 mb-10 no-select\",[23,[\"indexTo\"]],[23,[\"indexFrom\"]],[23,[\"filtered\",\"length\"]],\"pagination.container\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/pod-dots/template.hbs"
    }
  });

  _exports.default = _default;
});