define("ui/utils/parse-version", ["exports", "shared/utils/parse-version"], function (_exports, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "satisfies", {
    enumerable: true,
    get: function get() {
      return _parseVersion.satisfies;
    }
  });
  Object.defineProperty(_exports, "parse", {
    enumerable: true,
    get: function get() {
      return _parseVersion.parse;
    }
  });
  Object.defineProperty(_exports, "comparePart", {
    enumerable: true,
    get: function get() {
      return _parseVersion.comparePart;
    }
  });
  Object.defineProperty(_exports, "compare", {
    enumerable: true,
    get: function get() {
      return _parseVersion.compare;
    }
  });
  Object.defineProperty(_exports, "minorVersion", {
    enumerable: true,
    get: function get() {
      return _parseVersion.minorVersion;
    }
  });
});