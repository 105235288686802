define("ui/components/container-table/component", ["exports", "ui/components/container-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.headersWithStats = _exports.headersWithoutHost = _exports.headersWithNode = _exports.headersAll = void 0;
  var headersAll = [{
    name: 'state',
    sort: ['sortState', 'sortName', 'id'],
    searchField: 'displayState',
    translationKey: 'generic.state',
    width: 150
  }, {
    name: 'name',
    sort: ['sortName', 'id'],
    translationKey: 'generic.name'
  }, {
    name: 'image',
    sort: ['image', 'sortName', 'id'],
    searchField: 'image',
    translationKey: 'generic.image'
  }];
  _exports.headersAll = headersAll;
  var headersWithNode = headersAll.filter(function (x) {
    return x.name !== 'stats';
  });
  _exports.headersWithNode = headersWithNode;
  var headersWithoutHost = headersWithNode.filter(function (x) {
    return x.name !== 'hostName';
  });
  _exports.headersWithoutHost = headersWithoutHost;
  var headersWithStats = headersAll.filter(function (x) {
    return x.name !== 'hostName';
  });
  _exports.headersWithStats = headersWithStats;

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    stickyHeader: true,
    showNode: true,
    showStats: false,
    showInstanceState: true,
    pagingLabel: 'pagination.container',
    paging: true,
    sortBy: 'name',
    extraSearchFields: ['displayIp', 'primaryHost.displayName'],
    headers: function () {
      if (this.get('showStats')) {
        return headersWithStats;
      } else if (this.get('showNode')) {
        return headersWithNode;
      } else {
        return headersWithoutHost;
      }
    }.property(),
    filtered: function () {
      var out = this.get('body') || [];
      return out;
    }.property('body.@each.isSystem')
  });

  _exports.default = _default;
});