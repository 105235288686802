define("ui/components/container/form-command/component", ["exports", "ui/components/container/form-command/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    // Inputs
    instance: null,
    errors: null,
    editing: true,
    service: null,
    isSidekick: null,
    scaleMode: null,
    // ----------------------------------
    terminal: null,
    statusClass: null,
    status: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initTerminal();
      this.scaleModeDidChange();
    },
    terminalDidChange: Ember.observer('terminal.type', function () {
      var val = Ember.get(this, 'terminal.type');
      var stdin = val === 'interactive' || val === 'both';
      var tty = val === 'terminal' || val === 'both';
      Ember.set(this, 'instance.tty', tty);
      Ember.set(this, 'instance.stdin', stdin);
    }),
    scaleModeDidChange: Ember.observer('scaleMode', function () {
      var restartPolicy = Ember.get(this, 'service.restartPolicy');

      if (Ember.get(this, 'isJob')) {
        if (restartPolicy === 'Always') {
          Ember.set(this, 'service.restartPolicy', 'Never');
        }
      } else {
        Ember.set(this, 'service.restartPolicy', 'Always');
      }
    }),
    isJob: Ember.computed('scaleMode', function () {
      return Ember.get(this, 'scaleMode') === 'job' || Ember.get(this, 'scaleMode') === 'cronJob';
    }),
    // ----------------------------------
    // Terminal
    // 'both',
    initTerminal: function initTerminal() {
      var instance = Ember.get(this, 'instance');
      var tty = Ember.get(instance, 'tty');
      var stdin = Ember.get(instance, 'stdin');
      var out = {
        type: 'both',
        name: Ember.get(this, 'intl').t('formCommand.console.both', {
          htmlSafe: true
        })
      };

      if (tty !== undefined || stdin !== undefined) {
        if (tty && stdin) {
          out.type = 'both';
          out.name = Ember.get(this, 'intl').t('formCommand.console.both', {
            htmlSafe: true
          });
        } else if (tty) {
          out.type = 'terminal';
          out.name = Ember.get(this, 'intl').t('formCommand.console.terminal', {
            htmlSafe: true
          });
        } else if (stdin) {
          out.type = 'interactive';
          out.name = Ember.get(this, 'intl').t('formCommand.console.interactive', {
            htmlSafe: true
          });
        } else {
          out.type = 'none';
          out.name = Ember.get(this, 'intl').t('formCommand.console.none', {
            htmlSafe: true
          });
        }
      }

      Ember.set(this, 'terminal', out);
      this.terminalDidChange();
    }
  });

  _exports.default = _default;
});