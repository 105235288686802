define("ui/pod/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (window.ShellQuote) {
        return;
      } else {
        return emberAutoImportDynamic("shell-quote").then(function (module) {
          window.ShellQuote = module.default;
          return module.default;
        });
      }
    },
    model: function model(params) {
      var pod = Ember.get(this, 'store').find('pod', params.pod_id);

      if (!pod) {
        this.replaceWith('authenticated.project.index');
      }

      return pod;
    }
  });

  _exports.default = _default;
});