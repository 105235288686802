define("ui/ingresses/run/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['ingressId', 'upgrade'],
    ingressId: null,
    upgrade: null,
    actions: {
      done: function done() {
        this.send('goToPrevious', 'ingresses.index');
      },
      cancel: function cancel() {
        this.send('goToPrevious', 'ingresses.index');
      }
    }
  });

  _exports.default = _default;
});