define("ui/models/projectalertrule", ["exports", "@rancher/ember-api-store/models/resource", "ui/mixins/model-alert", "ui/utils/constants"], function (_exports, _resource, _modelAlert, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var projectAlertRule = _resource.default.extend(_modelAlert.default, {
    intl: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    canClone: true,
    canEdit: true,
    type: 'projectAlertRule',
    _targetType: 'pod',
    displayTargetType: Ember.computed('targetType', function () {
      return Ember.get(this, 'intl').t("alertPage.targetTypes.".concat(Ember.get(this, 'targetType')));
    }),
    podName: Ember.computed('podRule.podId', function () {
      var id = Ember.get(this, 'podRule.podId');
      var pod = Ember.get(this, 'projectStore').all('pod').filterBy('id', id).get('firstObject');

      if (!pod) {
        return null;
      }

      return Ember.get(pod, 'displayName');
    }),
    workloadName: Ember.computed('workloadRule.workloadId', function () {
      var id = Ember.get(this, 'workloadRule.workloadId');
      var workload = Ember.get(this, 'projectStore').all('workload').filterBy('id', id).get('firstObject');

      if (!workload) {
        return null;
      }

      return Ember.get(workload, 'displayName');
    }),
    displayCondition: Ember.computed('targetType', 'podRule.{condition,restartTimes,restartIntervalSeconds}', 'workloadRule.availablePercentage', function () {
      var t = Ember.get(this, 'targetType');
      var intl = Ember.get(this, 'intl');
      var out = intl.t('alertPage.na');
      var times = Ember.get(this, 'podRule.restartTimes');
      var interval = Ember.get(this, 'podRule.restartIntervalSeconds');
      var c = Ember.get(this, 'podRule.condition');
      var percent = Ember.get(this, 'workloadRule.availablePercentage');
      var metricRule = Ember.get(this, 'metricRule');

      switch (t) {
        case 'pod':
          switch (c) {
            case 'restarts':
              out = intl.t('alertPage.index.table.displayCondition.restarted', {
                times: times,
                interval: interval / 60
              });
              break;

            case 'notscheduled':
              out = intl.t('alertPage.index.table.displayCondition.notScheduled');
              break;

            case 'notrunning':
              out = intl.t('alertPage.index.table.displayCondition.notRunning');
              break;
          }

          break;

        case 'workload':
        case 'workloadSelector':
          out = intl.t('alertPage.index.table.displayCondition.available', {
            percent: percent
          });
          break;

        case 'metric':
          out = metricRule.comparison === _constants.default.ALERTING_COMPARISON.HAS_VALUE ? intl.t("alertPage.comparison.".concat(metricRule.comparison)) : "".concat(intl.t("alertPage.comparison.".concat(metricRule.comparison)), " ").concat(metricRule.thresholdValue);
          break;
      }

      return out;
    }),
    targetType: Ember.computed('podRule.podId', 'workloadRule.{workloadId,selector}', 'metricRule.expression', function () {
      if (Ember.get(this, 'podRule.podId')) {
        return 'pod';
      }

      if (Ember.get(this, 'workloadRule.workloadId')) {
        return 'workload';
      }

      if (Ember.get(this, 'workloadRule.selector')) {
        return 'workloadSelector';
      }

      if (Ember.get(this, 'metricRule.expression')) {
        return 'metric';
      }
    }),
    actions: {
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('authenticated.project.alert.new-rule', Ember.get(this, 'groupId'), {
          queryParams: {
            id: Ember.get(this, 'id')
          }
        });
      },
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.alert.edit-rule', Ember.get(this, 'groupId'), Ember.get(this, 'id'));
      }
    }
  });

  var _default = projectAlertRule;
  _exports.default = _default;
});