define("ui/components/container-default-limit/component", ["exports", "shared/utils/util", "shared/utils/parse-unit", "ui/components/container-default-limit/template"], function (_exports, _util, _parseUnit, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    limit: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        requestsCpu: (0, _util.convertToMillis)(Ember.get(this, 'limit.requestsCpu')),
        limitsCpu: (0, _util.convertToMillis)(Ember.get(this, 'limit.limitsCpu'))
      });

      if (Ember.get(this, 'limit.requestsMemory')) {
        Ember.set(this, 'requestsMemory', (0, _parseUnit.parseSi)(Ember.get(this, 'limit.requestsMemory'), 1024) / 1048576);
      }

      if (Ember.get(this, 'limit.limitsMemory')) {
        Ember.set(this, 'limitsMemory', (0, _parseUnit.parseSi)(Ember.get(this, 'limit.limitsMemory'), 1024) / 1048576);
      }
    },
    limitChanged: Ember.observer('requestsCpu', 'limitsCpu', 'requestsMemory', 'limitsMemory', function () {
      var requestsCpu = Ember.get(this, 'requestsCpu');
      var limitsCpu = Ember.get(this, 'limitsCpu');
      var requestsMemory = Ember.get(this, 'requestsMemory');
      var limitsMemory = Ember.get(this, 'limitsMemory');
      var out = {};

      if (requestsCpu) {
        Ember.set(out, 'requestsCpu', "".concat(requestsCpu, "m"));
      }

      if (limitsCpu) {
        Ember.set(out, 'limitsCpu', "".concat(limitsCpu, "m"));
      }

      if (requestsMemory) {
        Ember.set(out, 'requestsMemory', "".concat(requestsMemory, "Mi"));
      }

      if (limitsMemory) {
        Ember.set(out, 'limitsMemory', "".concat(limitsMemory, "Mi"));
      }

      if (this.changed) {
        this.changed(out);
      }
    })
  });

  _exports.default = _default;
});