define("ui/authenticated/project/project-catalogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ULdkxMjO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary btn-sm\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"catalogSettings.more.addActionLabel\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"add\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"catalogSettings.header\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"istio\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12\"],[9],[0,\"\\n      \"],[1,[27,\"banner-message\",null,[[\"icon\",\"color\",\"message\"],[\"icon-info\",\"bg-info mb-0 mt-10\",[27,\"t\",[\"globalDnsPage.entriesPage.istioHelp\"],[[\"htmlSafe\"],[true]]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"custom-catalog\",null,[[\"catalogs\",\"mode\"],[[23,[\"filtered\"]],\"project\"]]],false],[0,\"\\n\\n\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions mt-20\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent\"],[9],[1,[27,\"t\",[\"generic.back\"],null],false],[3,\"action\",[[22,0,[]],\"goBack\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/project-catalogs/template.hbs"
    }
  });

  _exports.default = _default;
});