define("ui/components/project-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aOKWpFJr",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"p\",\"dt\"],\"statements\":[[4,\"sortable-table\",null,[[\"tableClassNames\",\"paging\",\"pagingLabel\",\"headers\",\"descending\",\"body\",\"sortBy\"],[\"bordered mt-30\",true,\"pagination.project\",[23,[\"headers\"]],[23,[\"descending\"]],[23,[\"model\"]],[23,[\"sortBy\"]]]],{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"project-row\",null,[[\"model\",\"dt\"],[[22,3,[]],[22,4,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"nomatch\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[7,\"td\"],[12,\"colspan\",[28,[[22,1,[\"fullColspan\"]]]]],[11,\"class\",\"text-center text-muted lacsso pt-20 pb-20\"],[9],[1,[27,\"t\",[\"projectsPage.noMatch\"],null],false],[10],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[7,\"td\"],[12,\"colspan\",[28,[[22,1,[\"fullColspan\"]]]]],[11,\"class\",\"text-center text-muted lacsso pt-20 pb-20\"],[9],[1,[27,\"t\",[\"projectsPage.noData\"],null],false],[10],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2,3,4]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/project-table/template.hbs"
    }
  });

  _exports.default = _default;
});