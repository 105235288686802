define("ui/authenticated/project/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "llvUTc/D",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"project\",\"isSystemProject\"]]],null,{\"statements\":[[4,\"banner-message\",null,[[\"color\"],[\"bg-warning m-0\"]],{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"clusterDashboard.systemProject\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/template.hbs"
    }
  });

  _exports.default = _default;
});