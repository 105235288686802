define("ui/containers/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    model: function model() {
      var store = this.get('store');
      var globalStore = this.get('globalStore');
      return Ember.RSVP.hash({
        workloads: store.findAll('workload'),
        pods: store.findAll('pod'),
        nodes: globalStore.findAll('node')
      });
    },
    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, "session.".concat(_constants.default.SESSION.CONTAINER_ROUTE), 'containers');
      Ember.set(this, "session.".concat(_constants.default.SESSION.PROJECT_ROUTE), undefined);
    })
  });

  _exports.default = _default;
});