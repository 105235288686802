define("ui/components/identity-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z0W4mj0l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[23,[\"avatarSrc\"]]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"url\"]],[23,[\"link\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"href\",[28,[[21,\"url\"]]]],[11,\"target\",\"_blank\"],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[28,[[21,\"avatarSrc\"]]]],[12,\"width\",[28,[[21,\"size\"]]]],[12,\"height\",[28,[[21,\"size\"]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[28,[[21,\"avatarSrc\"]]]],[12,\"width\",[28,[[21,\"size\"]]]],[12,\"height\",[28,[[21,\"size\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"gh-placeholder\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/identity-avatar/template.hbs"
    }
  });

  _exports.default = _default;
});