define("ui/models/scalinggroup", ["exports", "ui/models/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ScalingGroup = _service.default.extend({
    type: 'scalingGroup',
    displayEnvironmentVars: Ember.computed('launchConfig.environment', function () {
      var envs = [];
      var environment = this.get('launchConfig.environment') || {};
      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });
      return envs;
    })
  });

  var _default = ScalingGroup;
  _exports.default = _default;
});