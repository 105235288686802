define("ui/models/probe", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({});

  _exports.default = _default;
});