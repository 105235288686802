define("ui/components/modal-wechat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t4AFpVZf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"settings\",\"isRancher\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"logo-text\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"parachute\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"align\",\"center\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[1,[27,\"t\",[\"modalWechat.title\"],null],false],[10],[0,\"\\n  \"],[7,\"img\"],[11,\"height\",\"128\"],[11,\"width\",\"128\"],[12,\"src\",[28,[[23,[\"app\",\"baseAssets\"]],\"assets/images/wechat-qr-code.jpg\"]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n\"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[1,[27,\"t\",[\"generic.closeModal\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-wechat/template.hbs"
    }
  });

  _exports.default = _default;
});