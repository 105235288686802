define("ui/components/registry-row/component", ["exports", "ui/components/registry-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    tagName: 'TR',
    classNames: 'main-row'
  });

  _exports.default = _default;
});