define("ui/authenticated/cluster/security/members/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/N+x82j2",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"new-edit-role\",null,[[\"model\",\"editing\",\"roleType\"],[[23,[\"model\"]],true,\"cluster\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/security/members/edit/template.hbs"
    }
  });

  _exports.default = _default;
});