define("ui/components/node-selector-row/component", ["exports", "ui/components/node-selector-row/template", "ui/utils/constants"], function (_exports, _template, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'TR',
    rule: null,
    editing: true,
    actions: {
      removeRule: function removeRule() {
        this.remove(this.rule);
      }
    },
    isJustLabel: Ember.computed('rule.operator', function () {
      return ['Exists', 'DoesNotExist'].includes(this.get('rule.operator'));
    }),
    isMultiple: Ember.computed('rule.operator', function () {
      return ['In', 'NotIn'].includes(this.get('rule.operator'));
    }),
    operatorChoices: _constants.default.SCHED_NODE_SELECTOR_OPERATOR,
    remove: function remove() {
      throw new Error('removeRule action is required!');
    }
  });

  _exports.default = _default;
});