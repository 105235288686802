define("ui/components/settings/table-rows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ymhf+7l3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"tableCountSelect.header\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"box\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[1,[27,\"new-select\",null,[[\"classNames\",\"optionValuePath\",\"optionLabelPath\",\"content\",\"value\"],[\"form-control\",\"value\",\"value\",[23,[\"tableCounts\"]],[23,[\"selectedCount\"]]]]],false],[0,\"\\n    \"],[1,[27,\"save-cancel\",null,[[\"save\",\"createLabel\",\"cancelDisabled\",\"forFooter\"],[[27,\"action\",[[22,0,[]],\"save\"],null],\"generic.done\",true,false]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/table-rows/template.hbs"
    }
  });

  _exports.default = _default;
});