define("ui/authenticated/project/hpa/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    sortBy: 'name',
    headers: [{
      name: 'state',
      sort: ['sortState', 'displayName'],
      searchField: 'displayState',
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['displayName', 'id'],
      searchField: 'displayName',
      translationKey: 'generic.name'
    }, {
      name: 'workloadId',
      searchField: 'workload.displayName',
      translationKey: 'hpaPage.table.target.label',
      width: 200
    }, {
      name: 'currentReplicas',
      sort: ['currentReplicas', 'displayName', 'id'],
      searchField: 'currentReplicas',
      translationKey: 'hpaPage.table.replicas.current',
      width: 200
    }, {
      name: 'lastScaleTime',
      sort: ['lastScaleTime', 'id'],
      classNames: 'text-right pr-20',
      searchField: 'lastScaleTime',
      translationKey: 'hpaPage.table.lastScaleTime.label',
      width: 200
    }],
    rows: Ember.computed.alias('model.data')
  });

  _exports.default = _default;
});