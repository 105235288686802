define("ui/authenticated/project/console/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8DHR5FfS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"shell-popup\"],[9],[0,\"\\n  \"],[1,[27,\"container-shell\",null,[[\"instance\",\"command\",\"windows\",\"containerName\",\"showProtip\",\"cols\",\"rows\",\"dismiss\",\"disconncted\"],[[23,[\"model\"]],[23,[\"command\"]],[23,[\"windows\"]],[23,[\"containerName\"]],false,80,24,[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/console/template.hbs"
    }
  });

  _exports.default = _default;
});