define("ui/volumes/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x7B/3M5M",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-persistent-volume-claim\",null,[[\"model\",\"persistentVolumes\",\"storageClasses\",\"namespace\",\"mode\"],[[23,[\"model\",\"pvc\"]],[23,[\"model\",\"persistentVolumes\"]],[23,[\"model\",\"storageClasses\"]],[23,[\"model\",\"pvc\",\"namespace\"]],\"view\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/volumes/detail/template.hbs"
    }
  });

  _exports.default = _default;
});