define("ui/mixins/verify-auth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import C from 'shared/utils/constants';
  var _default = Ember.Mixin.create({
    access: Ember.inject.service(),
    cookies: Ember.inject.service(),
    modal: Ember.inject.service(),
    prefs: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    queryParams: {
      config: {
        refreshModel: false
      },
      code: {
        refreshModel: false
      },
      forward: {
        refreshModel: false
      },
      state: {
        refreshModel: false
      },
      authProvider: {
        refreshModel: false
      },
      error_description: {
        refreshModel: false
      },
      oauth_token: {
        refreshModel: false
      },
      oauth_verifier: {
        refreshModel: false
      },
      login: {
        refreshModel: false
      },
      errorCode: {
        refreshModel: false
      },
      errorMsg: {
        refreshModel: false
      }
    }
  });

  _exports.default = _default;
});