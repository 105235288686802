define("ui/verify-auth/route", ["exports", "shared/utils/constants", "shared/utils/util", "ui/mixins/verify-auth"], function (_exports, _constants, _util, _verifyAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var samlProviders = ['ping', 'adfs', 'keycloak', 'okta'];
  var allowedForwards = ['localhost'];

  var _default = Ember.Route.extend(_verifyAuth.default, {
    oauth: Ember.inject.service(),
    intl: Ember.inject.service(),
    language: Ember.inject.service('user-language'),
    beforeModel: function beforeModel() {
      if (!this.intl.locale) {
        return Ember.get(this, 'language').initUnauthed();
      }
    },
    model: function model(params
    /* , transition */
    ) {
      var _this = this;

      var oauth = Ember.get(this, 'oauth');
      var code = Ember.get(params, 'code');
      var forward = Ember.get(params, 'forward'); // Allow another redirect if the hostname is in the whitelist above.
      // This allows things like sharing github auth between rancher at localhost:8000
      // and rio dev at localhost:8004

      if (forward) {
        var parsed = (0, _util.parseUrl)(forward);

        if (allowedForwards.includes(parsed.hostname.toLowerCase())) {
          if (Ember.get(params, 'login')) {
            window.location.href = (0, _util.addQueryParams)(forward, {
              forwarded: 'true',
              code: code
            });
          } else {
            oauth.login(forward);
          }
        } else {
          return Ember.RSVP.reject(new Error('Invalid forward url'));
        }

        return;
      }

      if (window.opener && !Ember.get(params, 'login') && !Ember.get(params, 'errorCode')) {
        var openersOauth = window.opener.ls('oauth');
        var openerStore = window.opener.ls('globalStore');
        var qp = Ember.get(params, 'config') || Ember.get(params, 'authProvider');
        var type = "".concat(qp, "Config");
        var config = openerStore.getById(type, qp);
        var stateMsg = 'Authorization state did not match, please try again.';
        var isGithub = Ember.get(params, 'config') === 'github';
        var isGoogle = Ember.get(params, 'config') === 'googleoauth';

        if (isGithub || isGoogle) {
          return oauth.testConfig(config).then(function (resp) {
            oauth.authorize(resp, openersOauth.get('state'));
          }).catch(function (err) {
            reply({
              err: err
            });
          });
        } else if (samlProviders.includes(Ember.get(params, 'config'))) {
          if (window.opener.window.onAuthTest) {
            reply(null, config);
          } else {
            reply({
              err: 'failure'
            });
          }
        }

        if (Ember.get(params, 'code')) {
          if (openersOauth.stateMatches(Ember.get(params, 'state'))) {
            reply(params.error_description, params.code);
          } else {
            reply(stateMsg);
          }
        }

        if (Ember.get(params, 'oauth_token') && Ember.get(params, 'oauth_verifier')) {
          reply(null, {
            oauthToken: Ember.get(params, 'oauth_token'),
            oauthVerifier: Ember.get(params, 'oauth_verifier')
          });
        }
      }

      if (code && Ember.get(params, 'state').includes('login')) {
        var providerType = Ember.get(params, 'state').includes('github') ? 'github' : 'googleoauth';

        if (oauth.stateMatches(Ember.get(params, 'state'))) {
          var currentProvider = Ember.get(this, 'access.providers').findBy('id', providerType);
          return currentProvider.doAction('login', {
            code: code,
            responseType: 'cookie',
            description: _constants.default.SESSION.DESCRIPTION,
            ttl: _constants.default.SESSION.TTL
          }).then(function () {
            return Ember.get(_this, 'access').detect().then(function () {
              return _this.transitionTo('authenticated');
            });
          });
        }
      }

      if (Ember.get(params, 'errorCode')) {
        var errorMessageKey = Ember.get(params, 'errorMsg') || null;
        var errorMessageTranslation = this.intl.t('loginPage.error.unknown');
        var locale = this.intl.locale || ['en-us'];

        if (errorMessageKey && this.intl.exists("loginPage.error.".concat(errorMessageKey), locale)) {
          errorMessageTranslation = this.intl.t("loginPage.error.".concat(errorMessageKey));
        }

        reply(errorMessageTranslation, Ember.get(params, 'errorCode'));
      }

      function reply(err, code) {
        try {
          window.opener.window.onAuthTest(err, code);
          setTimeout(function () {
            window.close();
          }, 250);
          return new Ember.RSVP.promise();
        } catch (e) {
          window.close();
        }
      }
    }
  });

  _exports.default = _default;
});