define("ui/components/modal-kubectl/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-kubectl/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    model: null,
    error: null,
    url: Ember.computed.alias('scope.currentCluster.links.shell'),
    init: function init() {
      this._super.apply(this, arguments);

      this.shortcuts.disable();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.shortcuts.enable();
    }
  });

  _exports.default = _default;
});