define("ui/authenticated/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    scope: Ember.inject.service(),
    redirect: function redirect() {
      // @TODO-2.0 go to appropriate place based on permissions
      this.replaceWith('global-admin.clusters');
    }
  });

  _exports.default = _default;
});