define("ui/components/settings/table-rows/component", ["exports", "ui/utils/constants", "ui/components/settings/table-rows/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TABLE_COUNTS = [{
    value: '10'
  }, {
    value: '25'
  }, {
    value: '50'
  }, {
    value: '100'
  }, {
    value: '250'
  }, {
    value: '500'
  }, {
    value: '1000'
  }];

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    tableCounts: TABLE_COUNTS,
    selectedCount: null,
    perPage: Ember.computed.alias('prefs.tablePerPage'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('selectedCount', "".concat(this.get('perPage')));
    },
    actions: {
      save: function save(cb) {
        this.set("prefs.".concat(_constants.default.PREFS.TABLE_COUNT), parseInt(this.get('selectedCount'), 10));
        cb(true);

        if (this.done) {
          this.done();
        }
      }
    }
  });

  _exports.default = _default;
});