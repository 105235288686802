define("ui/components/container/form-custom-metrics/component", ["exports", "ui/components/container/form-custom-metrics/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HTTPS = 'HTTPS';
  var HTTP = 'HTTP';
  var OPTIONS = [{
    label: HTTP,
    value: HTTP
  }, {
    label: HTTPS,
    value: HTTPS
  }];

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    editing: false,
    protocolOptions: OPTIONS,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'metrics', Ember.get(this, 'workload.workloadMetrics') || []);
    },
    actions: {
      add: function add() {
        Ember.get(this, 'metrics').pushObject({
          path: '',
          port: '',
          schema: HTTP
        });
      },
      remove: function remove(obj) {
        Ember.get(this, 'metrics').removeObject(obj);
      }
    },
    metricsChanged: Ember.observer('metrics.@each.{port,path,schema}', function () {
      Ember.set(this, 'workload.workloadMetrics', Ember.get(this, 'metrics').filter(function (metric) {
        return Ember.get(metric, 'port');
      }));
    })
  });

  _exports.default = _default;
});