define("ui/components/modal-import/component", ["exports", "codemirror", "js-yaml", "shared/mixins/modal-base", "ui/components/modal-import/template", "shared/mixins/child-hook"], function (_exports, _codemirror, _jsYaml, _modalBase, _template, _childHook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, _childHook.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    scope: Ember.inject.service(),
    store: Ember.inject.service('store'),
    layout: _template.default,
    mode: 'project',
    namespace: null,
    yaml: '',
    errors: null,
    compose: null,
    classNames: ['modal-container', 'large-modal', 'fullscreen-modal', 'modal-shell', 'alert'],
    init: function init() {
      this._super.apply(this, arguments);

      window.jsyaml || (window.jsyaml = _jsYaml.default);
    },
    actions: {
      cancel: function cancel() {
        return this._super.apply(this, arguments);
      },
      close: function close() {
        return this._super.apply(this, arguments);
      },
      save: function save(cb) {
        var _this = this;

        var yaml = Ember.get(this, 'yaml');
        var lintError = [];

        _jsYaml.default.safeLoadAll(yaml, function (y) {
          lintError.pushObjects(_codemirror.default.lint.yaml(y));
        });

        if (lintError.length) {
          Ember.set(this, 'errors', [Ember.get(this, 'intl').t('yamlPage.errors')]);
          cb(false);
          return;
        }

        Ember.set(this, 'errors', null);
        var opts = {
          yaml: Ember.get(this, 'yaml')
        };

        switch (Ember.get(this, 'mode')) {
          case 'namespace':
            opts.namespace = Ember.get(this, 'namespace.name');
            break;

          case 'project':
            opts.project = Ember.get(this, 'projectId');
            opts.defaultNamespace = Ember.get(this, 'namespace.name');
            break;

          case 'cluster':
            break;
        }

        if (Ember.get(this, 'mode') === 'cluster') {
          this.send('actuallySave', opts, cb);
        } else {
          return this.applyHooks('_beforeSaveHooks').then(function () {
            _this.send('actuallySave', opts, cb);
          }).catch(function () {
            cb(false);
          });
        }
      },
      actuallySave: function actuallySave(opts, cb) {
        var _this2 = this;

        return Ember.get(this, 'scope.currentCluster').doAction('importYaml', opts).then(function () {
          cb();

          _this2.send('cancel');
        }).catch(function () {
          cb(false);
        });
      }
    },
    lintObserver: Ember.observer('yaml', function () {
      var yaml = Ember.get(this, 'yaml');
      var lintError = [];

      _jsYaml.default.safeLoadAll(yaml, function (y) {
        lintError.pushObjects(_codemirror.default.lint.yaml(y));
      });

      if (lintError.length) {
        Ember.set(this, 'errors', null);
      }
    })
  });

  _exports.default = _default;
});