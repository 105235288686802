define("ui/authenticated/cluster/storage/classes/detail/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w0+fXusm",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-storage-class\",null,[[\"mode\",\"model\",\"done\",\"cancel\"],[\"edit\",[23,[\"model\"]],[27,\"action\",[[22,0,[]],\"back\"],null],[27,\"action\",[[22,0,[]],\"back\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/classes/detail/edit/template.hbs"
    }
  });

  _exports.default = _default;
});