define("ui/authenticated/project/route", ["exports", "ui/mixins/preload", "ui/utils/constants"], function (_exports, _preload, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALID_ROUTES = ['apps-tab', 'catalog-tab.index', 'authenticated.project.security.members.index', 'authenticated.project.ns', 'authenticated.project.certificates', 'authenticated.project.secrets', 'authenticated.project.config-maps', 'authenticated.project.registries', 'authenticated.project.alert', 'authenticated.project.logging', 'authenticated.project.pipeline.settings', 'authenticated.project.monitoring.project-setting', 'authenticated.project.istio.project-istio.graph', 'authenticated.project.istio.project-istio.metrics', 'authenticated.project.istio.project-istio.rules', 'authenticated.project.istio.project-istio.destination-rules', 'authenticated.project.istio.project-istio.virtual-services', 'authenticated.project.istio.project-istio.gateways', 'authenticated.project.hpa', 'authenticated.project.pipeline.pipelines'];

  var _default = Ember.Route.extend(_preload.default, {
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    shortcuts: {
      'g': 'toggleGrouping'
    },
    model: function model(params, transition) {
      var _this = this;

      var isPopup = this.controllerFor('application').get('isPopup');
      return Ember.get(this, 'globalStore').find('project', params.project_id).then(function (project) {
        return Ember.get(_this, 'scope').startSwitchToProject(project, !isPopup).then(function () {
          return Ember.RSVP.all([_this.loadSchemas('clusterStore'), _this.loadSchemas('store')]).then(function () {
            var out = Ember.Object.create({
              project: project
            });

            if (isPopup) {
              return out;
            } else {
              return Ember.RSVP.all([_this.preload('namespace', 'clusterStore'), _this.preload('storageClass', 'clusterStore'), _this.preload('persistentVolume', 'clusterStore'), _this.preload('pod'), _this.preload('workload'), _this.preload('secret'), _this.preload('service'), _this.preload('configmap'), _this.preload('namespacedSecret'), _this.preload('persistentVolumeClaim')]).then(function () {
                return out;
              });
            }
          });
        });
      }).catch(function (err) {
        return _this.loadingError(err, transition);
      });
    },
    redirect: function redirect(model, transition) {
      var route = this.get("session.".concat(_constants.default.SESSION.PROJECT_ROUTE));

      if (Ember.get(transition, 'targetName') === 'authenticated.project.index' && VALID_ROUTES.includes(route)) {
        this.replaceWith(route);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      Ember.get(this, 'scope').finishSwitchToProject(Ember.get(model, 'project'));
    },
    actions: {
      toggleGrouping: function toggleGrouping() {
        var _this2 = this;

        var choices = ['none', 'node', 'workload', 'namespace'];
        var cur = this.get('controller.group');
        var neu = choices[(choices.indexOf(cur) + 1) % choices.length];
        Ember.run.next(function () {
          _this2.set('controller.group', neu);
        });
      },
      importYaml: function importYaml() {
        Ember.get(this, 'modalService').toggleModal('modal-import', {
          escToClose: true,
          mode: 'project',
          projectId: Ember.get(this, 'scope.currentProject.id')
        });
      }
    }
  });

  _exports.default = _default;
});