define("ui/components/container/form-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j6d7SSZR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"formImage.label\"],null],false],[1,[21,\"field-required\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[9],[0,\"\\n  \"],[1,[27,\"input-suggest\",null,[[\"value\",\"grouped\",\"placeholder\"],[[23,[\"userInput\"]],[23,[\"suggestions\"]],[27,\"t\",[\"formImage.container.placeholder\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-image/template.hbs"
    }
  });

  _exports.default = _default;
});