define("ui/instance-initializers/theme", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(instance) {
    var session = instance.lookup('service:session');
    var userTheme = instance.lookup('service:user-theme');

    var theme = session.get(_constants.default.PREFS.THEME) || _constants.default.THEME.DEFAULT;

    userTheme.setTheme(theme, false);
  }

  var _default = {
    name: 'theme',
    initialize: initialize
  };
  _exports.default = _default;
});