define("ui/components/input-random-port/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Xb5wYpi",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showEdit\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"input-integer\",null,[[\"class\",\"min\",\"max\",\"value\",\"placeholder\"],[\"form-control input-sm\",[23,[\"min\"]],[23,[\"max\"]],[23,[\"value\"]],[23,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"edit btn bg-transparent pl-0\"],[9],[1,[27,\"t\",[[23,[\"standardKey\"]]],null],false],[0,\" \"],[7,\"i\"],[11,\"class\",\"icon icon-edit\"],[9],[10],[3,\"action\",[[22,0,[]],\"showEdit\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/input-random-port/template.hbs"
    }
  });

  _exports.default = _default;
});