define("ui/components/modal-edit-node-pool/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mWPWi2Jx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"editNodePool.title\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"horizontal-form container-fluid\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row inline-form\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-12\"],[9],[0,\"\\n      \"],[1,[27,\"form-node-taints\",null,[[\"model\",\"nodeTemplateTaints\",\"expandOnInit\"],[[23,[\"model\"]],[23,[\"model\",\"nodeTemplate\",\"nodeTaints\"]],true]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[27,\"save-cancel\",null,[[\"editing\",\"save\",\"cancel\"],[true,[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-edit-node-pool/template.hbs"
    }
  });

  _exports.default = _default;
});