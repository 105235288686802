define("ui/components/modal-rollback-service/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rollback-service/template", "shared/utils/constants", "moment", "jsondiffpatch"], function (_exports, _modalBase, _template, _constants, _moment, _jsondiffpatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HIDDEN_FIELDS = ['created', 'creatorId', 'name', 'ownerReferences', 'removed', 'scale', 'state', 'uuid', 'workloadLabels', 'workloadAnnotations'];

  function sanitize(config, keys) {
    var result = {};
    keys.forEach(function (key) {
      if (config[key] !== undefined) {
        result[key] = config[key];
      }

      if (key === 'labels' && result.labels) {
        delete result.labels['pod-template-hash'];
      }

      if (key === 'selector' && result.selector && result.selector.matchLabels) {
        delete result.selector.matchLabels['pod-template-hash'];
      }
    });
    return result;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    keys: null,
    name: null,
    loading: true,
    revisions: null,
    revisionId: null,
    init: function init() {
      this._super.apply(this, arguments);

      var schema = Ember.get(this, 'store').getById('schema', 'workload');
      var resourceFields = Ember.get(schema, 'resourceFields');
      Ember.set(this, 'keys', Object.keys(resourceFields).filter(function (field) {
        return !field.endsWith('Status') && HIDDEN_FIELDS.indexOf(field) === -1;
      }));
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var model = Ember.get(this, 'modalService.modalOpts.originalModel').clone();
      Ember.set(this, 'model', model);
      Ember.get(this, 'store').rawRequest({
        url: Ember.get(model, 'links.revisions'),
        method: 'GET'
      }).then(function (revs) {
        Ember.set(_this, 'revisions', revs.body.data);
      }).catch(function (err) {
        _this.send('cancel');

        Ember.get(_this, 'growl').fromError(err);
      }).finally(function () {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      save: function save(cb) {
        var _this2 = this;

        var id = Ember.get(this, 'selected.id');
        Ember.get(this, 'model').doAction('rollback', {
          replicaSetId: id
        }).then(function () {
          _this2.send('cancel');
        }).finally(function () {
          cb();
        });
      }
    },
    choices: Ember.computed('revisions.[]', function () {
      var _this3 = this;

      return (Ember.get(this, 'revisions') || []).sortBy('createdTS').reverse().map(function (r) {
        var time = (0, _moment.default)(Ember.get(r, 'created'));
        var id = Ember.get(r, 'id');
        return {
          label: "".concat(id, ": ").concat(time.format('YYYY-MM-DD HH:mm:ss'), " (").concat(time.fromNow(), ")"),
          value: id,
          ts: Ember.get(r, 'createdTS'),
          data: r,
          disabled: r.workloadAnnotations[_constants.default.LABEL.DEPLOYMENT_REVISION] === Ember.get(_this3, 'model.workloadAnnotations')[_constants.default.LABEL.DEPLOYMENT_REVISION]
        };
      });
    }),
    current: Ember.computed('revisions.@each.workloadAnnotations', function () {
      var currentRevision = Ember.get(this, 'model.workloadAnnotations')[_constants.default.LABEL.DEPLOYMENT_REVISION];

      return (Ember.get(this, 'revisions') || []).find(function (r) {
        return Ember.get(r, 'workloadAnnotations')[_constants.default.LABEL.DEPLOYMENT_REVISION] === currentRevision;
      });
    }),
    selected: Ember.computed('revisionId', 'revisions.[]', function () {
      return (Ember.get(this, 'revisions') || []).findBy('id', Ember.get(this, 'revisionId'));
    }),
    diff: Ember.computed('current', 'selected', function () {
      if (Ember.get(this, 'current') && Ember.get(this, 'selected')) {
        var left = sanitize(Ember.get(this, 'current'), Ember.get(this, 'keys'));
        var right = sanitize(Ember.get(this, 'selected'), Ember.get(this, 'keys'));

        var delta = _jsondiffpatch.default.diff(left, right);

        _jsondiffpatch.default.formatters.html.hideUnchanged();

        return _jsondiffpatch.default.formatters.html.format(delta, left).htmlSafe();
      }
    })
  });

  _exports.default = _default;
});