define("ui/resource-actions/service", ["exports", "shared/resource-actions/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _service.default;
    }
  });
});