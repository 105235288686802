define("ui/authenticated/cluster/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    queryParams: ['provider', 'clusterTemplateRevision'],
    provider: null,
    clusterTemplateRevision: null,
    cluster: Ember.computed.alias('model.cluster'),
    actions: {
      close: function close() {
        this.transitionToRoute('authenticated.cluster');
      }
    }
  });

  _exports.default = _default;
});