define("ui/components/project-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gT30H0ZL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"valign\",\"middle\"],[11,\"class\",\"row-check\"],[11,\"style\",\"padding-top: 2px;\"],[9],[0,\"\\n  \"],[1,[27,\"check-box\",null,[[\"nodeId\"],[[23,[\"model\",\"id\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"state\"]]]]],[11,\"class\",\"state\"],[9],[0,\"\\n  \"],[1,[27,\"badge-state\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[\"projects.table.project.label\"],null],\":\"]]],[9],[0,\"\\n\"],[4,\"link-to\",[\"authenticated.project\",[23,[\"model\",\"id\"]]],[[\"class\"],[\"clip project-link\"]],{\"statements\":[[0,\"    \"],[1,[23,[\"model\",\"displayName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[\"projects.table.created.label\"],null],\":\"]]],[9],[0,\"\\n  \"],[1,[27,\"date-calendar\",[[23,[\"model\",\"created\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[27,\"t\",[\"generic.actions\"],null],\":\"]]],[11,\"class\",\"actions\"],[9],[0,\"\\n  \"],[1,[27,\"action-menu\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/project-row/template.hbs"
    }
  });

  _exports.default = _default;
});