define("ui/components/modal-telemetry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FYfj+H9I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"welcome-copy\"],[9],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n    \"],[7,\"h1\"],[11,\"class\",\"alert-header\"],[9],[1,[27,\"t\",[\"modalWelcome.header\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[10],[0,\"\\n\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col span-4\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"app\",\"baseAssets\"]],\"assets/images/welcome-scene.svg\"]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col span-8\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"telemetryOpt.subtext\"],[[\"appName\",\"htmlSafe\"],[[23,[\"settings\",\"appName\"]],true]]],false],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"col-inline checkbox pt-30 text-small\"],[9],[0,\"\\n      \"],[7,\"label\"],[9],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"optIn\"]]]]],false],[0,\" \"],[1,[27,\"t\",[\"telemetryOpt.label\"],null],false],[10],[0,\" \"],[7,\"a\"],[12,\"href\",[28,[[23,[\"settings\",\"docsBase\"]],\"/faq/telemetry\"]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener nofollow\"],[9],[1,[27,\"t\",[\"telemetryOpt.learnMore.label\"],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary\"],[9],[1,[27,\"t\",[\"modalWelcome.closeModal\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-telemetry/template.hbs"
    }
  });

  _exports.default = _default;
});