define("ui/helpers/parse-camelcase", ["exports", "shared/helpers/parse-camelcase"], function (_exports, _parseCamelcase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _parseCamelcase.default;
    }
  });
});