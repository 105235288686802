define("ui/components/new-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6tBvtSpz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"pt-15 pb-30\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[1,[27,\"t\",[\"newPassword.password\"],null],false],[1,[21,\"field-required\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inline-form\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"password\",[27,\"t\",[\"newPassword.placeholder\"],null],[23,[\"newPassword\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pt-15 pb-30\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[1,[27,\"t\",[\"newPassword.confirmPassword\"],null],false],[1,[21,\"field-required\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inline-form\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"placeholder\",\"value\"],[\"password\",[27,\"t\",[\"newPassword.placeholder\"],null],[23,[\"confirmPassword\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"passwordsMatch\"]],\"hide\",\"\"],null]]]],[9],[0,\"\\n    \"],[7,\"p\"],[9],[1,[27,\"t\",[\"newPassword.passwordNoMatch\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/new-password/template.hbs"
    }
  });

  _exports.default = _default;
});