define("ui/authenticated/cluster/storage/classes/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+ihJ529n",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"cru-storage-class\",null,[[\"mode\",\"model\",\"persistentVolumes\"],[\"view\",[23,[\"model\",\"storageclass\"]],[23,[\"model\",\"persistentVolumes\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/classes/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});