define("ui/components/project-resource-quota/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pLv4+Cza",
    "block": "{\"symbols\":[\"quota\"],\"statements\":[[4,\"if\",[[23,[\"quotaArray\",\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\"],[11,\"class\",\"table fixed\"],[9],[0,\"\\n    \"],[7,\"thead\"],[9],[0,\"\\n      \"],[7,\"tr\"],[9],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"formResourceQuota.table.type.label\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"formResourceQuota.table.projectLimit.label\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[9],[1,[27,\"t\",[\"formResourceQuota.table.namespaceLimit.label\"],null],false],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"width\",\"10\"],[9],[0,\" \"],[10],[0,\"\\n        \"],[7,\"th\"],[11,\"width\",\"40\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"quotaArray\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"project-quota-row\",null,[[\"quota\",\"currentQuota\",\"editing\",\"remove\"],[[22,1,[]],[23,[\"quotaArray\"]],[23,[\"editing\"]],[27,\"action\",[[22,0,[]],\"removeQuota\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"editing\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"formResourceQuota.table.noData\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row mt-10\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-link icon-btn\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"darken\"],[9],[7,\"i\"],[11,\"class\",\"icon icon-plus text-small\"],[9],[10],[10],[0,\"\\n      \"],[7,\"span\"],[9],[1,[27,\"t\",[\"formResourceQuota.addQuotaLabel\"],null],false],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"addQuota\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/project-resource-quota/template.hbs"
    }
  });

  _exports.default = _default;
});