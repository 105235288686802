define("ui/components/modal-kubectl/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ylFAKPOY",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"container-shell\",null,[[\"url\",\"showProtip\",\"displayName\",\"dismiss\",\"disconncted\"],[[23,[\"url\"]],false,[23,[\"scope\",\"currentCluster\",\"displayName\"]],[27,\"action\",[[22,0,[]],\"cancel\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"footer-actions\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn bg-primary\"],[9],[1,[27,\"t\",[\"generic.closeModal\"],null],false],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-kubectl/template.hbs"
    }
  });

  _exports.default = _default;
});