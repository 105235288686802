define("ui/components/form-env-var/component", ["exports", "ui/components/form-env-var/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    model: null,
    expandAll: null,
    expandFn: null,
    sortBy: 'key',
    descending: true,
    headers: [{
      name: 'key',
      sort: ['key'],
      translationKey: 'formEnvVar.labels.key',
      searchField: 'key',
      width: '350'
    }, {
      name: 'value',
      sort: ['value', 'key'],
      searchField: 'value',
      translationKey: 'formEnvVar.labels.value'
    }]
  });

  _exports.default = _default;
});