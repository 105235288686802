define("ui/models/clustercatalog", ["exports", "ui/models/catalog", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _catalog, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _catalog.default.extend({
    level: 'cluster',
    cluster: (0, _denormalize.reference)('clusterId')
  });

  _exports.default = _default;
});