define("ui/components/basic-dropdown/content-element", ["exports", "ember-basic-dropdown/components/basic-dropdown/content-element"], function (_exports, _contentElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CE = _contentElement.default.extend({
    click: function click() {
      this.closeDD();
      return true;
    },
    closeDD: function closeDD() {
      var dd = Ember.get(this, 'parentView.dropdown');

      if (Ember.get(dd, 'actions')) {
        Ember.run.next(function () {
          dd.actions.close();
        });
      }
    }
  });

  var _default = CE;
  _exports.default = _default;
});