define("ui/models/namespacedcertificate", ["exports", "ui/models/certificate", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _certificate, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _certificate.default.extend({
    clusterStore: Ember.inject.service(),
    namespace: (0, _denormalize.reference)('namespaceId', 'namespace', 'clusterStore')
  });

  _exports.default = _default;
});