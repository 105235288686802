define("ui/components/certificate-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R6LK6IHu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"row-check\"],[11,\"valign\",\"middle\"],[11,\"style\",\"padding-top: 2px;\"],[9],[0,\"\\n  \"],[1,[27,\"check-box\",null,[[\"nodeId\"],[[23,[\"model\",\"id\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"state\"]]]]],[11,\"class\",\"state\"],[9],[0,\"\\n  \"],[1,[27,\"badge-state\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"name\"]]]]],[11,\"class\",\"force-wrap\"],[9],[0,\"\\n  \"],[4,\"link-to-external\",[\"authenticated.project.certificates.detail\",[23,[\"model\",\"id\"]]],null,{\"statements\":[[1,[23,[\"model\",\"displayName\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"cn\"]]]]],[9],[0,\"\\n  \"],[1,[23,[\"model\",\"displayDomainName\"]],true],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"expires\"]]]]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"model\",\"expiresSoon\"]],\"text-bold text-danger\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"date-from-now\",[[23,[\"model\",\"expiresDate\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[12,\"data-title\",[28,[[23,[\"dt\",\"actions\"]]]]],[11,\"class\",\"actions\"],[9],[0,\"\\n  \"],[1,[27,\"action-menu\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/certificate-row/template.hbs"
    }
  });

  _exports.default = _default;
});