define("ui/authenticated/project/container-log/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        pod: Ember.get(this, 'store').find('pod', params.podId),
        containerName: params.containerName
      });
    }
  });

  _exports.default = _default;
});