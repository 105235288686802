define("ui/models/certificate", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    allWorkloads: Ember.inject.service(),
    state: 'active',
    workloads: Ember.computed('allWorkloads.list.@each.volumes', 'allWorkloads.list.@each.containers', function () {
      var _this = this;

      return (Ember.get(this, 'allWorkloads.list') || []).map(function (item) {
        return item.obj;
      }).filter(function (workload) {
        if (Ember.get(_this, 'namespaceId') && Ember.get(workload, 'namespaceId') !== Ember.get(_this, 'namespaceId')) {
          return false;
        }

        var volume = (Ember.get(workload, 'volumes') || []).find(function (volume) {
          return Ember.get(volume, 'secret.secretName') === Ember.get(_this, 'name');
        });
        var env = (Ember.get(workload, 'containers') || []).find(function (container) {
          return (Ember.get(container, 'environmentFrom') || []).find(function (env) {
            return Ember.get(env, 'source') === 'secret' && Ember.get(env, 'sourceName') === Ember.get(_this, 'name');
          });
        });
        return volume || env;
      });
    }),
    issuedDate: Ember.computed('issuedAt', function () {
      return new Date(Ember.get(this, 'issuedAt'));
    }),
    expiresDate: Ember.computed('expiresAt', function () {
      return new Date(Ember.get(this, 'expiresAt'));
    }),
    expiresSoon: Ember.computed('expiresDate', function () {
      var diff = Ember.get(this, 'expiresDate').getTime() - new Date().getTime();
      var days = diff / (86400 * 1000);
      return days <= 8;
    }),
    displayIssuer: Ember.computed('issuer', function () {
      return (Ember.get(this, 'issuer') || '').split(/,/)[0].replace(/^CN=/i, '');
    }),
    // All the SANs that aren't the CN
    displaySans: Ember.computed('cn', 'subjectAlternativeNames.[]', function () {
      var sans = Ember.get(this, 'subjectAlternativeNames') || '';
      var cn = Ember.get(this, 'cn') || '';

      if (!sans) {
        return [];
      }

      return sans.removeObject(cn).filter(function (san) {
        return "".concat(san).indexOf('@') === -1;
      });
    }),
    // The useful SANs; Removes "domain.com" when the cert is for "www.domain.com"
    countableSans: Ember.computed('displaySans.[]', 'cn', function () {
      var sans = Ember.get(this, 'displaySans').slice();

      if (Ember.get(this, 'cn')) {
        sans.pushObject(Ember.get(this, 'cn'));
      }

      var commonBases = sans.filter(function (name) {
        return name.indexOf('*.') === 0 || name.indexOf('www.') === 0;
      }).map(function (name) {
        return name.substr(name.indexOf('.'));
      });
      return Ember.get(this, 'displaySans').slice().removeObjects(commonBases);
    }),
    // "cn.com and 5 others" (for table view)
    displayDomainName: Ember.computed('cn', 'countableSans.length', function () {
      var intl = Ember.get(this, 'intl');
      var cn = Ember.get(this, 'cn');

      if (!cn) {
        return intl.t('generic.none');
      }

      var sans = Ember.get(this, 'countableSans.length');
      var wildcard = cn.substr(0, 1) === '*';
      var key;

      if (wildcard) {
        if (sans) {
          key = 'certificatesPage.domainNames.wildcardWithSan';
        } else {
          key = 'certificatesPage.domainNames.wildcardSingle';
        }
      } else if (sans) {
        key = 'certificatesPage.domainNames.withSan';
      } else {
        key = 'certificatesPage.domainNames.single';
      }

      return intl.t(key, {
        cn: cn,
        sans: sans
      });
    }),
    // "user-provided-name (cn-if-different-than-user-name.com + 5 others)"
    displayDetailedName: Ember.computed('displayName', 'cn', 'countableSans.length', function () {
      var name = Ember.get(this, 'displayName');
      var cn = Ember.get(this, 'cn');
      var sans = Ember.get(this, 'countableSans.length');
      var out = name;
      var more = '';

      if (cn) {
        if (cn !== name) {
          more += cn;
        }

        if (sans > 0) {
          more += " + ".concat(sans, " other").concat(sans === 1 ? '' : 's');
        }
      }

      if (more) {
        out += " (".concat(more, ")");
      }

      return out;
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.project.certificates.detail.edit', Ember.get(this, 'id'));
      }
    }
  });

  _exports.default = _default;
});