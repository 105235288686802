define("ui/templates/tooltip-container-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ymQWme+O",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"tooltip-content-inner tooltip-dot\"],[9],[0,\"\\n  \"],[1,[27,\"action-menu\",null,[[\"model\",\"showPrimary\",\"inTooltip\",\"class\"],[[23,[\"model\"]],false,true,\"pull-right tooltip-more-actions\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"display-name\"],[9],[7,\"a\"],[12,\"href\",[28,[[27,\"href-to\",[\"pod\",[23,[\"model\",\"id\"]]],null]]]],[9],[1,[21,\"displayName\"],false],[10],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"pull-right\"],[9],[1,[27,\"badge-state\",null,[[\"classNames\",\"model\"],[\"btn-xs\",[23,[\"model\"]]]]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"display-ip pull-left pr-5\"],[9],[1,[27,\"format-ip\",[[23,[\"model\",\"displayIp\"]]],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"node\",\"displayName\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"display-ip pull-left pr-5\"],[9],[0,\"\\n        \"],[1,[23,[\"model\",\"node\",\"displayName\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"showTransitioningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"text-small bg-body p-5 mt-5 force-wrap \",[23,[\"model\",\"stateColor\"]]]]],[9],[1,[27,\"uc-first\",[[23,[\"model\",\"transitioningMessage\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-container-dot.hbs"
    }
  });

  _exports.default = _default;
});