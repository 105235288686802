define("ui/components/input-resource-quota/component", ["exports", "ui/components/input-resource-quota/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    quota: null,
    key: null
  });

  _exports.default = _default;
});