define("ui/authenticated/cluster/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1znCcQl9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"clustersPage.editClusterName\"],[[\"name\"],[[23,[\"model\",\"originalCluster\",\"displayName\"]]]]],false],[0,\" (\"],[1,[27,\"driver-name\",[[23,[\"provider\"]]],null],false],[0,\")\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[5,\"cru-cluster\",[],[[\"@applyClusterTemplate\",\"@clusterTemplateRevisionId\",\"@model\",\"@initialProvider\",\"@provider\",\"@mode\",\"@close\"],[[27,\"not\",[[27,\"is-empty\",[[23,[\"clusterTemplateRevision\"]]],null]],null],[27,\"mut\",[[23,[\"clusterTemplateRevision\"]]],null],[21,\"model\"],[23,[\"cluster\",\"provider\"]],[21,\"provider\"],\"edit\",[27,\"action\",[[22,0,[]],\"close\"],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/edit/template.hbs"
    }
  });

  _exports.default = _default;
});