define("ui/components/input-random-port/component", ["exports", "ui/components/input-random-port/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    showEdit: false,
    min: '1',
    max: '65535',
    value: null,
    standardKey: 'generic.random',
    placeholder: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'value')) {
        Ember.set(this, 'showEdit', true);
      }
    },
    actions: {
      showEdit: function showEdit() {
        var _this = this;

        Ember.set(this, 'showEdit', true);
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT').last()[0].focus();
        });
      }
    }
  });

  _exports.default = _default;
});