define("ui/components/cru-config-map/component", ["exports", "shared/mixins/view-new-edit", "shared/mixins/optionally-namespaced", "ui/components/cru-config-map/template"], function (_exports, _viewNewEdit, _optionallyNamespaced, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_viewNewEdit.default, _optionallyNamespaced.default, {
    layout: _template.default,
    model: null,
    namespace: null,
    titleKey: 'newConfigMap.title',
    scope: 'namespace',
    namespacedType: 'configMap',
    isView: Ember.computed.equal('mode', _viewNewEdit.VIEW),
    isNew: Ember.computed.equal('mode', _viewNewEdit.NEW),
    isEdit: Ember.computed.equal('mode', _viewNewEdit.EDIT),
    notView: Ember.computed.or('isNew', 'isEdit'),
    init: function init() {
      this._super.apply(this, arguments);

      var ns = Ember.get(this, 'primaryResource.namespace');

      if (ns) {
        Ember.set(this, 'namespace', ns);
      }
    },
    actions: {
      updateData: function updateData(map) {
        Ember.set(this, 'primaryResource.data', map);
      }
    },
    willSave: function willSave() {
      var pr = Ember.get(this, 'primaryResource'); // Namespace is required, but doesn't exist yet... so lie to the validator

      var nsId = Ember.get(pr, 'namespaceId');
      Ember.set(pr, 'namespaceId', '__TEMP__');
      var ok = this.validate();
      Ember.set(pr, 'namespaceId', nsId);
      return ok;
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];
      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    },
    doSave: function doSave() {
      var _arguments = arguments;
      var self = this;
      var sup = self._super;
      return this.namespacePromise().then(function () {
        return sup.apply(self, _arguments);
      });
    },
    doneSaving: function doneSaving() {
      if (this.done) {
        this.done();
      }
    }
  });

  _exports.default = _default;
});