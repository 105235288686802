define("ui/authenticated/cluster/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    wasReady: true,
    watchReady: Ember.observer('model.isReady', function () {
      var _this = this;

      var wasReady = Ember.get(this, 'wasReady');
      var isReady = Ember.get(this, 'model.isReady');
      Ember.set(this, 'wasReady', isReady);

      if (isReady && !wasReady) {
        Ember.run.next(function () {
          _this.send('becameReady');
        });
      }
    })
  });

  _exports.default = _default;
});