define("ui/initializers/inject-router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    // Injects all Ember components & models (for actions) with a router object:
    application.inject('component', 'router', 'router:main');
    application.inject('model', 'router', 'router:main');
  }

  var _default = {
    name: 'inject-router',
    initialize: initialize
  };
  _exports.default = _default;
});