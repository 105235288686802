define("ui/verify-auth-azure/route", ["exports", "shared/utils/constants", "ui/mixins/verify-auth"], function (_exports, _constants, _verifyAuth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_verifyAuth.default, {
    azureAd: Ember.inject.service(),
    model: function model(params
    /* , transition */
    ) {
      var _this = this;

      if (window.opener) {
        var stateMsg = 'Authorization state did not match, please try again.';

        if (Ember.get(params, 'code') && window.opener.window.onAzureTest) {
          reply(null, Ember.get(params, 'code'));
        } else {
          reply(stateMsg);
        }
      }

      if (Ember.get(params, 'code') && !window.opener) {
        var azureProvider = Ember.get(this, 'access.providers').findBy('id', 'azuread');
        return azureProvider.doAction('login', {
          code: Ember.get(params, 'code'),
          description: _constants.default.SESSION.DESCRIPTION,
          responseType: 'cookie',
          ttl: _constants.default.SESSION.TTL
        }).then(function () {
          return Ember.get(_this, 'access').detect().then(function () {
            return _this.transitionTo('authenticated');
          });
        });
      }

      function reply(err, code) {
        var opener = window.opener.window;

        if (opener.onAzureTest) {
          opener.onAzureTest(err, code);
          setTimeout(function () {
            window.close();
          }, 250);
        } else {
          window.close();
        }
      }
    }
  });

  _exports.default = _default;
});