define("ui/models/typedocumentation", ["exports", "@rancher/ember-api-store/utils/normalize", "@rancher/ember-api-store/models/resource"], function (_exports, _normalize, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TypeDocumentation = _resource.default.extend();

  TypeDocumentation.reopenClass({
    mangleIn: function mangleIn(data) {
      // Pass IDs through the type normalizer so they will match the case in other places like store.find('schema',normalizeType('thing'))
      data.id = (0, _normalize.normalizeType)(data.id);
      return data;
    }
  });
  var _default = TypeDocumentation;
  _exports.default = _default;
});