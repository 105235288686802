define("ui/components/form-access-modes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C08Uxid+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"acc-label\"],[9],[1,[27,\"t\",[\"formAccessModes.label\"],null],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"checkbox\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"accessRWO\"]]]]],false],[0,\"\\n      \"],[1,[27,\"t\",[\"formAccessModes.accessRWO\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"checkbox\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"accessROX\"]]]]],false],[0,\"\\n      \"],[1,[27,\"t\",[\"formAccessModes.accessROX\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"checkbox\"],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"accessRWX\"]]]]],false],[0,\"\\n      \"],[1,[27,\"t\",[\"formAccessModes.accessRWX\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"accessRWO\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[1,[27,\"t\",[\"formAccessModes.accessRWO\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"accessROX\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[1,[27,\"t\",[\"formAccessModes.accessROX\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"accessRWX\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[1,[27,\"t\",[\"formAccessModes.accessRWX\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-access-modes/template.hbs"
    }
  });

  _exports.default = _default;
});