define("ui/components/volume-source/source-flex-volume-longhorn/component", ["exports", "ui/components/volume-source/source-flex-volume-longhorn/template", "shared/mixins/volume-source"], function (_exports, _template, _volumeSource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_volumeSource.default, {
    layout: _template.default,
    field: 'flexVolume',
    init: function init() {
      this._super();

      Ember.get(this, 'config.driver') || Ember.set(this, 'config.driver', 'rancher.io/longhorn');
      Ember.get(this, 'config.options') || Ember.set(this, 'config.options', {
        size: '2Gi',
        numberOfReplicas: '3',
        staleReplicaTimeout: '20',
        fromBackup: ''
      });
    }
  });

  _exports.default = _default;
});