define("ui/update-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AvJnEHpw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"firstLogin\"]]],null,{\"statements\":[[0,\"\\n      \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"modalEditPassword.firstLogin.title\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"help-text\"],[9],[1,[27,\"t\",[\"modalEditPassword.firstLogin.help\"],[[\"appName\",\"htmlSafe\"],[[23,[\"settings\",\"appName\"]],true]]],false],[10],[0,\"\\n\\n      \"],[7,\"label\"],[11,\"class\",\"text-small\"],[9],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"model\",\"optIn\"]]]]],false],[0,\" \"],[1,[27,\"t\",[\"telemetryOpt.label\"],null],false],[10],[0,\" \"],[7,\"a\"],[12,\"href\",[28,[[23,[\"settings\",\"docsBase\"]],\"/faq/telemetry\"]]],[11,\"class\",\"text-small\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener nofollow\"],[9],[1,[27,\"t\",[\"telemetryOpt.learnMore.label\"],null],false],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"modalEditPassword.edit.title\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"help-text\"],[9],[1,[27,\"t\",[\"modalEditPassword.edit.help\"],[[\"appName\",\"htmlSafe\"],[[23,[\"settings\",\"appName\"]],true]]],false],[10],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[1,[27,\"input-edit-password\",null,[[\"complete\",\"user\",\"showCurrent\",\"currentPassword\",\"editButton\",\"cancelDisabled\"],[[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],[23,[\"complete\"]]],null]],null],[23,[\"model\",\"user\"]],[23,[\"showCurrent\"]],[23,[\"currentPassword\"]],[27,\"if\",[[23,[\"firstLogin\"]],\"modalEditPassword.firstLogin.actionButton\",\"modalEditPassword.actionButton\"],null],true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/update-password/template.hbs"
    }
  });

  _exports.default = _default;
});