define("ui/authenticated/project/certificates/new/controller", ["exports", "ui/mixins/new-or-edit"], function (_exports, _newOrEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    actions: {
      back: function back() {
        this.transitionToRoute('authenticated.project.certificates');
      }
    }
  });

  _exports.default = _default;
});