define("ui/authenticated/project/registries/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['id', 'type'],
    actions: {
      back: function back() {
        this.transitionToRoute('authenticated.project.registries');
      }
    }
  });

  _exports.default = _default;
});