define("ui/initializers/viewport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    if (screen && screen.width <= 570) {
      document.getElementById('viewport').setAttribute('content', 'width=570');
    }
  }

  var _default = {
    name: 'viewport',
    initialize: initialize
  };
  _exports.default = _default;
});