define("ui/models/volume", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerSource = registerSource;
  _exports.getSources = getSources;
  _exports.default = void 0;
  var SOURCES = []; //             name/component    field                     component ephemeral persistent supported driver

  registerSource('aws-ebs', 'awsElasticBlockStore', true, true, true, true);
  registerSource('azure-disk', 'azureDisk', true, true, true, true);
  registerSource('azure-file', 'azureFile', true, true, true, true);
  registerSource('cephfs', 'cephfs', true, true, true, false);
  registerSource('cinder', 'cinder', true, true, true, false);
  registerSource('config-map', 'configMap', true, false, false, false); // registerSource('downward-api',      'downwardAPI',            true, true,  false, false);

  registerSource('empty-dir', 'emptyDir', true, true, false, false);
  registerSource('fc', 'fc', true, true, true, false);
  registerSource('flex-volume', 'flexVolume', true, true, true, false);
  registerSource('flex-volume-longhorn', 'flexVolume', true, true, true, true, 'rancher.io/longhorn');
  registerSource('flocker', 'flocker', true, true, true, false);
  registerSource('gce-pd', 'gcePersistentDisk', true, true, true, true); // registerSource('git-repo',          'gitRepo',                true, true,  false, false);

  registerSource('glusterfs', 'glusterfs', true, true, true, false);
  registerSource('host-path', 'hostPath', true, true, true, true);
  registerSource('iscsi', 'iscsi', true, true, true, false);
  registerSource('local', 'local', true, false, true, true);
  registerSource('nfs', 'nfs', true, true, true, true); // registerSource('pvc',               'persisitentVolumeClaim', true, true,  false, false);

  registerSource('photon', 'photonPersistentDisk', true, true, true, false);
  registerSource('portworx', 'portworxVolume', true, true, true, false); // registerSource('projected',         'projected',              true, true,  false, false);

  registerSource('quobyte', 'quobyte', true, true, true, false);
  registerSource('rbd', 'rbd', true, true, true, false);
  registerSource('scaleio', 'scaleIO', true, true, true, false);
  registerSource('secret', 'secret', true, true, false, false);
  registerSource('storageos', 'storageos', true, true, true, false);
  registerSource('vsphere-volume', 'vsphereVolume', true, true, true, true);

  function registerSource(name, field, component) {
    var ephemeral = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    var persistent = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
    var supported = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var driver = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : '';

    if (component === true) {
      component = name;
    }

    var existing = SOURCES.findBy('name', name);

    if (existing) {
      SOURCES.removeObject(existing);
    }

    SOURCES.push({
      name: name,
      value: field,
      driver: driver,
      component: component,
      ephemeral: !!ephemeral,
      persistent: !!persistent,
      supported: !!supported
    });
  }

  function getSources() {
    var which = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';

    if (which === 'ephemeral') {
      return JSON.parse(JSON.stringify(SOURCES.filter(function (s) {
        return s.ephemeral;
      })));
    } else if (which === 'persistent') {
      return JSON.parse(JSON.stringify(SOURCES.filter(function (s) {
        return s.persistent;
      })));
    } else {
      return JSON.parse(JSON.stringify(SOURCES));
    }
  }

  var Volume = _resource.default.extend({
    intl: Ember.inject.service(),
    reservedKeys: ['configName'],
    sources: SOURCES,
    type: 'volume',
    configName: Ember.computed('sources.@each.{value}', function () {
      var keys = Ember.get(this, 'sources').map(function (x) {
        return x.value;
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (Ember.get(this, key)) {
          return key;
        }
      }

      return null;
    }),
    config: Ember.computed('configName', function () {
      var key = Ember.get(this, 'configName');

      if (key) {
        return Ember.get(this, key);
      }
    }),
    sourceName: Ember.computed('configName', function () {
      var key = Ember.get(this, 'configName');

      if (!key) {
        return;
      }

      var entry;
      var driver = Ember.get(this, key).driver;
      var sources = Ember.get(this, 'sources');
      entry = sources.findBy('value', key);

      if (key === 'flexVolume' && driver) {
        var specialSource = sources.findBy('driver', driver);

        if (specialSource) {
          entry = specialSource;
        }
      }

      if (entry) {
        return entry.name;
      }
    }),
    displaySource: Ember.computed('sourceName', 'intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var sourceName = Ember.get(this, 'sourceName');

      if (sourceName) {
        return intl.t("volumeSource.".concat(sourceName, ".title"));
      }
    }),
    clearSourcesExcept: function clearSourcesExcept(keep) {
      var keys = Ember.get(this, 'sources').map(function (x) {
        return x.value;
      });

      for (var key, i = 0; i < keys.length; i++) {
        key = keys[i];

        if (key !== keep && Ember.get(this, key)) {
          Ember.set(this, key, null);
        }
      }
    }
  });

  Volume.reopenClass({
    stateMap: {
      'active': {
        icon: 'icon icon-hdd',
        color: 'text-success'
      }
    }
  });
  var _default = Volume;
  _exports.default = _default;
});