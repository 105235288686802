define("ui/components/settings/user-info/component", ["exports", "ui/components/settings/user-info/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    access: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    account: null,
    actions: {
      editPassword: function editPassword() {
        this.get('account').send('edit');
      }
    }
  });

  _exports.default = _default;
});