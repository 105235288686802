define("ui/components/container/form-ports/component", ["exports", "ui/components/container/form-ports/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KINDS = ['NodePort', 'HostPort', 'ClusterIP', 'LoadBalancer'];
  var protocolOptions = [{
    label: 'TCP',
    value: 'TCP'
  }, {
    label: 'UDP',
    value: 'UDP'
  }];

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    settings: Ember.inject.service(),
    capabilities: Ember.inject.service(),
    layout: _template.default,
    protocolOptions: protocolOptions,
    initialPorts: null,
    editing: false,
    showWarning: false,
    kindChoices: null,
    ports: null,
    nodePortFrom: null,
    nodePortTo: null,
    nodePortPlaceholder: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.initPorts();
      this.nodePortRangeDidChange();
      this.initKindChoices();
    },
    actions: {
      addPort: function addPort() {
        var _this = this;

        this.get('ports').pushObject(Ember.get(this, 'store').createRecord({
          type: 'containerPort',
          kind: 'NodePort',
          protocol: 'TCP',
          containerPort: ''
        }));
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.public').last()[0].focus();
        });
      },
      removePort: function removePort(obj) {
        this.get('ports').removeObject(obj);
      }
    },
    portsChanged: Ember.observer('ports.@each.{containerPort,dnsName,hostIp,kind,name,protocol,sourcePort,_ipPort}', function () {
      var errors = [];
      var intl = Ember.get(this, 'intl');
      var ports = Ember.get(this, 'ports');
      ports.forEach(function (obj) {
        var containerPort = obj.containerPort;

        if (!containerPort) {
          errors.push(intl.t('formPorts.error.privateRequired'));
        }

        if (obj.kind === 'HostPort' && !Ember.get(obj, '_ipPort')) {
          errors.push(intl.t('formPorts.error.hostPort.sourcePortRequired'));
        }

        if (obj.kind === 'LoadBalancer' && !Ember.get(obj, 'sourcePort')) {
          errors.push(intl.t('formPorts.error.loadBalancer.sourcePortRequired'));
        }

        if (!obj.sourcePort) {
          delete obj['sourcePort'];
        }

        if (Ember.get(obj, 'kind') === 'HostPort') {
          var port = Ember.get(obj, '_ipPort') || '';
          var ip;
          var idx = port.lastIndexOf(':');

          if (idx >= 0) {
            ip = port.substr(0, idx);
            port = port.substr(idx + 1);
          }

          var toSet = {};

          if (ip) {
            if (Ember.get(obj, 'hostIp') !== ip) {
              toSet['hostIp'] = ip;
            }
          } else {
            delete obj['hostIp'];
          }

          port = parseInt(port, 10) || undefined;

          if (port !== Ember.get(obj, 'sourcePort')) {
            toSet['sourcePort'] = port;
          }

          Ember.setProperties(obj, toSet);
        }
      });
      this.set('errors', errors.uniq());

      if (this.changed) {
        this.changed(ports.slice());
      }
    }),
    nodePortRangeDidChange: Ember.observer('intl.locale', 'scope.currentCluster.rancherKubernetesEngineConfig.services.kubeApi.serviceNodePortRange', function () {
      var intl = Ember.get(this, 'intl');
      var nodePortRange = Ember.get(this, 'scope.currentCluster.rancherKubernetesEngineConfig.services.kubeApi.serviceNodePortRange');
      var ccPorts = Ember.get(this, 'capabilities.allowedNodePortRanges');

      if (nodePortRange) {
        var ports = nodePortRange.split('-');

        if (ports.length === 2) {
          var from = parseInt(ports[0], 10);
          var to = parseInt(ports[1], 10);

          if (ccPorts.length > 0) {
            if (from < ccPorts[0]) {
              from = ccPorts[0];
            }

            if (to > ccPorts[1]) {
              to = ccPorts[1];
            }
          }

          Ember.setProperties(this, {
            nodePortFrom: from,
            nodePortTo: to,
            nodePortPlaceholder: intl.t('formPorts.nodePort.customPortRangePlaceholder', {
              range: nodePortRange,
              port: from
            })
          });
          return;
        }
      }

      Ember.setProperties(this, {
        nodePortFrom: 1,
        nodePortTo: 65535,
        nodePortPlaceholder: intl.t('formPorts.nodePort.placeholder')
      });
    }),
    initPorts: function initPorts() {
      var ports = Ember.get(this, 'initialPorts') || [];
      ports.forEach(function (obj) {
        if (Ember.get(obj, 'kind') === 'HostPort') {
          var ip = Ember.get(obj, 'hostIp');
          var port = Ember.get(obj, 'sourcePort');
          Ember.set(obj, '_ipPort', (ip ? "".concat(ip, ":") : '') + port);
        }
      });
      Ember.set(this, 'ports', ports);
    },
    initKindChoices: function initKindChoices() {
      var loadBalancerCapabilites = Ember.get(this, 'capabilities.loadBalancerCapabilites');
      Ember.set(this, 'kindChoices', KINDS.map(function (k) {
        var out = {
          translationKey: null,
          value: k
        };

        if (!loadBalancerCapabilites.l4LoadBalancerEnabled && k === 'LoadBalancer') {
          Ember.setProperties(out, {
            translationKey: "formPorts.kind.LoadBalancerDisbaled",
            disabled: true
          });
        } else {
          Ember.set(out, 'translationKey', "formPorts.kind.".concat(k));
        }

        return out;
      }));
    }
  });

  _exports.default = _default;
});