define("ui/catalog-tab/launch/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['istio', 'appId', 'appName', 'stackId', 'upgrade', 'catalog', 'namespaceId', 'clone'],
    stackId: null,
    upgrade: null,
    showName: true,
    catalog: null,
    namespaceId: null,
    appId: null,
    appName: null,
    istio: false,
    parentRoute: 'catalog-tab',
    actions: {
      cancel: function cancel() {
        this.send('goToPrevious', 'apps-tab.index');
      }
    }
  });

  _exports.default = _default;
});