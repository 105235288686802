define("ui/initializers/polyfill-svg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application*/
  {
    // http://jointjs.com/blog/get-transform-to-element-polyfill.html
    if (!SVGElement.prototype.getTransformToElement) {
      SVGElement.prototype.getTransformToElement = function (toElement) {
        return toElement.getScreenCTM().inverse().multiply(this.getScreenCTM());
      };
    }
  }

  var _default = {
    name: 'polyfill-svg',
    initialize: initialize
  };
  _exports.default = _default;
});