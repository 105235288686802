define("ui/update-critical-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aea7T+hm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[9],[0,\"\\n  \"],[1,[27,\"settings/server-url\",null,[[\"serverUrlSetting\",\"initServerUrl\"],[[23,[\"model\",\"serverUrlSetting\"]],[23,[\"model\",\"serverUrl\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/update-critical-settings/template.hbs"
    }
  });

  _exports.default = _default;
});