define("ui/ie/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    bootstrap: function () {
      Ember.run.schedule('afterRender', this, function () {
        Ember.$('#loading-overlay').hide();
        Ember.$('#loading-underlay').hide();
      });
    }.on('init')
  });

  _exports.default = _default;
});