define("ui/helpers/run-time", ["exports", "shared/helpers/run-time"], function (_exports, _runTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _runTime.default;
    }
  });
});