define("ui/containers/run/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    prefs: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var promises = {};

      if (!window.Prettycron) {
        Ember.set(promises, 'Prettycron', emberAutoImportDynamic("prettycron"));
      }

      if (!window.ShellQuote) {
        Ember.set(promises, 'ShellQuote', emberAutoImportDynamic("shell-quote"));
      }

      return Ember.RSVP.hash(promises).then(function (resolved) {
        if (resolved.Prettycron) {
          window.Prettycron = resolved.Prettycron;
        }

        if (resolved.ShellQuote) {
          window.ShellQuote = resolved.ShellQuote;
        }

        return resolved;
      });
    },
    model: function model(params
    /* , transition*/
    ) {
      var _this = this;

      var store = Ember.get(this, 'store');
      var gs = Ember.get(this, 'globalStore');
      var appRoute = window.l('route:application');
      var project = appRoute.modelFor('authenticated.project').get('project');
      var projectId = project.get('id');
      var clusterId = project.get('clusterId');
      var clusterLogging = gs.find('clusterLogging').then(function (res) {
        var logging = res.filterBy('clusterId', clusterId).get('firstObject');
        return !!logging;
      });
      var projectLogging = gs.find('projectLogging').then(function (res) {
        var logging = res.filterBy('projectId', projectId).get('firstObject');
        return !!logging;
      });
      var promise = null;

      if (params.workloadId) {
        // Existing Service
        promise = store.find('workload', params.workloadId).then(function (workload) {
          return _this.modelForExisting(workload, params);
        });
      } else {
        promise = Ember.RSVP.resolve(this.modelForNew(params));
      }

      return Ember.RSVP.hash({
        dataMap: promise,
        clusterLogging: clusterLogging,
        projectLogging: projectLogging
      }).then(function (hash) {
        return {
          loggingEnabled: hash.clusterLogging || hash.projectLogging,
          dataMap: hash.dataMap
        };
      });
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.set(controller, 'namespaceId', null);
        Ember.set(controller, 'workloadId', null);
        Ember.set(controller, 'podId', null);
        Ember.set(controller, 'upgrade', null);
        Ember.set(controller, 'addSidekick', null);
        Ember.set(controller, 'launchConfigIndex', null);
      }
    },
    queryParams: {
      launchConfigIndex: {
        refreshModel: true
      }
    },
    modelForNew: function modelForNew(params) {
      var scaleMode = Ember.get(this, "prefs.".concat(_constants.default.PREFS.LAST_SCALE_MODE)) || 'deployment';

      if (scaleMode === 'container' || scaleMode === 'service') {
        scaleMode = 'deployment';
      }

      return Ember.Object.create({
        scaleMode: scaleMode,
        workload: this.emptyWorkload(params),
        container: this.emptyContainer(params),
        isUpgrade: false
      });
    },
    modelForExisting: function modelForExisting(_workload, params) {
      if (!_workload) {
        return Ember.RVP.reject('Workload not found');
      }

      var clone = _workload.clone();

      var cloneType = clone.type;

      if (!params.upgrade && params.addSidekick !== 'true') {
        delete clone['workloadAnnotations'];
        delete clone['workloadLabels'];
        delete clone['publicEndpoints'];
        Ember.set(clone, 'type', 'workload');

        if (clone.labels) {
          delete clone.labels['workload.user.cattle.io/workloadselector'];
        }

        if (clone.selector && clone.selector.matchLabels) {
          delete clone.selector.matchLabels['workload.user.cattle.io/workloadselector'];

          if (!Object.keys(clone.selector.matchLabels).length) {
            delete clone.selector['matchLabels'];
          }
        }
      }

      var containerNames = clone.containers.map(function (x) {
        return Ember.get(x, 'name');
      });
      var containerName = null;

      if (params.launchConfigIndex !== null) {
        var launchConfigIndex = parseInt(params.launchConfigIndex, 10);

        if (launchConfigIndex > -1) {
          containerName = clone.containers[launchConfigIndex + 1].name;
        } else if (launchConfigIndex === -1) {
          containerName = '';
        }
      } // Add a sidekick


      if (params.addSidekick) {
        return Ember.Object.create({
          scaleMode: 'sidekick',
          workload: clone,
          container: this.emptyContainer(params, Ember.get(clone, 'namespaceId')),
          isUpgrade: false
        });
      } else if (containerName === null) {
        // Figure out the container name
        if (containerNames.length > 1) {
          if (params.upgrade) {
            // If there are sidekicks, you need to pick one & come back
            return Ember.Object.create({
              workload: clone,
              containerNames: containerNames,
              selectLaunchConfig: true
            });
          } else {
            // Clone with multiple containers not supported yet
            return Ember.RVP.reject('Cloning a workload with multiple containers not supported');
          }
        } else {
          // Otherwise use primary
          containerName = '';
        }
      }

      var container;

      if (containerName === '') {
        // The primary/only container
        container = clone.containers[0];
      } else {
        // Existing container
        container = clone.containers.findBy('name', containerName);
      }

      if (params.upgrade) {
        // Upgrade workload
        var out = Ember.Object.create({
          scaleMode: containerName ? 'sidekick' : cloneType,
          workload: clone,
          container: container,
          isUpgrade: true
        });
        return out;
      } else {
        // Clone workload with one container
        var neu = Ember.get(this, 'store').createRecord(clone.serializeForNew());
        delete neu.deploymentStatus;
        container = neu.containers[0]; // Cleanup port mappings so they get new services

        (neu.containers || []).forEach(function (container) {
          (container.ports || []).forEach(function (port) {
            delete port.name;
            delete port.dnsName;
          });
        });
        return Ember.Object.create({
          scaleMode: cloneType,
          workload: neu,
          container: container,
          isUpgrade: false // no launchConfigIndex because this will be a new service or sidekick

        });
      }
    },
    getNamespaceId: function getNamespaceId(params) {
      var clusterStore = Ember.get(this, 'clusterStore');
      var ns = null;

      if (params.namespaceId) {
        ns = clusterStore.getById('namespace', params.namespaceId);
      }

      if (!ns) {
        var project = window.l('route:application').modelFor('authenticated.project').get('project');
        var projectId = project.get('id');
        var lastNamespace = clusterStore.getById('namespace', Ember.get(this, "prefs.".concat(_constants.default.PREFS.LAST_NAMESPACE)));

        if (lastNamespace && Ember.get(lastNamespace, 'projectId') === projectId) {
          ns = lastNamespace;
        }
      }

      var namespaceId = null;

      if (ns) {
        namespaceId = ns.get('id');
      }

      return namespaceId;
    },
    emptyWorkload: function emptyWorkload(params) {
      var store = Ember.get(this, 'store');
      return store.createRecord({
        type: 'workload',
        namespaceId: this.getNamespaceId(params),
        scale: 1,
        dnsPolicy: 'ClusterFirst',
        restartPolicy: 'Always',
        labels: {},
        containers: []
      });
    },
    emptyContainer: function emptyContainer(params, namespaceId) {
      return Ember.get(this, 'store').createRecord({
        type: 'container',
        tty: true,
        stdin: true,
        privileged: false,
        allowPrivilegeEscalation: false,
        readOnly: false,
        runAsNonRoot: false,
        namespaceId: namespaceId ? namespaceId : this.getNamespaceId(params),
        imagePullPolicy: Ember.get(this, "prefs.".concat(_constants.default.PREFS.LAST_IMAGE_PULL_POLICY)) || 'Always'
      });
    }
  });

  _exports.default = _default;
});