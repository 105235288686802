define("ui/authenticated/project/security/members/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IjGELd1K",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[27,\"t\",[\"membersPage.add.title\"],[[\"type\"],[\"Project\"]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"box mt-30\"],[9],[0,\"\\n    \"],[1,[27,\"form-scoped-roles\",null,[[\"model\",\"type\",\"cancel\",\"doneSaving\"],[[23,[\"model\"]],\"project\",[27,\"action\",[[22,0,[]],\"doneSaving\"],null],[27,\"action\",[[22,0,[]],\"doneSaving\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/project/security/members/new/template.hbs"
    }
  });

  _exports.default = _default;
});