define("ui/components/form-key-to-path/component", ["exports", "ui/components/form-key-to-path/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SECRET = 'secret';
  var CONFIG_MAP = 'configmap';

  var _default = Ember.Component.extend({
    layout: _template.default,
    // Inputs
    initialItems: null,
    secretName: null,
    configMapName: null,
    mode: SECRET,
    editing: null,
    ary: null,
    keys: null,
    allSecrets: null,
    allConfigMaps: null,
    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];
      var items = Ember.get(this, 'initialItems');

      if (Ember.get(this, 'mode') === SECRET) {
        var allSecrets = Ember.get(this, 'store').all('secret');
        var namespacedSecrets = Ember.get(this, 'store').all('namespacedSecret').filterBy('type', 'namespacedSecret');
        allSecrets.pushObjects(namespacedSecrets);
        Ember.set(this, 'allSecrets', allSecrets);
        this.updateSecretKeys();
      }

      if (Ember.get(this, 'mode') === CONFIG_MAP) {
        var allConfigMaps = Ember.get(this, 'store').all('configmap');
        Ember.set(this, 'allConfigMaps', allConfigMaps);
        this.updateConfigMapKeys();
      }

      if (items) {
        items.forEach(function (item) {
          ary.push(Ember.Object.create({
            key: item.key,
            path: item.path,
            mode: item.mode ? new Number(item.mode).toString(8) : null
          }));
        });
      }

      Ember.set(this, 'ary', ary);

      if (!ary.length) {
        this.send('add');
      }
    },
    actions: {
      add: function add() {
        var _this = this;

        var ary = Ember.get(this, 'ary');
        ary.pushObject(Ember.Object.create({
          key: '',
          path: '',
          mode: ''
        }));
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          var elem = _this.$('INPUT.key').last()[0];

          if (elem) {
            elem.focus();
          }
        });
      },
      remove: function remove(obj) {
        Ember.get(this, 'ary').removeObject(obj);
      }
    },
    secretDidChange: Ember.observer('secretName', function () {
      if (Ember.get(this, 'mode') === SECRET) {
        this.updateSecretKeys();
        Ember.set(this, 'ary', []);
      }
    }),
    configMapDidChange: Ember.observer('configMapName', function () {
      if (Ember.get(this, 'mode') === CONFIG_MAP) {
        this.updateConfigMapKeys();
        Ember.set(this, 'ary', []);
      }
    }),
    aryObserver: Ember.on('init', Ember.observer('ary.@each.{key,path,mode}', function () {
      Ember.run.debounce(this, 'fireChanged', 100);
    })),
    // Secret
    updateSecretKeys: function updateSecretKeys() {
      var allSecrets = Ember.get(this, 'allSecrets');
      var secretName = Ember.get(this, 'secretName');
      Ember.set(this, 'keys', []);

      if (secretName) {
        var secret = allSecrets.findBy('name', secretName);

        if (secret) {
          Ember.set(this, 'keys', Object.keys(secret.data).map(function (k) {
            return {
              label: k,
              value: k
            };
          }));
        }
      }
    },
    // Config Map
    updateConfigMapKeys: function updateConfigMapKeys() {
      var allConfigMaps = Ember.get(this, 'allConfigMaps');
      var configMapName = Ember.get(this, 'configMapName');
      Ember.set(this, 'keys', []);

      if (configMapName) {
        var configMap = allConfigMaps.findBy('name', configMapName);

        if (configMap) {
          Ember.set(this, 'keys', Object.keys(configMap.data).map(function (k) {
            return {
              label: k,
              value: k
            };
          }));
        }
      }
    },
    fireChanged: function fireChanged() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var arr = [];
      Ember.get(this, 'ary').forEach(function (row) {
        var k = (row.get('key') || '').trim();
        var p = (row.get('path') || '').trim();
        var m = (row.get('mode') || '').trim();

        if (k && p) {
          if (m) {
            arr.push({
              key: k,
              path: p,
              mode: parseInt(m, 8)
            });
          } else {
            arr.push({
              key: k,
              path: p
            });
          }
        }
      });

      if (this.changed) {
        this.changed(arr);
      }
    }
  });

  _exports.default = _default;
});