define("ui/models/persistentvolume", ["exports", "ui/models/volume", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _volume, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _volume.default.extend({
    router: Ember.inject.service(),
    type: 'persistentVolume',
    storageClass: (0, _denormalize.reference)('storageClassId'),
    canRemove: Ember.computed('links.remove', 'state', function () {
      return !!Ember.get(this, 'links.remove') && Ember.get(this, 'state') !== 'bound';
    }),
    displayPvc: Ember.computed('claimRef.namespace', 'claimRef.name', function () {
      if (Ember.get(this, 'claimRef.name')) {
        return "".concat(Ember.get(this, 'claimRef.namespace'), "/").concat(Ember.get(this, 'claimRef.name'));
      }
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('authenticated.cluster.storage.persistent-volumes.detail.edit', Ember.get(this, 'id'));
      }
    }
  });

  _exports.default = _default;
});