define("ui/volumes/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      done: function done() {
        return this.transitionToRoute('volumes.index');
      },
      cancel: function cancel() {
        this.send('goToPrevious', 'volumes.index');
      }
    }
  });

  _exports.default = _default;
});