define("ui/helpers/date-recent", ["exports", "shared/helpers/date-recent"], function (_exports, _dateRecent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _dateRecent.default;
    }
  });
});