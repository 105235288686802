define("ui/services/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    unknownProperty: function unknownProperty(key) {
      return Ember.get(this, "app.".concat(key));
    }
  });

  _exports.default = _default;
});