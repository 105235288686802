define("ui/authenticated/project/console/controller", ["exports", "ui/mixins/console"], function (_exports, _console) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_console.default, {
    command: null
  });

  _exports.default = _default;
});