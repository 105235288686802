define("ui/models/namespace", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/parse-externalid", "shared/utils/util", "shared/utils/parse-unit", "ui/utils/constants", "@rancher/ember-api-store/utils/denormalize", "ui/mixins/state-counts"], function (_exports, _resource, _parseExternalid, _util, _parseUnit, _constants, _denormalize, _stateCounts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertResourceQuota = convertResourceQuota;
  _exports.activeIcon = activeIcon;
  _exports.tagsToArray = tagsToArray;
  _exports.normalizeTag = normalizeTag;
  _exports.normalizeTags = normalizeTags;
  _exports.default = void 0;
  var ISTIO_INJECTION = 'istio-injection';
  var ENABLED = 'enabled';

  function convertResourceQuota(key, value) {
    var out;

    switch (key) {
      case 'limitsCpu':
      case 'requestsCpu':
        out = (0, _util.convertToMillis)(value);
        break;

      case 'limitsMemory':
      case 'requestsMemory':
        out = (0, _parseUnit.parseSi)(value, 1024) / 1048576;
        break;

      case 'requestsStorage':
        out = (0, _parseUnit.parseSi)(value) / Math.pow(1024, 3);
        break;

      default:
        out = parseInt(value, 10);
    }

    return out;
  }

  function activeIcon(ns) {
    if (ns.get('system')) {
      return 'icon icon-gear';
    } else {
      return 'icon icon-layers';
    }
  }

  function tagsToArray(str) {
    var normalize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return (str || '').split(/\s*,\s*/).map(function (tag) {
      if (normalize) {
        return normalizeTag(tag);
      } else {
        return tag;
      }
    }).filter(function (tag) {
      return tag.length > 0;
    });
  }

  function normalizeTag(name) {
    return (name || '').trim().toLowerCase();
  }

  function normalizeTags(ary) {
    return (ary || []).map(normalizeTag).filter(function (str) {
      return str.length > 0;
    });
  }

  var Namespace = _resource.default.extend(_stateCounts.default, {
    k8s: Ember.inject.service(),
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    projectStore: Ember.inject.service('store'),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    pods: (0, _denormalize.hasMany)('id', 'pod', 'namespaceId', 'projectStore', null, 'clusterStore'),
    configMaps: (0, _denormalize.hasMany)('id', 'configMap', 'namespaceId', 'projectStore', null, 'clusterStore'),
    workloads: (0, _denormalize.hasMany)('id', 'workload', 'namespaceId', 'projectStore', null, 'clusterStore'),
    services: (0, _denormalize.hasMany)('id', 'service', 'namespaceId', 'projectStore', null, 'clusterStore'),
    secrets: (0, _denormalize.hasMany)('id', 'namespacedSecret', 'namespaceId', 'projectStore', null, 'clusterStore'),
    ingress: (0, _denormalize.hasMany)('id', 'ingress', 'namespaceId', 'projectStore', null, 'clusterStore'),
    volumes: (0, _denormalize.hasMany)('id', 'persistentVolumeClaim', 'namespaceId', 'projectStore', null, 'clusterStore'),
    type: 'namespace',
    project: (0, _denormalize.reference)('projectId', 'project', 'globalStore'),
    init: function init() {
      this._super.apply(this, arguments); // @TODO-2.0 this.defineStateCounts('services', 'serviceStates', 'serviceCountSort');

    },
    availableActions: Ember.computed('projectId', 'actionLinks.@each.{move}', 'scope.currentCluster.istioEnabled', 'scope.currentCluster.systemProject', 'autoInjectionEnabled', function () {
      var aa = Ember.get(this, 'actionLinks') || {};
      var out = [{
        label: 'action.move',
        icon: 'icon icon-fork',
        action: 'move',
        enabled: !!aa.move,
        bulkable: true
      }, {
        label: 'action.enableAutoInject',
        icon: 'icon icon-plus-circle',
        action: 'enableAutoInject',
        enabled: Ember.get(this, 'scope.currentCluster.istioEnabled') && !!Ember.get(this, 'scope.currentCluster.systemProject') && !Ember.get(this, 'autoInjectionEnabled'),
        bulkable: true
      }, {
        label: 'action.disableAutoInject',
        icon: 'icon icon-minus-circle',
        action: 'disableAutoInject',
        enabled: Ember.get(this, 'scope.currentCluster.istioEnabled') && !!Ember.get(this, 'scope.currentCluster.systemProject') && Ember.get(this, 'autoInjectionEnabled'),
        bulkable: true
      }, {
        divider: true
      }];
      return out;
    }),
    combinedState: Ember.computed('state', 'healthState', function () {
      var stack = this.get('state');
      var health = this.get('healthState');

      if (stack === 'active' && health) {
        return health;
      } else {
        return stack;
      }
    }),
    externalIdInfo: Ember.computed('externalId', function () {
      return (0, _parseExternalid.parseExternalId)(this.get('externalId'));
    }),
    isDefault: Ember.computed('name', function () {
      return (this.get('name') || '').toLowerCase() === 'default';
    }),
    isEmpty: Ember.computed('pods.length', 'workloads.length', function () {
      return (Ember.get(this, 'pods.length') || 0 + Ember.get(this, 'workloads.length') || 0) === 0;
    }),
    hasProject: Ember.computed('project', function () {
      return !!Ember.get(this, 'project');
    }),
    isFromCatalog: Ember.computed('externalIdInfo.kind', function () {
      var kind = this.get('externalIdInfo.kind');
      return kind === _constants.default.EXTERNAL_ID.KIND_CATALOG || kind === _constants.default.EXTERNAL_ID.KIND_SYSTEM_CATALOG;
    }),
    // This only works if the templates have already been loaded elsewhere...
    catalogTemplate: Ember.computed('externalIdInfo.templateId', function () {
      return this.get('catalog').getTemplateFromCache(this.get('externalIdInfo.templateId'));
    }),
    icon: Ember.computed('catalogTemplate', function () {
      var tpl = this.get('catalogTemplate');

      if (tpl) {
        return tpl.linkFor('icon');
      }
    }),
    grouping: Ember.computed('externalIdInfo.kind', 'group', 'system', function () {
      var kind = this.get('externalIdInfo.kind');

      if (kind === _constants.default.EXTERNAL_ID.KIND_KUBERNETES || kind === _constants.default.EXTERNAL_ID.KIND_LEGACY_KUBERNETES) {
        return _constants.default.EXTERNAL_ID.KIND_KUBERNETES;
      } else if (this.get('system')) {
        return _constants.default.EXTERNAL_ID.KIND_INFRA;
      } else {
        return _constants.default.EXTERNAL_ID.KIND_USER;
      }
    }),
    normalizedTags: Ember.computed('tags.[]', function () {
      return normalizeTags(this.get('tags'));
    }),
    autoInjectionEnabled: Ember.computed('labels', function () {
      var labels = Ember.get(this, 'labels');
      return labels && labels[ISTIO_INJECTION] === ENABLED;
    }),
    validateResourceQuota: function validateResourceQuota(originLimit) {
      var intl = Ember.get(this, 'intl');
      var errors = [];
      var resourceQuota = Ember.get(this, 'resourceQuota.limit') || {};
      var total = Ember.get(this, 'project.resourceQuota.limit');
      var used = Ember.get(this, 'project.resourceQuota.usedLimit') || {};

      if (total) {
        Object.keys(resourceQuota).forEach(function (key) {
          if (!resourceQuota[key] && parseInt(resourceQuota[key], 10) !== 0) {
            errors.push(intl.t('formResourceQuota.errors.limitRequired', {
              resource: intl.t("formResourceQuota.resources.".concat(key))
            }));
          }

          if (resourceQuota[key]) {
            var t = convertResourceQuota(key, total[key]);
            var u = convertResourceQuota(key, used[key] || 0);
            var v = convertResourceQuota(key, resourceQuota[key]);
            var originValue = originLimit && originLimit[key] ? originLimit[key] : 0;
            var o = convertResourceQuota(key, originValue);
            var left = t - u + o;

            if (v > left) {
              errors.push(intl.t('formResourceQuota.errors.invalidLimit', {
                resource: intl.t("formResourceQuota.resources.".concat(key)),
                left: left,
                total: t,
                used: u - o
              }));
            }
          }
        });
      }

      return errors;
    },
    actions: {
      edit: function edit() {
        this.get('modalService').toggleModal('modal-edit-namespace', this);
      },
      delete: function _delete() {
        var _this = this;

        return this._super().then(function () {
          if (_this.get('application.currentRouteName') === 'stack.index') {
            _this.get('router').transitionTo('containers');
          }
        });
      },
      move: function move() {
        this.get('modalService').toggleModal('modal-move-namespace', this);
      },
      enableAutoInject: function enableAutoInject() {
        this.autoInjectToggle();
      },
      disableAutoInject: function disableAutoInject() {
        this.autoInjectToggle();
      }
    },
    hasTags: function hasTags(want) {
      if (!want || !want.length) {
        return true;
      }

      want = normalizeTags(want);
      var have = this.get('normalizedTags');

      for (var i = 0; i < want.length; i++) {
        if (!have.includes(want[i])) {
          return false;
        }
      }

      return true;
    },
    autoInjectToggle: function autoInjectToggle() {
      var _this2 = this;

      var labels = Ember.get(this, 'labels');
      var clone = this.clone();

      if (Ember.get(this, 'autoInjectionEnabled')) {
        delete labels['istio-injection'];
      } else {
        labels[ISTIO_INJECTION] = ENABLED;
      }

      Ember.set(clone, 'labels', labels);
      clone.save().catch(function (err) {
        return Ember.get(_this2, 'growl').fromError('Error:', err);
      });
    }
  });

  Namespace.reopenClass({
    stateMap: {
      'active': {
        icon: activeIcon,
        color: 'text-success'
      },
      'rolling-back': {
        icon: 'icon icon-history',
        color: 'text-info'
      },
      'upgraded': {
        icon: 'icon icon-arrow-circle-up',
        color: 'text-info'
      },
      'upgrading': {
        icon: 'icon icon-arrow-circle-up',
        color: 'text-info'
      }
    }
  });
  var _default = Namespace;
  _exports.default = _default;
});