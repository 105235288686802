define("ui/models/roletemplate", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants"], function (_exports, _resource, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    roleTemplateService: Ember.inject.service('roleTemplate'),
    growl: Ember.inject.service(),
    type: 'roleTemplate',
    canClone: true,
    state: Ember.computed('locked', function () {
      return Ember.get(this, 'locked') ? 'locked' : 'active';
    }),
    isCustom: Ember.computed('roleTemplateId', function () {
      return !_constants.default.BASIC_ROLE_TEMPLATE_ROLES.includes(Ember.get(this, 'id'));
    }),
    displayName: Ember.computed('name', 'id', function () {
      var name = Ember.get(this, 'name');

      if (name) {
        return name;
      }

      return "(".concat(Ember.get(this, 'id'), ")");
    }),
    canRemove: Ember.computed('links.remove', 'builtin', function () {
      return !!Ember.get(this, 'links.remove') && !Ember.get(this, 'builtin');
    }),
    actions: {
      edit: function edit() {
        Ember.get(this, 'router').transitionTo('global-admin.security.roles.edit', Ember.get(this, 'id'));
      },
      clone: function clone() {
        Ember.get(this, 'router').transitionTo('global-admin.security.roles.new', {
          queryParams: {
            id: Ember.get(this, 'id')
          }
        });
      }
    },
    delete: function _delete() {
      var _this = this,
          _arguments = arguments;

      var self = this;
      var sup = self._super;
      var roleTemplateService = Ember.get(this, 'roleTemplateService');
      var canDelete = true;
      var roleNames = [];
      return roleTemplateService.fetchFilteredRoleTemplates().then(function (res) {
        var roleTemplates = res.filter(function (r) {
          return r.canRemove;
        });
        roleTemplates.map(function (r) {
          var _r$roleTemplateIds = r.roleTemplateIds,
              roleTemplateIds = _r$roleTemplateIds === void 0 ? [] : _r$roleTemplateIds;
          (roleTemplateIds || []).map(function (id) {
            if (id === _this.id) {
              canDelete = false;
              roleNames.pushObject(r.name);
            }
          });
        });

        if (canDelete) {
          return sup.apply(self, _arguments);
        } else {
          return Ember.get(_this, 'growl').error(Ember.get(_this, 'intl').t('rolesPage.index.errors.inherited', {
            displayName: Ember.get(_this, 'displayName'),
            roleNames: roleNames.join(',')
          }));
        }
      });
    }
  });

  _exports.default = _default;
});