define("ui/components/form-node-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/B4RPYtJ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"box mb-10 pt-5\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n          \"],[7,\"button\"],[11,\"class\",\"btn bg-transparent text-small vertical-middle\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"formNodeAffinity.removeRuleLabel\"],null],false],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"removeRule\",[23,[\"rule\"]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[2,\" 28px is the height of the button, no jumpy \"],[0,\"\\n        \"],[7,\"div\"],[11,\"style\",\"height: 28px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[1,[27,\"form-node-requirement\",null,[[\"term\",\"editing\"],[[23,[\"rule\"]],[23,[\"editing\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-node-selector/template.hbs"
    }
  });

  _exports.default = _default;
});