define("ui/container/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      if (window.ShellQuote) {
        return;
      } else {
        return emberAutoImportDynamic("shell-quote").then(function (module) {
          window.ShellQuote = module.default;
          return module.default;
        });
      }
    },
    model: function model(params) {
      var _this = this;

      var pod = Ember.get(this, 'store').find('pod', params.pod_id);
      return Ember.RSVP.hash({
        pod: pod
      }).then(function (hash) {
        var container = Ember.get(hash, 'pod.containers').findBy('name', params.container_name);

        if (!container) {
          _this.replaceWith('authenticated.project.index');
        }

        return container;
      });
    }
  });

  _exports.default = _default;
});