define("ui/authenticated/cluster/notifier/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bHBPmNc7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"header clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-left pt-10\"],[9],[7,\"h1\"],[9],[1,[27,\"t\",[\"notifierPage.notifiers\"],null],false],[10],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-buttons\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm bg-primary\"],[9],[1,[27,\"t\",[\"notifierPage.index.addActionLabel\"],null],false],[3,\"action\",[[22,0,[]],\"showNewEditModal\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"notifier/notifier-table\",null,[[\"model\"],[[23,[\"notifiers\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/notifier/index/template.hbs"
    }
  });

  _exports.default = _default;
});