define("ui/models/globalrolebinding", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    canRemove: false,
    globalRole: (0, _denormalize.reference)('globalRoleId', 'globalRole')
  });

  _exports.default = _default;
});