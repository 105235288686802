define("ui/helpers/rbac-allows", ["exports", "shared/helpers/rbac-allows"], function (_exports, _rbacAllows) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _rbacAllows.default;
    }
  });
});