define("ui/utils/navigation-tree", ["exports", "shared/utils/navigation-tree"], function (_exports, _navigationTree) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "bulkAdd", {
    enumerable: true,
    get: function get() {
      return _navigationTree.bulkAdd;
    }
  });
  Object.defineProperty(_exports, "getProjectId", {
    enumerable: true,
    get: function get() {
      return _navigationTree.getProjectId;
    }
  });
  Object.defineProperty(_exports, "getClusterId", {
    enumerable: true,
    get: function get() {
      return _navigationTree.getClusterId;
    }
  });
  Object.defineProperty(_exports, "addItem", {
    enumerable: true,
    get: function get() {
      return _navigationTree.addItem;
    }
  });
  Object.defineProperty(_exports, "removeId", {
    enumerable: true,
    get: function get() {
      return _navigationTree.removeId;
    }
  });
  Object.defineProperty(_exports, "get", {
    enumerable: true,
    get: function get() {
      return _navigationTree.get;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _navigationTree.default;
    }
  });
});