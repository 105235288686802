define("ui/container/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    scope: Ember.inject.service(),
    router: Ember.inject.service(),
    monitoringEnabled: Ember.computed.alias('scope.currentCluster.isMonitoringReady'),
    podStateDidChange: Ember.observer('model.pod.state', function () {
      if (_constants.default.REMOVEDISH_STATES.includes(Ember.get(this, 'model.pod.state')) && Ember.get(this, 'router.currentRouteName') === 'container') {
        var workloadId = Ember.get(this, 'model.pod.workloadId');

        if (workloadId) {
          this.transitionToRoute('workload', workloadId);
        } else {
          this.transitionToRoute('authenticated.project.index');
        }
      }
    }),
    displayEnvironmentVars: Ember.computed('model.environment', function () {
      var envs = [];
      var environment = Ember.get(this, 'model.environment') || {};
      Object.keys(environment).forEach(function (key) {
        envs.pushObject({
          key: key,
          value: environment[key]
        });
      });
      return envs;
    })
  });

  _exports.default = _default;
});