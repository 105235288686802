define("ui/authenticated/project/dns/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['id'],
    actions: {
      done: function done() {
        return this.transitionToRoute('authenticated.project.dns.index');
      },
      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });

  _exports.default = _default;
});