define("ui/logout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      transition.send('logout');
    }
  });

  _exports.default = _default;
});