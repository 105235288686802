define("ui/catalog-tab/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return Ember.get(this, 'catalog').fetchUnScopedCatalogs();
    },
    model: function model() {
      // Do not use the model result
      var out = {};
      return Ember.get(this, 'catalog').fetchTemplates().then(function () {
        return out;
      });
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        controller.setProperties({
          category: ''
        });
      }
    },
    deactivate: function deactivate() {
      // Clear the cache when leaving the route so that it will be reloaded when you come back.
      this.set('cache', null);
    },
    actions: {
      refresh: function refresh() {
        // Clear the cache so it has to ask the server again
        this.set('cache', null);
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});