define("ui/components/form-access-modes/component", ["exports", "ui/components/form-access-modes/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    mode: null,
    model: null,
    accessRWO: null,
    accessROX: null,
    accessRWX: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var accessRWO = true;
      var accessROX = false;
      var accessRWX = false;

      if (Ember.get(this, 'mode') !== 'new') {
        var modes = Ember.get(this, 'model.accessModes') || [];
        accessRWO = modes.includes('ReadWriteOnce');
        accessROX = modes.includes('ReadOnlyMany');
        accessRWX = modes.includes('ReadWriteMany');
      }

      Ember.setProperties(this, {
        accessRWO: accessRWO,
        accessROX: accessROX,
        accessRWX: accessRWX
      });
      this.modesChanged();
    },
    modesChanged: Ember.observer('accessRWO', 'accessROX', 'accessRWX', function () {
      var modes = [];

      if (Ember.get(this, 'accessRWO')) {
        modes.push('ReadWriteOnce');
      }

      if (Ember.get(this, 'accessROX')) {
        modes.push('ReadOnlyMany');
      }

      if (Ember.get(this, 'accessRWX')) {
        modes.push('ReadWriteMany');
      }

      Ember.set(this, 'model.accessModes', modes);
    }),
    editing: Ember.computed('mode', function () {
      return Ember.get(this, 'mode') !== 'view';
    })
  });

  _exports.default = _default;
});