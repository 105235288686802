define("ui/components/namespace-quota-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9RQdRbYa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"pr-10\"],[9],[0,\"\\n  \"],[1,[27,\"resource-quota-select\",null,[[\"editing\",\"quota\"],[false,[23,[\"quota\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"pr-10\"],[9],[0,\"\\n  \"],[1,[27,\"progress-bar-multi\",null,[[\"values\",\"tooltipValues\",\"tooltipArrayOrString\",\"tooltipTemplate\",\"max\",\"minPercent\"],[[23,[\"quota\",\"currentProjectUse\"]],[23,[\"quota\",\"totalLimits\"]],\"array\",\"-ns-quota-progressbar\",[23,[\"quota\",\"max\"]],0]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[27,\"input-resource-quota\",null,[[\"editing\",\"quota\",\"key\"],[[23,[\"editing\"]],[23,[\"quota\"]],\"value\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/namespace-quota-row/template.hbs"
    }
  });

  _exports.default = _default;
});