define("ui/components/accordion-container/component", ["exports", "ui/components/accordion-container/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    model: null,
    expandOnInit: true,
    sortBy: 'displayState',
    descending: true,
    initExpand: true,
    headers: [{
      name: 'displayState',
      sort: ['displayState'],
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['name'],
      translationKey: 'generic.name'
    }, {
      name: 'image',
      sort: ['image'],
      translationKey: 'generic.image'
    }, {
      name: 'restarts',
      sort: ['restarts'],
      translationKey: 'generic.restarts',
      width: 100
    }]
  });

  _exports.default = _default;
});