define("ui/components/container/form-upgrade-stateful-set/component", ["exports", "ui/components/container/form-upgrade-stateful-set/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    didReceiveAttrs: function didReceiveAttrs() {
      var config = Ember.get(this, 'workloadConfig');
      var strategy = Ember.get(config, 'strategy');

      if (!strategy) {
        Ember.set(config, 'strategy', 'RollingUpdate');
      }
    }
  });

  _exports.default = _default;
});