define("ui/components/input-command/component", ["exports", "ui/components/input-command/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reop = reop;
  _exports.unparse = unparse;
  _exports.default = _exports.OPS = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var ShellQuote = window.ShellQuote;
  var OPS = ['||', '&&', ';;', '|&', '&', ';', '(', ')', '|', '<', '>'];
  _exports.OPS = OPS;

  function reop(xs) {
    return xs.map(function (s) {
      if (OPS.includes(s)) {
        return {
          op: s
        };
      } else {
        return s;
      }
    });
  }

  function unparse(xs) {
    return xs.map(function (s) {
      if (s && _typeof(s) === 'object') {
        if (s.hasOwnProperty('pattern')) {
          return "\"".concat(s.pattern, "\"");
        } else {
          return s.op;
        }
      } else if (/["\s]/.test(s) && !/'/.test(s)) {
        return "'".concat(s.replace(/(['\\])/g, '\\$1'), "'");
      } else if (/["'\s]/.test(s)) {
        return "\"".concat(s.replace(/(["\\$`!])/g, '\\$1'), "\"");
      } else {
        return String(s).replace(/([\\$`()!#&*|])/g, '\\$1');
      }
    }).join(' ');
  }

  var _default = Ember.TextField.extend({
    layout: _template.default,
    type: 'text',
    disabled: false,
    init: function init() {
      this._super.apply(this, arguments);

      var initial = Ember.get(this, 'initialValue') || '';

      if (Ember.isArray(initial)) {
        Ember.set(this, 'value', unparse(reop(initial)));
      } else {
        Ember.set(this, 'value', initial);
      }
    },
    valueChanged: Ember.observer('value', function () {
      var out = ShellQuote.parse(Ember.get(this, 'value') || '').map(function (piece) {
        if (_typeof(piece) === 'object' && piece) {
          if (piece.pattern) {
            return piece.pattern;
          } else if (piece.op) {
            return piece.op;
          } else {
            return '';
          }
        }

        return piece;
      });

      if (out.length) {
        if (this.changed) {
          this.changed(out);
        }
      } else {
        if (this.changed) {
          this.changed(null);
        }
      }
    })
  });

  _exports.default = _default;
});