define("ui/components/modal-edit-certificate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3fBFmp2y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"horizontal-form container-fluid\"],[9],[0,\"\\n  \"],[7,\"h2\"],[9],[1,[27,\"t\",[\"editCertificate.title\"],null],false],[10],[0,\"\\n\\n  \"],[1,[27,\"form-name-description\",null,[[\"model\",\"nameRequired\",\"descriptionShown\",\"namePlaceholder\",\"descriptionPlaceholder\"],[[23,[\"primaryResource\"]],true,false,\"editCertificate.name.placeholder\",\"editCertificate.description.placeholder\"]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"banner bg-info\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"banner-icon\"],[9],[0,\"\\n      \"],[7,\"i\"],[11,\"class\",\"icon icon-info\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"banner-message pt-10 pb-10\"],[9],[1,[27,\"t\",[\"editCertificate.noteKeyWriteOnly\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[27,\"cru-certificate\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"top-errors\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n\"],[1,[27,\"save-cancel\",null,[[\"editing\",\"save\",\"cancel\"],[true,[27,\"action\",[[22,0,[]],\"save\"],null],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-edit-certificate/template.hbs"
    }
  });

  _exports.default = _default;
});