define("ui/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      activate: function activate() {
        this.transitionTo('authenticated');
      }
    }
  });

  _exports.default = _default;
});