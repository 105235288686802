define("ui/components/modal-container-logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k5S2jMMp",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"container-logs\",null,[[\"instance\",\"containerName\",\"dismiss\"],[[23,[\"originalModel\"]],[23,[\"containerName\"]],[27,\"action\",[[22,0,[]],\"cancel\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-container-logs/template.hbs"
    }
  });

  _exports.default = _default;
});