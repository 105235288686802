define("ui/templates/tooltip-static", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "45H331OZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"model\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/templates/tooltip-static.hbs"
    }
  });

  _exports.default = _default;
});