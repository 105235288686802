define("ui/components/container/form-upgrade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hJzlkerD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"accordion-list-item\",null,[[\"title\",\"detail\",\"status\",\"statusClass\",\"expandAll\",\"expandOnInit\",\"expand\",\"everExpanded\"],[[27,\"t\",[\"formUpgrade.title\"],null],[27,\"t\",[\"formUpgrade.detail\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],[23,[\"status\"]],[23,[\"statusClass\"]],[23,[\"expandAll\"]],[23,[\"expandOnInit\"]],[27,\"action\",[[22,0,[]],[23,[\"expandFn\"]]],null],true]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"componentName\"]]],[[\"workload\",\"scaleMode\",\"workloadConfig\",\"editing\",\"isUpgrade\"],[[23,[\"workload\"]],[23,[\"scaleMode\"]],[23,[\"workloadConfig\"]],[23,[\"editing\"]],[23,[\"isUpgrade\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade/template.hbs"
    }
  });

  _exports.default = _default;
});