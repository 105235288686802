define("ui/authenticated/controller", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    scope: Ember.inject.service(),
    application: Ember.inject.controller(),
    error: null,
    isPopup: Ember.computed.alias('application.isPopup'),
    pageScope: Ember.computed.alias('scope.currentPageScope'),
    bootstrap: Ember.on('init', function () {
      var _this = this;

      Ember.run.schedule('afterRender', this, function () {
        _this.get('application').setProperties({
          error: null,
          error_description: null,
          state: null
        });

        var bg = _this.get("prefs.".concat(_constants.default.PREFS.BODY_BACKGROUND));

        if (bg) {
          $('BODY').css('background', bg); // eslint-disable-line
        }
      });
    }),
    hasHosts: Ember.computed('model.hosts.length', function () {
      return this.get('model.hosts.length') > 0;
    })
  });

  _exports.default = _default;
});