define("ui/components/notifier/notifier-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JBcJSfWv",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"row\",\"dt\"],\"statements\":[[4,\"sortable-table\",null,[[\"tableClassNames\",\"classNames\",\"bulkActions\",\"paging\",\"search\",\"sortBy\",\"descending\",\"headers\",\"body\"],[\"bordered\",\"grid sortable-table\",true,true,true,[23,[\"sortBy\"]],[23,[\"descending\"]],[23,[\"headers\"]],[23,[\"filteredNotifiers\"]]]],{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"row\"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"notifier/notifier-row\",null,[[\"model\"],[[22,3,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"nomatch\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[12,\"colspan\",[28,[[22,1,[\"fullColspan\"]]]]],[11,\"class\",\"text-center text-muted pt-20 pb-20\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"notifierPage.index.table.noMatch\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,2,[]],\"norows\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"td\"],[12,\"colspan\",[28,[[22,1,[\"fullColspan\"]]]]],[11,\"class\",\"text-center text-muted pt-20 pb-20\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"notifierPage.index.table.noData\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1,2,3,4]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/notifier/notifier-table/template.hbs"
    }
  });

  _exports.default = _default;
});