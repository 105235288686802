define("ui/models/sourcecodecredential", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    type: 'sourcecodecredential',
    username: function () {
      return Ember.get(this, 'displayName');
    }.property('displayName'),
    profilePicture: function () {
      return Ember.get(this, 'avatarUrl');
    }.property('avatarUrl'),
    profileUrl: function () {
      return Ember.get(this, 'htmlUrl');
    }.property('htmlUrl')
  });

  _exports.default = _default;
});