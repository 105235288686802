define("ui/components/modal-feedback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAYDEfzG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-10 pb-40 pl-40 pr-40 welcome-copy\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"class\",\"header\"],[9],[1,[27,\"t\",[\"modalFeedback.header\"],[[\"appName\"],[[23,[\"settings\",\"appName\"]]]]],false],[10],[0,\"\\n  \"],[7,\"p\"],[9],[1,[27,\"t\",[\"modalFeedback.subtitle\"],null],false],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"id\",\"feedback-form\"],[11,\"class\",\"clearfix\"],[11,\"style\",\"min-height: 100px;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-center p-20\"],[9],[7,\"i\"],[11,\"class\",\"icon icon-spinner icon-spin\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"generic.loading\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"footer-actions pt-20\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-primary\"],[12,\"disabled\",[21,\"loading\"]],[9],[1,[27,\"t\",[\"modalFeedback.send\"],null],false],[3,\"action\",[[22,0,[]],\"submit\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/modal-feedback/template.hbs"
    }
  });

  _exports.default = _default;
});