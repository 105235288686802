define("ui/mixins/preload", ["exports", "shared/mixins/preload"], function (_exports, _preload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _preload.default;
    }
  });
});