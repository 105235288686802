define("ui/components/modal-rollback-app/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-rollback-app/template", "jsondiffpatch", "moment"], function (_exports, _modalBase, _template, _jsondiffpatch, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HIDDEN_FIELDS = ['digest'];

  function sanitize(config) {
    HIDDEN_FIELDS.forEach(function (key) {
      if (config.hasOwnProperty(key)) {
        delete config[key];
      }
    });
    return config;
  }

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['large-modal'],
    loading: true,
    forceUpgrade: false,
    revisions: null,
    revisionId: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var model = Ember.get(this, 'modalService.modalOpts.originalModel').clone();
      Ember.set(this, 'model', model);
      Ember.get(this, 'store').rawRequest({
        url: Ember.get(model, 'links.revision'),
        method: 'GET'
      }).then(function (revs) {
        Ember.set(_this, 'revisions', revs.body.data);
      }).catch(function (err) {
        _this.send('cancel');

        Ember.get(_this, 'growl').fromError(err);
      }).finally(function () {
        Ember.set(_this, 'loading', false);
      });
    },
    actions: {
      save: function save(cb) {
        var _this2 = this;

        var forceUpgrade = this.forceUpgrade,
            revisionId = this.revisionId;
        Ember.get(this, 'model').doAction('rollback', {
          revisionId: revisionId,
          forceUpgrade: forceUpgrade
        }).then(function () {
          _this2.send('cancel');
        }).finally(function () {
          cb();
        });
      }
    },
    choices: Ember.computed('revisions.[]', function () {
      return (Ember.get(this, 'revisions') || []).sortBy('created').reverse().map(function (r) {
        var time = (0, _moment.default)(Ember.get(r, 'created'));
        return {
          label: "".concat(Ember.get(r, 'name'), ": ").concat(time.format('YYYY-MM-DD HH:mm:ss'), " (").concat(time.fromNow(), ")"),
          value: Ember.get(r, 'name'),
          data: r
        };
      });
    }),
    current: Ember.computed('choices.[]', function () {
      return Ember.get(this, 'choices.firstObject.data');
    }),
    selected: Ember.computed('revisionId', 'revisions.[]', function () {
      return Ember.get(this, 'revisions').findBy('name', Ember.get(this, 'revisionId'));
    }),
    diff: Ember.computed('current', 'selected', function () {
      if (Ember.get(this, 'current') && Ember.get(this, 'selected')) {
        var left = Ember.get(this, 'current.status');
        var right = Ember.get(this, 'selected.status');

        var delta = _jsondiffpatch.default.diff(sanitize(left), sanitize(right));

        _jsondiffpatch.default.formatters.html.hideUnchanged();

        return _jsondiffpatch.default.formatters.html.format(delta, left).htmlSafe();
      }
    })
  });

  _exports.default = _default;
});