define("ui/components/modal-restore-backup/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-restore-backup/template", "moment"], function (_exports, _modalBase, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    backupId: null,
    loadingBackups: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.initOwnProperties();
      this.fetchAllBackupsForCluster();
    },
    actions: {
      restore: function restore() {
        var _this = this;

        var backupId = this.backupId;
        var out = {};

        if (backupId) {
          Ember.set(out, 'etcdBackupId', backupId);
          this.modalOpts.cluster.doAction('restoreFromEtcdBackup', out).then(function () {
            _this.send('cancel');
          });
        } else {
          this.growl.fromError(this.intl.t('modalRestoreBackup.error'));
        }
      }
    },
    availableBackups: Ember.computed('modalOpts.cluster.etcdbackups.[]', function () {
      return Ember.get(this, 'modalOpts.cluster.etcdbackups').map(function (backup) {
        var time = (0, _moment.default)(Ember.get(backup, 'created'));
        return {
          id: backup.id,
          label: "".concat(backup.displayName, " ( ").concat(time.format('MMMM Do YYYY, H:mm:ss'), ")"),
          created: backup.created,
          state: backup.state
        };
      }).sortBy('created');
    }),
    initOwnProperties: function initOwnProperties() {
      Ember.setProperties(this, {
        backupId: '',
        errors: []
      });
    },
    fetchAllBackupsForCluster: function fetchAllBackupsForCluster() {
      var _this2 = this;

      Ember.set(this, 'loadingBackups', true);
      this.modalOpts.cluster.store.findAll('etcdbackup').finally(function () {
        Ember.set(_this2, 'loadingBackups', false);
      });
    }
  });

  _exports.default = _default;
});