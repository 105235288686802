define("ui/components/form-node-affinity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WsuYxBOZ",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[4,\"each\",[[23,[\"rules\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"form-node-selector\",null,[[\"removeRule\",\"rule\",\"editing\"],[[27,\"action\",[[22,0,[]],\"removeRule\"],null],[22,1,[]],[23,[\"editing\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"editing\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"text-center text-muted pt-20 pb-20\"],[9],[1,[27,\"t\",[\"formNodeAffinity.noRules\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"editing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn bg-link icon-btn pull-left\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"darken\"],[9],[7,\"i\"],[11,\"class\",\"icon icon-plus text-small\"],[9],[10],[10],[0,\"\\n      \"],[7,\"span\"],[9],[1,[27,\"t\",[\"formNodeAffinity.addRuleLabel\"],null],false],[10],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"addRule\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/form-node-affinity/template.hbs"
    }
  });

  _exports.default = _default;
});