define("ui/components/modal-telemetry/component", ["exports", "ui/utils/constants", "shared/mixins/modal-base", "shared/utils/parse-version", "ui/components/modal-telemetry/template"], function (_exports, _constants, _modalBase, _parseVersion, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    layout: _template.default,
    classNames: ['modal-container', 'span-6', 'offset-3', 'modal-telemetry', 'alert'],
    optIn: null,
    init: function init() {
      this._super.apply(this, arguments);

      var cur = Ember.get(this, "settings.".concat(_constants.default.SETTING.TELEMETRY));
      var version = Ember.get(this, 'settings.rancherVersion');
      var optIn;

      if (!version || (0, _parseVersion.isDevBuild)(version)) {
        // For dev builds, default to opt out
        optIn = cur === 'in';
      } else {
        // For releases, default to opt in
        optIn = cur !== 'out';
      }

      Ember.set(this, 'optIn', optIn);
    },
    actions: {
      cancel: function cancel() {
        Ember.get(this, 'settings').set(_constants.default.SETTING.TELEMETRY, Ember.get(this, 'optIn') ? 'in' : 'out');
        Ember.get(this, 'modalService').toggleModal();
      }
    }
  });

  _exports.default = _default;
});